angular.module('picmonic-common')
    .factory("MarketOptionsConfig", ['$filter', '$cookies', '$location', function ($filter, $cookies, $location) {
        return {
            markets: [
                {id:100, parent_id: 11, name:'Medicine', public_name: 'Medical (MD/DO)', public_name2: 'Medicine (MD/DO)', segmented_control_name:'MD / DO - Physicians', school_name:'medical', slug:'medicine', hidden:0, show_school_modal: 1, selected_lead: 100, use_program_market_id: false, tokens: 3, picmonics: window.pls_config.picmonic_counts[100], facts: 11000, pricing_url:"/pricing/medicine", tabs:['courses','body systems', 'books', 'boards'],
                    sections:[
                        {
                            name: 'Courses',
                            pathway_type: 'courses',
                            curriculum: 'standard',
                            icon: 'extension'
                        },
                        {
                            name: 'Body Systems',
                            pathway_type: 'courses',
                            curriculum: 'body-systems',
                            icon: 'accessibility'
                        },
                        {
                            name: 'Books',
                            pathway_type: 'books',
                            icon: 'import_contacts'
                        },
                        {
                            name: 'USMLE Step 1',
                            pathway_type: 'boards',
                            curriculum: 'usmle-step-1',
                            icon: 'assignment'
                        },
                        {
                            name: 'USMLE Step 2',
                            pathway_type: 'boards',
                            curriculum: 'usmle-step-2',
                            icon: 'assignment'
                        }
                    ],
                    testimonial: '"[Picmonics] correlate directly with what is in First Aid so you know it is essential information that will show up on the exam. The number of questions I got right in biochemistry and microbiology were mainly due to this resource." James, Texas Tech University Health Sciences Center School of Medicine, 274 on Step 1',
                    exam_name: 'the USMLE Step 1, USMLE Step 2 CK, COMLEX Level 1, or COMLEX Level 2'
                },
                {id:101, parent_id: 20, name:'Nursing RN', public_name: 'Nursing (RN)', public_name2: 'Nursing (RN)', segmented_control_name:'rn - registered nurses', school_name:'nursing', slug:'nursing', hidden:0, show_school_modal: 1, selected_lead: 101, use_program_market_id: true, tokens: 1, picmonics: window.pls_config.picmonic_counts[101], facts: 7500, pricing_url:"/pricing/nursing", tabs:['courses','body systems', 'books', 'nclex'],
                    sections:[
                        {
                            name: 'Courses',
                            pathway_type: 'courses',
                            curriculum: 'standard',
                            icon: 'extension'
                        },
                        {
                            name: 'Body Systems',
                            pathway_type: 'courses',
                            curriculum: 'body-systems',
                            icon: 'accessibility'
                        },
                        {
                            name: 'Books',
                            pathway_type: 'books',
                            icon: 'import_contacts'
                        },
                        {
                            name: 'NCLEX<sup>®</sup>',
                            pathway_type: 'boards',
                            curriculum: 'nclex',
                            icon: 'assignment'
                        }
                    ],
                    testimonial: '"Bravo Team Picmonic on creating a truly revolutionary approach to learning, and for helping me to pass the NCLEX®. Couldn’t have done it without you!" — Steph B., University of Detroit Mercy, class of 2015',
                    exam_name: 'your NCLEX®-RN, Hesi, ATI, TEAS test, Kaplan exams'
                },
                {id:202, parent_id: 20, name:'Nursing LPN', public_name: 'Nursing (LPN)', public_name2: 'Nursing (LPN)', segmented_control_name:'lpn - practical nurses', school_name:'nursing', slug:'nursing-lpn',  selected_lead: 101, use_program_market_id: false, tokens: 1, picmonics: window.pls_config.picmonic_counts[202], facts: 7500, hidden:0, show_school_modal: 1, pricing_url:"/pricing/nursing-lpn", tabs:['courses','body systems', 'books', 'nclex-pn'],
                    sections:[
                        {
                            name: 'Courses',
                            pathway_type: 'courses',
                            curriculum: 'standard',
                            icon: 'extension'
                        },
                        {
                            name: 'Body Systems',
                            pathway_type: 'courses',
                            curriculum: 'body-systems',
                            icon: 'accessibility'
                        },
                        {
                            name: 'Books',
                            pathway_type: 'books',
                            icon: 'import_contacts'
                        },
                        {
                            name: 'NCLEX<sup>®</sup>',
                            pathway_type: 'boards',
                            curriculum: 'nclex',
                            icon: 'assignment'
                        },
                    ],
                    testimonial: '"Bravo Team Picmonic on creating a truly revolutionary approach to learning, and for helping me to pass the NCLEX®. Couldn’t have done it without you!" — Steph B., University of Detroit Mercy, class of 2015',
                    exam_name: 'your NCLEX®-LPN, Hesi, ATI, or Kaplan exams'
                },
                {id:203, parent_id: 20, name:'Nurse Practitioner', segmented_control_name:'np - nurse practitioners', school_name:'nursing', slug:'nursing-np', show_school_modal: 1, hidden:0, use_program_market_id: false, tokens: 3, is_beta:false, show_miniplayer: false,  picmonics: window.pls_config.picmonic_counts[203], facts: 9000, pricing_url:"/pricing/nursing-np", tabs:['courses', 'body systems', 'books'],
                    sections:[
                        {
                            name: 'Courses',
                            pathway_type: 'courses',
                            curriculum: 'standard',
                            icon: 'extension'
                        },
                        {
                            name: 'Body Systems',
                            pathway_type: 'courses',
                            curriculum: 'body-systems',
                            icon: 'accessibility'
                        },
                        {
                            name: 'Books',
                            pathway_type: 'books',
                            icon: 'import_contacts'
                        }
                    ],
                    exam_name: 'your licensing exams'
                },
                {id:204, parent_id: 11, name:'Nursing CNA', segmented_control_name:'cna - certified nursing assistants', school_name:'nursing', slug:'nursing-cna', is_beta:true, hidden:1, use_program_market_id: true, tokens: 3, show_miniplayer: true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:105, parent_id: 11, name:'Physician Assistant', segmented_control_name:'pa - physician assistants', school_name:'PA', slug:'physician-assistant', show_school_modal: 1, hidden:0, use_program_market_id: false, tokens: 3, show_miniplayer: false, picmonics: window.pls_config.picmonic_counts[105], facts: 11000, pricing_url:"/pricing/physician-assistant", tabs:['courses', 'body systems', 'books'],
                    sections:[
                        {
                            name: 'Courses',
                            pathway_type: 'courses',
                            curriculum: 'standard',
                            icon: 'extension'
                        },
                        {
                            name: 'Body Systems',
                            pathway_type: 'courses',
                            curriculum: 'body-systems',
                            icon: 'accessibility'
                        },
                        {
                            name: 'Books',
                            pathway_type: 'books',
                            icon: 'import_contacts'
                        }
                    ],
                    exam_name: 'the PANCE'
                },
                {id:109, parent_id: 11, name:'Pharmacy', public_name: 'Pharmacy (PharmD)', public_name2: 'Pharmacy (PharmD)', exam_name: 'the PCAT® and NAPLEX® exams', segmented_control_name:'pharm.d - pharmacists', school_name:'pharmacy', slug:'pharmacy', is_beta:false, hidden:0, show_school_modal: 1, use_program_market_id: false, tokens: 3, show_miniplayer: false, picmonics: 500, facts: 3000, pricing_url:"/pricing/pharmacy", tabs:['courses', 'body systems'], sections:[{name: 'Courses', pathway_type: 'courses', curriculum: 'standard', icon: 'extension'}, {name: 'Body Systems', pathway_type: 'courses', curriculum: 'body-systems', icon: 'accessibility'}]},
                {id:103, parent_id: 18, name:'Pre-Health', segmented_control_name:'pre-health', exam_name: 'the MCAT', school_name:'medical', slug:'prehealth',  selected_lead: 103, use_program_market_id: false, tokens: 3, picmonics: 100, pricing_url:"/pricing/prehealth", hidden:0, tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:112, parent_id: 18, name:'College', segmented_control_name:'college', school_name:'', slug:'college', is_beta:true, hidden:0,  selected_lead: 112, use_program_market_id: false, tokens: 3, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:107, parent_id: 15, name:'K12', segmented_control_name:'k-12', school_name:'', slug:'k12',  hidden:0,  selected_lead: 107, use_program_market_id: false, tokens: 3, is_beta:true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:191, parent_id: 19, name:'German Medicine', school_name:'medical', slug:'german-medicine', is_beta:true, hidden:1,   selected_lead: 191, use_program_market_id: false, tokens: 3, show_miniplayer: true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:302, parent_id: 30, name:'Aunt Chilada\'s', school_name:'', slug:'auntchiladas', hidden:1,   selected_lead: 103, use_program_market_id: false, tokens: 3, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:310, parent_id: 31, name:'University of Phoenix', school_name:'', slug:'universityofphoenix', is_beta:true, hidden:1, use_program_market_id: false, tokens: 3, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:210, parent_id: 21, name:'EMT', school_name:'EMT', slug:'emt', is_beta:true, hidden:1, use_program_market_id: false, tokens: 3, show_miniplayer: true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:106, parent_id: 11, name:'UK Medicine', school_name:'medical', slug:'uk-medicine', is_beta:true, hidden:1, use_program_market_id: false, tokens: 3, show_miniplayer: true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:108, parent_id: 11, name:'Medical Terminology', school_name:'healthcare', slug:'medical-terminology', is_beta:false, is_pseudo: 1, hidden:1, use_program_market_id: false, tokens: 3, show_miniplayer: false, pricing_url:"/pricing/medical-terminology", picmonics: window.pls_config.picmonic_counts[108], facts: 300, tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:181, parent_id: 18, name:'Anatomy and Physiology', school_name:'', slug:'anatomy-and-physiology', is_beta:true, hidden:1, use_program_market_id: false, tokens: 3, show_miniplayer: true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:104, parent_id: 22, name:'MCAT', school_name:'', slug:'mcat', is_beta:true, hidden:1, use_program_market_id: false, tokens: 3, show_miniplayer: true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:110, parent_id: 11, name:'Dental', school_name:'dental', slug:'dental', is_beta:true, hidden:1, use_program_market_id: false, tokens: 3, show_miniplayer: true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:111, parent_id: 11, name:'Chiropractic', school_name:'chiropractic', slug:'chiropractic', is_beta:true, hidden:1, use_program_market_id: false, tokens: 3, show_miniplayer: true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:130, parent_id: 13, name:'Law', school_name:'law', slug:'law', is_beta:true, hidden:1, use_program_market_id: false, tokens: 3, show_miniplayer: true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:140, parent_id: 14, name:'SAT', school_name:'', slug:'sat', is_beta:true, hidden:1, use_program_market_id: false, tokens: 3, show_miniplayer: true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:160, parent_id: 16, name:'Accounting', school_name:'accounting', slug:'accounting', is_beta:true, hidden:1, use_program_market_id: false, tokens: 3, show_miniplayer: true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:161, parent_id: 16, name:'Finance', school_name:'finance', slug:'finance', is_beta:true, hidden:1, use_program_market_id: false, tokens: 3, show_miniplayer: true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:220, parent_id: 10, name:'GRE', school_name:'', slug:'gre', is_beta:true, hidden:1, use_program_market_id: false, tokens: 3, show_miniplayer: true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:301, parent_id: 30, name:'Olive Garden', school_name:'', slug:'olive-garden', is_beta:true, hidden:1, use_program_market_id: false, tokens: 3, show_miniplayer: true, pricing_url:"/pricing", tabs:['courses'], sections:[{ name: 'Courses', pathway_type: 'courses', curriculum: 'standard'}]},
                {id:333, parent_id: 30, name:'Picmonic', school_name:'', slug: 'picmonic', hidden:1, tokens: 100},
            ],
            // See Service_User::saveSettings when addding a new program
            programs: [
                {market_id: 204, parent_id: 20, name: 'CNA', value: 'cna', slug: 'nursing-cna', visible_pricing: 0},
                {market_id: 202, parent_id: 20, name: 'LPN', value: 'lpn', slug: 'nursing-lpn', visible_pricing: 1},
                {market_id: 101, parent_id: 20, name: 'RN', value: 'rn', slug: 'nursing', visible_pricing: 1}
            ],
            filters: {
                courses: {
                    name: 'Courses',
                    value: 'courses',
                    markets:[
                        {name:'Medicine', market_id:100, curriculum:[
                            {name: 'Standard', value: 'standard'},
                            {name: 'Body Systems', value: 'body-systems'}
                        ]},
                        {name: 'Nursing', market_id: 101, curriculum:[
                            {name: 'Standard', value: 'standard'},
                            {name: 'Body Systems', value: 'body-systems'}
                        ]},
                        {name: 'Nursing LPN', market_id: 202, curriculum:[
                            {name: 'Standard', value: 'standard'},
                            {name: 'Body Systems', value: 'body-systems'}
                        ]},
                        {name: 'Nursing CNA', market_id: 204, curriculum:[
                            {name: 'Standard', value: 'standard'},
                            {name: 'Body Systems', value: 'body-systems'}
                        ]},
                        {name: 'Pre-Health Biology', market_id: 103, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'College', market_id: 112, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'K12', market_id: 107, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'German Medicine', market_id: 191, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'Law', market_id: 130, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'Accounting', market_id: 160, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'Finance', market_id: 161, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'Olive Garden', market_id: 301, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'Aunt Chiladas', market_id: 302, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'University of Phoenix', market_id: 310, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'Physician Assistant', market_id: 105, curriculum:[
                            {name: 'Standard', value: 'standard'},
                            {name: 'Body Systems', value: 'body-systems'}
                        ]},
                        {name: 'EMT', market_id: 210, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'UK Medicine', market_id: 106, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'Medical Terminology', market_id: 108, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'Pharmacy', market_id: 109, curriculum:[
                            {name: 'Standard', value: 'standard'},
                            {name: 'Body Systems', value: 'body-systems'}
                        ]},
                        {name: 'Anatomy and Physiology', market_id: 181, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'MCAT', market_id: 104, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'Nurse Practitioner', market_id: 203, curriculum:[
                            {name: 'Standard', value: 'standard'},
                            {name: 'Body Systems', value: 'body-systems'}
                        ]},
                        {name: 'GRE', market_id: 220, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]},
                        {name: 'Picmonic', market_id: 333, curriculum:[
                            {name: 'Standard', value: 'standard'}
                        ]}
                    ]
                },
                boards: {
                    name: 'Boards',
                    value: 'boards',
                    markets: [
                        {name:'Medicine', market_id:100, curriculum:[
                            {name: 'USMLE Step 1', value: 'usmle-step-1'},
                            {name: 'USMLE Step 2', value: 'usmle-step-2'}
                        ]},
                        {name: 'Nursing', market_id: 101, curriculum:[
                            {name: 'NCLEX-RN', value: 'nclex'}
                        ]},
                        {name: 'Nursing LPN', market_id: 202, curriculum:[
                            {name: 'NCLEX-PN', value: 'nclex-pn'}
                        ]},
                        {name: 'Nursing CNA', market_id: 204, curriculum:[
                            {name: 'CNA', value: 'cna'}
                        ]},
                        {name:'Physician Assistant', market_id:105, curriculum:[
                            {name: 'PANCE', value: 'pance'}
                        ]},
                        {name:'Nurse Practitioner', market_id:203, curriculum:[
                            {name: 'FNP', value: 'fnp'}
                        ]}
                    ]
                },
                books: {
                    name: 'Books',
                    value: 'books',
                    markets: [
                        {name: 'Medicine', market_id: 100},
                        {name: 'Nursing', market_id: 101},
                        {name: 'Nursing LPN', market_id: 202},
                        {name: 'Nursing CNA', market_id: 204},
                        {name: 'Pre-Health Biology', market_id: 103},
                        {name: 'College', market_id: 112},
                        {name: 'K12', market_id: 107},
                        {name: 'German Medicine', market_id: 191},
                        {name: 'Physician Assistant', market_id: 105},
                        {name: 'EMT', market_id: 210},
                        {name: 'UK Medicine', market_id: 106},
                        {name: 'Pharmacy', market_id: 109},
                        {name: 'Nurse Practitioner', market_id: 203}
                    ]
                }
            },
            getMarketById: function(id){
                return $filter('findInArray')(this.markets, id, 'id');
            },
            getProgramsByMarketId: function(marketId){
                return $filter('findInArray')(this.programs, marketId, 'market_id');
            },
            getMarketBySlug: function(slug){
                return $filter('findInArray')(this.markets, slug, 'slug');
            },
            getMarketByName: function(name){
                return $filter('findInArray')(this.markets, name, 'name');
            },
            getProgramByValue: function(value){
                return $filter('findInArray')(this.programs, value, 'value');
            },
            setProgram: function(slug, hardPageLoad, redirect) {
                var self = this;
                var market = self.getMarketBySlug(slug);
                var program = self.getProgramsByMarketId(market.id);
                redirect = (typeof redirect !== "undefined" ? redirect : true);

                if (program.value) {
                    $cookies.put('pls_user_program', program.value);
                }

                $cookies.put('pls_user_role', 'Student');
                $cookies.put('pls_user_lead', market.id);

                if (redirect) {
                    if (slug != 'nursing' && !hardPageLoad) {
                        $location.url('/find-picmonic?market=' + market.slug);
                    } else {
                        window.location = '/find-picmonic?market=' + market.slug;
                    }
                }
            }
        };
    }]);