angular.module('picmonicpublic')
    .controller('UpcomingWebinarsController', ['$scope', '$controller', '$filter', 'UserService', 'WebinarResource', '$compile', '$modal', '$state', '$location', 'IntercomService', '$stateParams', 'MarketOptionsConfig', function ($scope, $controller, $filter, UserService, WebinarResource, $compile, $modal, $state, $location, IntercomService, $stateParams, MarketOptionsConfig) {
        moment.tz.add('America/Los_Angeles|PST PDT|80 70|01010101010101010101010|1BQW0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|15e6');

        WebinarResource.query(function(data) {
            angular.forEach(data, function(value) {
                value.formatted_date = moment(value.times[0].startTime).tz('America/Los_Angeles').format('llll z');

                var slug = 'medicine';
                if (value.subject.toLowerCase().indexOf("nursing") !== -1) {
                    slug = 'nursing'
                }
                value.link = '/webinars/'+slug+'/'+value.webinarKey;
            });

            $scope.webinars = data;
        });

    }]);