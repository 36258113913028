angular.module('picmonic-common')
    .factory("PermissionsService", ['User', '$q', function (User, $q) {
        var permserv = {
            permissions: User.get({resource: 'permissions'}),
            roles: User.get({resource: 'roles'}),
            hasAccess: function (area, perm, action) {
                var has_access;
                if (permserv.permissions.$resolved) {
                    has_access = {
                        result: permserv._checkPermissions(area, perm, action)
                    };
                } else {
                    var deferred = $q.defer();
                    has_access = {'$promise': deferred.promise};
                    has_access.$promise.then(function (value) {
                        has_access.result = value;
                    });

                    permserv.permissions.$promise.then(function () {
                        deferred.resolve(permserv._checkPermissions(area, perm, action));
                    });
                }

                return has_access;
            },
            hasRole: function (role, noAdmin) {
                if(permserv.roles.$resolved) {
                    return { result: permserv._checkRoles(role, noAdmin) };
                } else {
                    var deferred = $q.defer();

                    var has_role = {'$promise': deferred.promise};
                    has_role.$promise.then(function (value) {
                        has_role.result = value;
                    });

                    permserv.roles.$promise.then(function () {
                        deferred.resolve(permserv._checkRoles(role, noAdmin));
                    });

                    return has_role;
                }
            },
            _checkPermissions: function (area, perm, action) {
                if (permserv.permissions["superadmin"]) {
                    return true;
                }
                else if (action && permserv.permissions["permissions"][area] && permserv.permissions["permissions"][area][perm] && permserv.permissions["permissions"][area][perm].indexOf(action) !== -1) {
                    return true;
                }
                else if (!action && permserv.permissions["permissions"][area] && permserv.permissions["permissions"][area][perm]) {
                    return true;
                }
                else {
                    return false;
                }
            },
            _checkRoles: function(role, noAdmin) {
                var roles = [role];
                if (!noAdmin) {
                    roles.push('superadmin');
                }
                
                for (var x  in this.roles.roles) {
                    if (roles.indexOf(this.roles.roles[x].name.toLowerCase()) !== -1) {
                        return true;
                    }
                }
                return false;
            }
        };
        return permserv;
    }]);