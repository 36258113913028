angular.module('picmonic-common')
  .directive('forceAutoplay', function($rootScope) {
    return {
      restrict: 'A',
      link: function($scope, element, attrs) {
        element[0].play();
        element.bind('click', function() {
          element[0].play();
        });
      }
    }
  });
