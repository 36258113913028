angular.module('picmonic-common')
    .factory("MarketSelectorModalService", ['$modal', '$cookies', '$stateParams', 'MarketOptionsConfig', 'UserService', function($modal, $cookies, $stateParams, MarketOptionsConfig, UserService) {

        return {
            showMarketSelectorModal: function(market, setCookie) {
                var marketUrl = null;
                nextPage = '/find-picmonic';

                if (market) {
                    var marketObj = MarketOptionsConfig.getMarketByName(market);
                    marketUrl = nextPage+'?market=' + marketObj.slug + ($stateParams.pathway ? '&pathway=' + $stateParams.pathway : '') + ($stateParams.path ? '&path=' + $stateParams.path : '') + ($stateParams.pathway_type ? '&pathway_type=' + $stateParams.pathway_type : '') + ($stateParams.curriculum ? '&curriculum=' + $stateParams.curriculum : '') +  '&noMarketModal';
                } else if ($cookies.get('pls_user_lead')) {
                    var userMarket = MarketOptionsConfig.getMarketById($cookies.get('pls_user_lead'));
                    if (userMarket.slug) {
                        marketUrl = nextPage+'?market=' + userMarket.slug + ($stateParams.pathway ? '&pathway=' + $stateParams.pathway : '') + ($stateParams.path ? '&path=' + $stateParams.path : '') + ($stateParams.pathway_type ? '&pathway_type=' + $stateParams.pathway_type : '') + ($stateParams.curriculum ? '&curriculum=' + $stateParams.curriculum : '') +  '&noMarketModal';
                    }
                }

                if (marketUrl) {
                    window.location.href = marketUrl;
                    return;
                } else if (UserService.user.user_id && UserService.user.lead) {
                    userMarket = MarketOptionsConfig.getMarketById(UserService.user.lead);
                    if (userMarket.slug) {
                        window.location.href = nextPage+'?market=' + userMarket.slug + ($stateParams.pathway ? '&pathway=' + $stateParams.pathway : '') + ($stateParams.path ? '&path=' + $stateParams.path : '') + ($stateParams.pathway_type ? '&pathway_type=' + $stateParams.pathway_type : '') + ($stateParams.curriculum ? '&curriculum=' + $stateParams.curriculum : '') +  '&noMarketModal';
                        return;
                    }
                }

                send_ga('event', 'New User Flow', 'Launch Market Modal', 'Try It Now');

                if(typeof(atlasEvent) !== 'undefined') {
                    atlasEvent('click', 'launch market modal', 'new_user');
                }

                return $modal.open({
                    templateUrl: '/templates/public/modals/market-selector.html',
                    windowClass: 'market-selector',
                    controller: ['$scope', '$filter', '$modalInstance', 'MenuService', 'MarketOptionsConfig', '$location', function ($scope, $filter, $modalInstance, MenuService, MarketOptionsConfig, $location) {
                        $scope.selectedMarket = 'MOST POPULAR';
                        MenuService.navigation.$promise.then(function(){
                            $scope.markets = $filter('filter')(MenuService.navigation.items, {title: 'Subjects'}, true)[0];
                        });

                        $scope.selectMarket = function(market) {
                            $scope.selectedMarket = (market.title == $scope.selectedMarket ? '' : market.title);
                        };

                        $scope.close = function () {
                            $modalInstance.close();
                        };

                        $scope.selectLead = function(lead) {
                            if (setCookie) {
                                var queryString = lead.url.substring(lead.url.indexOf('?') + 1);
                                var slug = 'medicine';
                                if (queryString.indexOf('&') > -1) {
                                    slug = queryString.substring(queryString.indexOf('market=') + 7, queryString.indexOf('&'));
                                } else {
                                    slug = queryString.substring(queryString.indexOf('market=') + 7);
                                }

                                $cookies.put('pls_user_lead', MarketOptionsConfig.getMarketBySlug(slug).id);
                                $scope.close();
                            } else {
                                lead.url =  lead.url.replace('get-started','find-picmonic');
                                window.location.href = lead.url;
                            }
                        };

                        $scope.goToUrl = function(url) {
                            window.location.href = url;
                        };
                    }]
                });
            }
        };
    }]);