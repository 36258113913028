angular.module('picmonicpublic')
    .controller('QOTDController', ['$scope', '$controller', '$filter', 'UserService', 'WordPressResource', '$compile', '$modal', '$state', '$location', 'IntercomService', function ($scope, $controller, $filter, UserService, WordPressResource, $compile, $modal, $state, $location, IntercomService) {
        $controller('LoginController', {$scope: $scope});
        $controller('RegisterStep1Controller', {$scope: $scope});

        $scope.today = $filter('date')(new Date(), 'yyyy-MM-dd');
        $scope.registration_source_page = 'nursing-nclex-question-of-the-day';
        UserService.setCookieIfNotLoggedIn('lead', 101);

        var params = {
            resource: 'pages',
            slug: 'question-of-the-day'
        };

        WordPressResource.get(params, function(res) {
            if(res.length) {
                $scope.page = res[0];
                angular.element('#compiledPageContent').html($compile($scope.page.content.rendered)($scope));
                $scope.$emit('endInteraction');
            } else {
                $state.transitionTo('public.404');
            }
        }, function(){
            $state.transitionTo('public.404');
        }).$promise.finally(function(){
            $scope.loading = false;
        });

        $scope.showRegisterModal = function() {
            $modal.open({
                templateUrl: '/templates/modals/register-modal.html',
                windowClass: 'learn-master-complete registration-cta-modal full-screen-modal',
                backdropClass: 'below-nav',
                size: 'sm',
                scope: $scope,
                backdrop: 'static',
                controller: 'RegisterModalController',
                resolve: {
                    card: function () {
                        return;
                    },
                    copy: function () {
                        return;
                    },
                    market_id: function() {
                        return 101;
                    },
                    redirect: function() {
                        IntercomService.trackEvent('qotd-signup');
                        if ($location.search().redirect) {
                            return $location.search().redirect;
                        } else {
                            return '/nursing-nclex-question-of-the-day';
                        }

                    },
                    registration_source_page: function() {
                        return 'nursing-nclex-question-of-the-day';
                    }
                }
            });
        };


        $scope.submitModalRegistration = function(){
            $scope.user.lead = 101;
            $scope.user.accessToken = null;
            $scope.loading = true;
            $scope.error = '';

            // Call RegisterStep1Controller.register
            $scope.register(false).then(function(data){
                IntercomService.trackEvent('qotd-signup');
                if ($location.search().redirect) {
                    document.location.href = $location.search().redirect;
                } else {
                    document.location.href = '/nursing-nclex-question-of-the-day/'+$scope.today;
                }
            }, function(error){
                $scope.error = error.data.message.replace('/login', '/login?redirect='+$scope.current_url);
                $scope.loading = false;
            });
        };

    }]);