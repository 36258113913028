angular.module('picmonic-common')
    .directive('quizPlayer', ['Picmonics', '$filter', '$interval', '$q', 'hotkeys', 'UserService', '$state', 'deviceDetector', '$rootScope', '$timeout', 'LearnService', 'PicmonicQueue', '$window', function(Picmonics, $filter, $interval, $q, hotkeys, UserService, $state, deviceDetector, $rootScope, $timeout, LearnService, PicmonicQueue, $window) {
        return {
            restrict: 'E',
            templateUrl: '/templates/directives/quiz-player.html',
            link: function(scope, element, attrs) {
                scope.deviceDetector = deviceDetector.raw;

                scope.$watch(function(){
                    return attrs.factId;
                }, function(){
                    LearnService.seek(0);
                });

                scope.$watch(function(){
                    return attrs.slug;
                }, function(){
                    if (LearnService.currentCard.slug != attrs.slug) {
                        scope.getCard();
                    }
                });

                $window.onbeforeunload = function (event) {
                    LearnService.saveCardAttempt(0);
                };

                scope.$on('$destroy', function () {
                    LearnService.saveCardAttempt(0);
                });

                scope.getCard = function() {
                    LearnService.reset();
                    scope.loading = true;
                    LearnService.insideQuiz = true;
                    LearnService.quizRoundId = attrs.quizRoundId;

                    LearnService.getCard(attrs.slug, true, true).then(function (resolvedData) {

                        $rootScope.$broadcast('cardDataChanged', {
                            action: 'learn_get',
                            card_id: LearnService.currentCard.card_id
                        });

                        LearnService.init(resolvedData);

                        LearnService.startCard();
                        LearnService.factsOpen = false;
                        LearnService.assetStatus = [];
                        LearnService.assetsLoaded = true;
                        scope.$emit('endInteraction');
                        scope.loading = false;

                        if (LearnService.preLearnVisible) {
                            $rootScope.bodyClass.push('prelearn-overlay-mobile-lock');
                            if (UserService.user.user_id) {
                                $timeout(function () {
                                    angular.element('#prelearn-play-button').focus().select();
                                });
                            } else {
                                $timeout(function () {
                                    angular.element('#play-button-border').focus().select();
                                });
                            }
                        }

                        if (UserService.user.user_id) {
                            LearnService.loadCommunityFacts();
                            UserService.getRecommendations();

                            scope.related = Picmonics.query({
                                card_id: resolvedData.currentCard.card_id,
                                type: 'by_fact',
                                fact_id: $filter('filter')(resolvedData.currentCard.facts, {is_primary: 1})[0].fact_id
                            });
                        }

                    }, function (error) {
                        alert("Error");
                    }).finally(function () {
                        scope.loading = false;
                    });
                };
            }
        };
    }]);
