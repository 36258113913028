angular.module('picmonic-common')
    .directive('videoModal', function () {
      return {
        restrict: 'A',
        scope: {
          videoId: '@videoModal'
        },
        controller: ['$scope', '$modal', function ($scope, $modal) {
          var videoId = $scope.videoId;
          $scope.openModal = function () {
            $modal.open({
              templateUrl: '/templates/modals/video-modal.html',
              windowClass: 'learn-light-modal video-modal',
              keyboard: true,
              controller: ['$scope', '$modalInstance', function ($scope, $modalInstance) {
                $scope.videoId = videoId;
                $scope.playerVars = {
                  autoplay: 1,
                  controls: 2,
                  color: 'white',
                  fs: 0,
                  modestbranding: 1,
                  rel: 0
                };

                $scope.close = function () {
                  $modalInstance.close();
                };
              }]
            });
          };
        }],
        link: function (scope, element, attrs) {
          element.bind('click', function () {
            scope.$apply(function(){
              scope.openModal();
            });
          });
        }
      };
    });