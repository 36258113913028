angular.module('picmonic-common')
    .factory("QuestionOfTheDay", ['$http', function($http) {
        return {
            addQuestion: function(formdata, choices) {
                return $http.post('/apiv2/questionoftheday', {question: formdata, choices: choices}).success(function(data, status) {
                    return data;
                });
            },

            deleteQuestion: function(question_of_the_day_id) {
                return $http.delete('/apiv2/questionoftheday/' + question_of_the_day_id).success(function(result) {
                    return result;
                });
            }
        }; // return {}
    }])

    .factory('QuestionOfTheDayResource', ['$resource', function($resource){
        return $resource('/apiv2/questionoftheday/:question_of_the_day_id', {question_of_the_day_id: '@question_of_the_day_id'},{
            'update':{method:'PUT', isArray:false}
        });
    }]);