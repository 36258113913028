angular.module('picmonic-common')
    .directive("factCharacter", ['$state', '$q', '$timeout', '$window', '$filter', function($state, $q, $timeout, $window, $filter){
        return {
            restrict: "E",
            replace: true,
            scope:{
                fact: '=',
                character: '=',
                playerService: '=',
                size: '=',
                zoom: '=',
                forceZoom: '=?',
                preventZoom: '@?',
                noBlur: '=',
                doesBlink: '=',
                startBlink: '=',
                doesFade: '=',
                zIndex: '='
            },
            template:
                '<div class="fact-character" ng-class="{selected: character.selected, primary: character.is_primary, doesBlink: blink, fades: doesFade, slowzoom: slowZoom, bounce: bounce, cropping: cropping}" ng-style="maskStyles">' +
                    '<div class="character-tools-wrapper" ng-show="playerService.mode == \'editor\'">' +
                        '<div class="resize-anchor top left"></div>' +
                        '<div class="resize-anchor top right"></div>' +
                        '<div class="resize-anchor bottom left"></div>' +
                        '<div class="resize-anchor bottom right"></div>' +
                        '<div class="fact-editor-tools-wrapper">' +
                            '<div class="fact-editor-tools" ng-show="!cropping">' +
                                '<button ng-click="deleteCharacter()"><i class="icon-trash"></i></button>' +
                                '<button ng-click="toggleCropping()"><i class="icon-crop"></i></button>' +
                                '<button ng-click="flipHorizontal()"><i class="icon-mirror-horizontal"></i></button>' +
                                '<button ng-click="flipVertical()"><i class="icon-mirror-vertical"></i></button>' +
                                '<button ng-click="moveDown()" ng-if="character.character_z > lowestZ()"><i class="icon-caret-down"></i></button>' +
                                '<button ng-click="moveUp()" ng-if="character.character_z < highestZ()"><i class="icon-caret-up"></i></button>' +
                            '</div>' +
                            '<div class="fact-editor-tools fact-editor-tools--crop" ng-show="cropping">' +
                                '<button class="pls-btn" ng-click="toggleCropping()">Done</button>' +
                            '</div>' +
                        '</div>' +
                    '</div>' +
                    '<div class="asset-mask">' +
                        '<div class="asset-wrapper" ng-class="{mirrored_x: character.mirrored_x, mirrored_y: character.mirrored_y}" ng-style=characterStyles></div>' +
                    '</div>' +
                '</div>',
            controller: ['$scope', '$filter', 'UserService', function($scope, $filter, UserService) {
                $scope.maskStyles = {};
                $scope.characterStyles = {};
                $scope.element = false;
                $scope.slowZoom = false;
                $scope.previouslyZoomed = false;
                $scope.cropping = false;

                $scope.isFactSelected = function(){
                    return $scope.playerService.location == 'quiz' || $scope.fact.selected || ($scope.fact.is_primary && $scope.playerService.tab == 'topic');
                };

                function determineDimensions(){
                    if(!$scope.container) { return; }
                    var zoomPrevented = ($scope.preventZoom == 1 || ($scope.fact && $scope.fact.prevent_zoom == 1));
                    var zoomRequested = $scope.fact && $scope.isFactSelected() && ((window.innerWidth < 480 && $scope.zoom) || $scope.forceZoom);
                    var doZoom = (zoomPrevented == false && zoomRequested == true);

                    if ($scope.fact && doZoom) {
                        var minx;
                        var miny;
                        var maxx;
                        var maxy;

                        if ($scope.fact.characters.length > 0) {
                            for (a in $scope.fact.characters) {
                                var character = $scope.fact.characters[a];

                                minx = typeof minx != 'undefined' ? ((character.character_x + character.crop_x) < minx ? (character.character_x + character.crop_x) : minx) : (character.character_x + character.crop_x);
                                miny = typeof miny != 'undefined' ? ((character.character_y + character.crop_y) < miny ? (character.character_y + character.crop_y) : miny) : (character.character_y + character.crop_y);
                                maxx = typeof maxx != 'undefined' ? (character.character_x + character.crop_x + character.crop_width > maxx ? character.character_x + character.crop_x + character.crop_width : maxx) : character.character_x + character.crop_x + character.crop_width;
                                maxy = typeof maxy != 'undefined' ? (character.character_y + character.crop_y + character.crop_height > maxy ? character.character_y + character.crop_y + character.crop_height : maxy) : character.character_y + character.crop_y + character.crop_height;
                            }

                            var boundingHeight = maxy - miny,
                                boundingWidth = maxx - minx,
                                containerWidth = $scope.container.width(),
                                containerHeight = $scope.container.height();

                            var left, top, ratio;

                            if(boundingWidth > boundingHeight && ((containerWidth / boundingWidth) * boundingHeight < containerHeight)){
                                ratio = containerWidth / boundingWidth;
                            }else{
                                ratio = containerHeight / boundingHeight;
                            }

                            top = (containerHeight - (boundingHeight * ratio)) / 2;
                            left = (containerWidth - (boundingWidth * ratio)) / 2;

                            var maskStyles = {
                                width: ((($scope.character.crop_width * ratio) / containerWidth) * 100) + '%',
                                height: ((($scope.character.crop_height * ratio) / containerHeight) * 100) + '%',
                                left: ((((((($scope.character.character_x + $scope.character.crop_x) - minx) * ratio) + left)) / containerWidth) * 100) + '%',
                                top: ((((((($scope.character.character_y + $scope.character.crop_y) - miny) * ratio) + top)) / containerHeight) * 100) + '%'
                            };

                            $scope.previouslyZoomed = true;
                        }
                    } else {
                        if ($scope.previouslyZoomed) {
                            $scope.previouslyZoomed = false;
                            if ($scope.playerService.location == 'quiz') {
                                $scope.slowZoom = true;
                            }
                        } else if ($scope.slowZoom) {
                            $scope.slowZoom = false;
                        }

                        var maskStyles = {
                            width: ($scope.character.crop_width ? (($scope.character.crop_width / 2200) * 100)+'%': (($scope.character.character_width / 2200) * 100)+'%'),
                            height: ($scope.character.crop_height ? (($scope.character.crop_height / 1700) * 100)+'%': (($scope.character.character_height / 1700) * 100)+'%'),
                            left: ($scope.character.crop_x ? ((($scope.character.crop_x + $scope.character.character_x) / 2200) * 100)+'%': (($scope.character.character_x / 2200) * 100)+'%'),
                            top: ($scope.character.crop_y ? ((($scope.character.crop_y + $scope.character.character_y) / 1700) * 100)+'%': (($scope.character.character_y / 1700) * 100)+'%'),
                        };
                    }

                    var styles = {
                        width: $scope.character.crop_width ? (($scope.character.character_width / $scope.character.crop_width) * 100)+'%' : '100%',
                        height: $scope.character.crop_height ? (($scope.character.character_height / $scope.character.crop_height) * 100)+'%' : '100%',
                        left: $scope.character.crop_x ? (($scope.character.crop_x / $scope.character.crop_width) * -100) + '%' : '0%',
                        top: $scope.character.crop_y ? (($scope.character.crop_y / $scope.character.crop_height) * -100) + '%' : '0%',
                        'z-index': $scope.character.character_z ? $scope.character.character_z : ''
                    };

                    if($scope.zIndex) {
                        styles.zIndex = $scope.zIndex;
                    }

                    if(($scope.playerService.mode == 'review' && $scope.playerService.reviewStep == 1 && !$scope.playerService.modalInstance) || ($scope.playerService.showBlur && !$scope.noBlur)) {
                        var quizBlur = UserService.user.quiz_blur > 15 ? 15 : UserService.user.quiz_blur;
                        styles['-webkit-filter']    = 'blur(' + quizBlur + 'px)';
                        styles['-moz-filter']       = 'blur(' + quizBlur + 'px)';
                        styles['-o-filter']         = 'blur(' + quizBlur + 'px)';
                        styles['-ms-filter']        = 'blur(' + quizBlur + 'px)';
                        styles['filter']            = 'blur(' + quizBlur + 'px)';
                    }




                    $scope.maskStyles = maskStyles;
                    $scope.characterStyles = styles;

                    return styles;
                }

                determineDimensions();

                if ($scope.fact) {
                    $scope.$watch(function () {
                        return $scope.fact.selected;
                    }, function (value) {
                        if (value != undefined) {
                            $timeout(determineDimensions, 0);
                        }
                    });
                }

                $scope.$watch(function () {
                    return $scope.playerService.showBlur;
                }, function (value) {
                    if (value != undefined) {
                        $timeout(determineDimensions, 0);
                    }
                });

                $scope.$watch(function () {
                    return UserService.user.quiz_blur;
                }, function (value) {
                    if (value != undefined) {
                        $timeout(determineDimensions, 0);
                    }
                });

                $scope.$watch(function () {
                    return ($scope.zoom || $scope.forceZoom) ? true : false;
                }, function (value) {
                    if (value != undefined) {
                        $timeout(determineDimensions, 0);
                    }
                });

                if ($scope.playerService.reviewStep) {
                    $scope.$watch(function () {
                        return $scope.playerService.reviewStep
                    }, function (value) {
                        if (value == 2 && $scope.fact && $scope.fact.selected) {
                            $timeout(determineDimensions, 0);
                        }
                    });
                }

                $scope.$watch(function () {
                    return $scope.playerService.modalInstance
                }, function (value) {
                    $timeout(determineDimensions, 0);
                });

                $scope.$watch(function(){
                    return $scope.playerService.mode;
                }, function(value){
                    if(value != undefined){
                        $timeout(determineDimensions, 0);
                    }
                });

                $scope.$watch(function(){
                    return $scope.character.selected;
                }, function(){
                    if(!$scope.character.selected && $scope.cropping){
                        $scope.cropping = false;
                    }
                });

                /**
                 * Watching character.character_z for moveUp/moveDown digest cycle
                 * trigger to update all the other placed characters z-index.
                 */
                $scope.$watch('character.character_z', function() {
                    determineDimensions();
                });

                angular.element($window).resize(determineDimensions);

                $scope.deleteCharacter = function(){
                    var characterPos = $scope.fact.characters.indexOf($scope.character);

                    if(!$scope.character.card_fact_character_id){
                        $scope.fact.characters = _.without($scope.fact.characters, $scope.character);
                    }else{
                        $scope.character.deleted = true;
                    }

                    var remainingFacts = $filter('filter')($scope.fact.characters, {deleted: '!true'});

                    if(!remainingFacts.length){
                        delete $scope.fact.hotspot_x;
                        delete $scope.fact.hotspot_y;
                    }else if(characterPos == 0){
                        $scope.fact.hotspot_x = remainingFacts[0].character_x + (remainingFacts[0].character_width / 2);
                        $scope.fact.hotspot_y = remainingFacts[0].character_y + (remainingFacts[0].character_height / 2);
                    }
                    $scope.playerService.addToHistory();
                };

                $scope.moveDown = function(){
                    if($scope.character.character_z - 1 >= $scope.lowestZ()){
                        var nextLowestCharacter = $scope.nextLowestCharacter();
                        $scope.character.character_z = nextLowestCharacter.character_z;
                        nextLowestCharacter.character_z++;
                        $scope.playerService.addToHistory();
                    }
                };

                $scope.moveUp = function(){
                    if($scope.character.character_z + 1 <= $scope.highestZ()){
                        var nextHighestCharacter = $scope.nextHighestCharacter();
                        $scope.character.character_z = nextHighestCharacter.character_z;
                        nextHighestCharacter.character_z--;
                        $scope.playerService.addToHistory();
                    }
                };

                $scope.lowestZ = function(){
                    if($scope.playerService.mode == 'editor'){
                        var lowestZ,
                            facts = $scope.playerService.currentFacts,
                            fact,
                            character;

                        for(var a in facts){
                            fact = facts[a];

                            for(var b in fact.characters){
                                character = fact.characters[b];

                                if(!character.deleted && (!lowestZ || character.character_z < lowestZ)){
                                    lowestZ = character.character_z;
                                }
                            }
                        }

                        return lowestZ;
                    }
                };

                $scope.highestZ = function(){
                    if($scope.playerService.mode == 'editor'){
                        var highestZ,
                            facts = $scope.playerService.currentFacts,
                            fact,
                            character;

                        for(var a in facts){
                            fact = facts[a];

                            for(var b in fact.characters){
                                character = fact.characters[b];

                                if(!character.deleted && (!highestZ || character.character_z > highestZ)){
                                    highestZ = character.character_z;
                                }
                            }
                        }

                        return highestZ;
                    }
                };

                $scope.nextHighestCharacter = function(){
                    var facts = $scope.playerService.currentFacts,
                        fact,
                        characters = [];

                    for(var a in facts){
                        fact = facts[a];

                        for(var b in fact.characters){
                            if(!fact.characters[b].deleted){
                                characters.push(fact.characters[b]);
                            }
                        }
                    }

                    characters = $filter('orderBy')(characters, 'character_z');

                    return characters[$filter('arrayPos')(characters, $scope.character.character_z, 'character_z')+1];
                };

                $scope.nextLowestCharacter = function(){
                    var facts = $scope.playerService.currentFacts,
                        fact,
                        characters = [];

                    for(var a in facts){
                        fact = facts[a];

                        for(var b in fact.characters){
                            if(!fact.characters[b].deleted){
                                characters.push(fact.characters[b]);
                            }
                        }
                    }

                    characters = $filter('orderBy')(characters, 'character_z');

                    return characters[$filter('arrayPos')(characters, $scope.character.character_z, 'character_z')-1];
                };

                //Horizontal Flip requires that the char+x be offset by the width/crop_width diff in
                //either a positive or negitive direction depending on if flip is true or false
                $scope.flipHorizontal = function(){
                    var originalCropX = $scope.character.crop_x;
                    var originalCharacterX = $scope.character.character_x;
                    var xOffset = originalCropX - ( $scope.character.character_width - ( originalCropX + $scope.character.crop_width ));
                    
                    $scope.character.character_x += xOffset;
                    $scope.character.mirrored_y = !$scope.character.mirrored_y;
                    $scope.character.crop_x = $scope.character.character_width - ($scope.character.crop_x + $scope.character.crop_width);
                    determineDimensions();
                    $scope.playerService.addToHistory();
                };
                
                //Vertical Flip requires that the char+y be offset by the height/crop_height diff in
                //either a positive or negitive direction depending on if flip is true or false
                $scope.flipVertical = function(){
                    var originalCropY = $scope.character.crop_y;
                    var yOffset = originalCropY - ( $scope.character.character_height - ( originalCropY + $scope.character.crop_height ));

                    $scope.character.character_y += yOffset;
                    $scope.character.mirrored_x = !$scope.character.mirrored_x;
                    $scope.character.crop_y = $scope.character.character_height -   ($scope.character.crop_y + $scope.character.crop_height);
                    determineDimensions();
                    $scope.playerService.addToHistory();
                };

                $scope.toggleCropping = function(){
                    if (!$scope.cropping) {
                        angular.element('body').on("keypress", $scope.toggleCroppingOnEnter);
                    } else {
                        angular.element('body').off("keypress", $scope.toggleCroppingOnEnter);
                    }

                    $scope.cropping = !$scope.cropping;
                };

                $scope.toggleCroppingOnEnter = function(event){
                    if(event.charCode === 13) {
                        $scope.$apply(function(){ $scope.toggleCropping(); });
                    }
                };
            }],

            link: function(scope, element, attrs){
                var sizeSuffix = '';

                if (window.innerWidth < 480) {
                    scope.size = 'sm';
                }

                switch(scope.size){
                    case 'md':
                        sizeSuffix = '_threequarter';
                        break;
                    case 'sm':
                        sizeSuffix = '_half';
                        break;
                    case 'xs':
                        sizeSuffix = '_quarter';
                        break;
                }

                var container = scope.container = element.parents('.learn-image-wrapper');


                // Handle blink
                if(scope.doesBlink) {
                    scope.$watch(function () {
                        return scope.startBlink;
                    }, function (value) {
                        if (value) {
                            scope.forceZoom = true;
                            scope.playerService.animationDone = false;
                            $timeout(function () {
                                $timeout(function() {
                                    scope.bounce = false;
                                    scope.forceZoom = false;
                                    $timeout(function() {
                                        scope.playerService.animationDone = true;
                                    }, 1000);
                                }, 1000);
                                scope.bounce = true;
                                element.addClass('blink');
                            }, 100);
                        }
                    });
                }

                for(var a in scope.character.assets){
                    var asset = scope.character.assets[a];

                    if(asset.image_url){
                        var assetStatus = $q(function(resolve, reject){
                            var img = new Image();

                            img.addEventListener('load', function(){
                                element.find('.asset-wrapper').append(img);
                                determineToolPosition();
                                resolve();
                            });

                            img.addEventListener('error', function(e){
                                reject();
                            });

                            img.src = asset.image_url.replace('picmonic_assets','picmonic_assets' + sizeSuffix);

                            img.style.width = ((asset.width / scope.character.original_width) * 100) + '%';
                            img.style.height = ((asset.height / scope.character.original_height) * 100) + '%';
                            img.style.left = ((asset.x / scope.character.original_width) * 100) + '%';
                            img.style.top = ((asset.y / scope.character.original_height) * 100) + '%';
                            img.style['z-index'] = asset.z;
                        });

                        scope.playerService.assetStatus.push(assetStatus);
                    }
                }

                if(scope.fact){
                    if(scope.playerService.mode == 'editor' && ((scope.fact.is_primary && scope.playerService.hasAccess('workflow', 'topic_character', scope.fact)) || (!scope.fact.is_primary && scope.playerService.hasAccess('workflow', 'fact_character', scope.fact)))){
                        var prevx, prevy, selectedAnchor, originalValues;

                        var getDistanceMoved = function(e){
                            var ratiox = 2200 / container.width();
                            var ratioy = 1700 / container.height();

                            var moved = {
                                x: (e.pageX - prevx) * ratiox,
                                y: (e.pageY - prevy) * ratioy
                            };

                            prevx = e.pageX;
                            prevy = e.pageY;

                            return moved;
                        };

                        var moveHandler = function(e){
                            var moved = getDistanceMoved(e);

                            var new_x= Math.max(Math.min((originalValues.character_x + originalValues.crop_x) + moved.x, 2200 - originalValues.crop_width), 0);
                            var new_y = Math.max(Math.min((originalValues.character_y + originalValues.crop_y) + moved.y, 1700 - originalValues.crop_height), 0);

                            if($filter('filter')(scope.fact.characters, {deleted: '!true'}).indexOf(scope.character) === 0){
                                originalValues.hotspot_x += moved.x;
                                originalValues.hotspot_y += moved.y;
                            }

                            originalValues.character_x = new_x - originalValues.crop_x;
                            originalValues.character_y = new_y - originalValues.crop_y;

                            applyNewStyles();
                        };

                        var resizeHandler = function(e){
                            e.stopPropagation();

                            var anchor = angular.element(selectedAnchor);
                            var moved = getDistanceMoved(e);

                            if(scope.cropping){
                                if(anchor.hasClass('left')) {
                                    var new_x = Math.max(Math.min(originalValues.crop_x + moved.x, originalValues.character_width), 0);
                                    var updatedCropWidth = originalValues.crop_width - (new_x - originalValues.crop_x);
                                    originalValues.crop_width = (updatedCropWidth > 0) ? updatedCropWidth : originalValues.crop_width;
                                    originalValues.crop_x = new_x;
                                }else{
                                    originalValues.crop_width = Math.max(Math.min(originalValues.crop_width + moved.x, originalValues.character_width - originalValues.crop_x), 0.1);
                                }

                                if(anchor.hasClass('top')){
                                    var new_y = Math.max(Math.min(originalValues.crop_y + moved.y, originalValues.character_height), 0);
                                    var updateCropHeight = originalValues.crop_height - (new_y - originalValues.crop_y);
                                    originalValues.crop_height = (updateCropHeight > 0) ? updateCropHeight : originalValues.crop_height;
                                    originalValues.crop_y = new_y;
                                }else{
                                    originalValues.crop_height = Math.max(Math.min(originalValues.crop_height + moved.y, originalValues.character_height - originalValues.crop_y), 0.1);
                                }

                                if(scope.fact.characters.indexOf(scope.character) == 0) {
                                    if(originalValues.character_x + originalValues.crop_x > originalValues.hotspot_x){
                                        originalValues.hotspot_x = originalValues.character_x + originalValues.crop_x;
                                    }else if (originalValues.character_x + originalValues.crop_x + originalValues.crop_width < originalValues.hotspot_x){
                                        originalValues.hotspot_x = originalValues.character_x + originalValues.crop_x + originalValues.crop_width;
                                    }

                                    if(originalValues.character_y + originalValues.crop_y > originalValues.hotspot_y){
                                        originalValues.hotspot_y = originalValues.character_y + originalValues.crop_y;
                                    }else if (originalValues.character_y + originalValues.crop_y + originalValues.crop_height < originalValues.hotspot_y){
                                        originalValues.hotspot_y = originalValues.character_y + originalValues.crop_y + originalValues.crop_height;
                                    }
                                }

                            }else{
                                var hotspot_x_ratio = (originalValues.hotspot_x - (originalValues.character_x + originalValues.crop_x)) / originalValues.crop_width;
                                var hotspot_y_ratio = (originalValues.hotspot_y - (originalValues.character_y + originalValues.crop_y)) / originalValues.crop_height;

                                if(anchor.hasClass('left')){
                                    var ratio = moved.x / originalValues.crop_width;
                                    originalValues.character_x += (originalValues.crop_x * ratio) + moved.x;
                                    originalValues.character_width -= originalValues.character_width * ratio;
                                    originalValues.crop_x -= originalValues.crop_x * ratio;
                                    originalValues.crop_width -= originalValues.crop_width * ratio;
                                }else{
                                    var ratio = moved.x / originalValues.crop_width;
                                    originalValues.character_x -= originalValues.crop_x * ratio;
                                    originalValues.character_width += originalValues.character_width * ratio;
                                    originalValues.crop_x += originalValues.crop_x * ratio;
                                    originalValues.crop_width += originalValues.crop_width * ratio;
                                }

                                if(anchor.hasClass('top')){
                                    var ratio = moved.y / originalValues.crop_height;
                                    originalValues.character_y += (originalValues.crop_y * ratio) + moved.y;
                                    originalValues.character_height -= originalValues.character_height * ratio;
                                    originalValues.crop_y -= originalValues.crop_y * ratio;
                                    originalValues.crop_height -= originalValues.crop_height * ratio;
                                }else{
                                    var ratio = moved.y / originalValues.crop_height;
                                    originalValues.character_y -= originalValues.crop_y * ratio;
                                    originalValues.character_height += originalValues.character_height * ratio;
                                    originalValues.crop_y += originalValues.crop_y * ratio;
                                    originalValues.crop_height += originalValues.crop_height * ratio;
                                }

                                if(scope.fact.characters.indexOf(scope.character) == 0) {
                                    originalValues.hotspot_x = originalValues.character_x + originalValues.crop_x + (originalValues.crop_width * hotspot_x_ratio);
                                    originalValues.hotspot_y = originalValues.character_y + originalValues.crop_y + (originalValues.crop_height * hotspot_y_ratio);
                                }
                            }

                            applyNewStyles();
                        };

                        var applyNewStyles = function(){
                            var maskStyles = {
                                width: (originalValues.crop_width ? ((originalValues.crop_width / 2200) * 100)+'%': ((originalValues.character_width / 2200) * 100)+'%'),
                                height: (originalValues.crop_height ? ((originalValues.crop_height / 1700) * 100)+'%': ((originalValues.character_height / 1700) * 100)+'%'),
                                left: (originalValues.crop_x ? (((originalValues.crop_x + originalValues.character_x) / 2200) * 100)+'%': ((originalValues.character_x / 2200) * 100)+'%'),
                                top: (originalValues.crop_y ? (((originalValues.crop_y + originalValues.character_y) / 1700) * 100)+'%': ((originalValues.character_y / 1700) * 100)+'%'),
                            };

                            var styles = {
                                width: originalValues.crop_width ? ((originalValues.character_width / originalValues.crop_width) * 100)+'%' : '100%',
                                height: originalValues.crop_height ? ((originalValues.character_height / originalValues.crop_height) * 100)+'%' : '100%',
                                left: originalValues.crop_x ? ((originalValues.crop_x / originalValues.crop_width) * -100) + '%' : '0%',
                                top: originalValues.crop_y ? ((originalValues.crop_y / originalValues.crop_height) * -100) + '%' : '0%',
                                'z-index': scope.character.character_z ? scope.character.character_z : ''
                            };

                            element.css(maskStyles);
                            element.find('.asset-wrapper').css(styles);

                            if(scope.isFactSelected()){
                                angular.element('.learn-hotspot').css({
                                    top: 'calc(' + ((originalValues.hotspot_y / 1700) * 100) + '% - 10px)',
                                    left: 'calc(' + ((originalValues.hotspot_x / 2200) * 100) + '% - 10px)'
                                });
                            }

                            determineToolPosition();
                        };

                        var updateHandler = function(e){
                            delete selectedAnchor;
                            angular.element('body').unbind('mousemove', moveHandler);
                            angular.element('body').unbind('mousemove', resizeHandler);
                            angular.element('body').unbind('mouseup', updateHandler);

                            var dataUpdated = false;

                            for(var a in originalValues){
                                if(a == 'hotspot_x' || a == 'hotspot_y'){
                                    if(scope.fact[a] != originalValues[a]){
                                        scope.fact[a] = originalValues[a];
                                        dataUpdated = true;
                                    }
                                }else if(scope.character[a] != originalValues[a]){
                                    scope.character[a] = originalValues[a];
                                    dataUpdated = true;
                                }
                            }

                            if (dataUpdated) {
                                scope.playerService.addToHistory();
                            }

                            if(!element.has(angular.element(e.target)).length){
                                scope.character.selected = false;
                            }

                            scope.$apply();
                        };

                        element.bind('mousedown', function(e){
                            prevx = e.pageX;
                            prevy = e.pageY;

                            originalValues = {
                                character_x: scope.character.character_x,
                                character_y: scope.character.character_y,
                                character_width: scope.character.character_width,
                                character_height: scope.character.character_height,
                                hotspot_x: scope.fact.hotspot_x,
                                hotspot_y: scope.fact.hotspot_y,
                                crop_x: scope.character.crop_x,
                                crop_y: scope.character.crop_y,
                                crop_width: scope.character.crop_width,
                                crop_height: scope.character.crop_height
                            };

                            scope.$apply(function(){
                                scope.playerService.selectCharacter(scope.character);
                            });

                            angular.element('body')
                                .bind('mousemove', moveHandler)
                                .bind('mouseup', updateHandler);
                        });

                        angular.element('body')
                            .bind('keydown', function(e){
                            switch (e.keyCode) {
                                case 8:
                                    if(!angular.element(e.target).is('input, textarea')){
                                        if(scope.character.selected && scope.isFactSelected() && !scope.character.deleted){
                                            e.preventDefault();
                                            scope.deleteCharacter();
                                            scope.$apply();
                                        }
                                    }
                                break;
                            }
                        });

                        element.find('.resize-anchor').bind('mousedown', function(e){
                            selectedAnchor = e.currentTarget;
                            prevx = e.pageX;
                            prevy = e.pageY;
                            angular.element('body').bind('mousemove', resizeHandler);
                        });
                    }
                }

                function determineToolPosition(){
                    var toolWrapper = element.find('.fact-editor-tools-wrapper');

                    var top = parseFloat(element[0].style.top) / 100;
                    var left = parseFloat(element[0].style.left) / 100;

                    if((top * container.height()) - 50 < 0){
                        toolWrapper.css({top: (-50 - (top * container.height() - 50)) + 'px'});
                    }else{
                        toolWrapper.css({top: '-50px'});
                    }

                    if((left * container.width()) < 0){
                        toolWrapper.css({left: ((left * container.width()) * -1) + 'px'});
                    }else{
                        toolWrapper.css({left: '0px'});
                    }
                }

                determineToolPosition();
            }
        }
    }]);
