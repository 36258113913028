angular.module('picmonic-common')
    .directive("schoolInput", [function () {
      return {
        restrict: 'E',
        require: 'ngModel',
        template: '<pls-typeahead dark="dark" styles="{{styles}}" ng-model="ngModel" on-search="runSearch($searchTerm)" on-select="select({$item: $item})" label="name" placeholder="Start typing the name of your school..." pls-touch>{{$parent.option.name}}</pls-typeahead>',
        scope: {
          market: '=',
          ngModel: '=',       // The model object
          setSchool: '&',
          styles: '@',
          showMarket: '=',
          dark: '='
        },
        controller: ['$scope', 'Schools', 'SchoolDataService', 'MarketOptionsConfig', function ($scope, Schools, SchoolDataService, MarketOptionsConfig) {
          $scope.schools = [];
          $scope.selected = null;
          $scope.loadingSchools = false;

          $scope.select = function ($item) {
            $scope.ngModel = $item.$item;
            return $scope.setSchool($item);
          };

          $scope.runSearch = function (query) {
            $scope.loadingSchools = true;
            angular.element('.school-loading.icon-spin').removeClass('hidden');
            return Schools.search({query: query, market_id: $scope.market}).$promise;
          };

          $scope.schoolName = function (school) {
            if (school && $scope.showMarket && school && school.market_id) {
              var market = MarketOptionsConfig.getMarketById(school.market_id);
              if (market) {
                return school.name + ' - ' + market.name;
              }
            }
            return school ? school.name : '';
          };
        }],
        link: function(scope, element, attrs) {
            $('body').on('DOMMouseScroll mousewheel onmousewheel', '.pls-typeahead__results', function(e) {
              var t = $(this);
              var wDelta = e.originalEvent.deltaY < 0 ? 'up' : 'down';
              if (wDelta == 'up' && t.scrollTop() === 0) {
                e.preventDefault();
              }
              else {
                if (wDelta == 'down' && (t.scrollTop() == this.scrollHeight - t.innerHeight())) {
                  e.preventDefault();
                }
              }
            });
        }
      };
    }]);