angular.module('picmonic-common')
    .controller('FooterController', ['$rootScope', '$scope', 'MenuService', 'OnboardingService', 'WordPressResource', '$filter', '$state', function($rootScope, $scope, MenuService, OnboardingService, WordPressResource, $filter, $state){
        $scope.base_asset_url = $rootScope.base_asset_url;
        $scope.footer = MenuService.footer;
        $scope.OnboardingService = OnboardingService;
        $scope.state = $state;
        $scope.onPathways = false;

        if ($state.current.name.indexOf('app.pathways.market') > -1) {
            $scope.onPathways = true;
        }

        WordPressResource.get({
            resource: 'seo_footer'
        }, function(res) {
            if ($state.current.name == 'public.home') {
                $scope.description = $filter('filter')(res, {slug: 'home'})[0];
            } else if ($state.current.name.indexOf('public.question-of-the-day') > -1) {
                $scope.description = $filter('filter')(res, {slug: 'nursing'})[0];
            } else if (($state.current.name.indexOf('public.pricing') > -1) || $scope.onPathways) {
                if ($filter('filter')(res, {slug: $state.params.market}).length > 0) {
                    $scope.description = $filter('filter')(res, {slug: $state.params.market})[0];
                } else {
                    $scope.description = $filter('filter')(res, {slug: 'generic'})[0];
                }
            } else {
                $scope.description = $filter('filter')(res, {slug: 'generic'})[0];
            }
        });

        WordPressResource.get({
            resource: 'trending'
        }, function(res) {
            if ($state.current.name.indexOf('public.question-of-the-day') > -1) {
                $scope.trending = $filter('filter')(res, {slug: 'nursing'})[0];
            } else if (($state.current.name.indexOf('public.pricing') > -1) || $scope.onPathways) {
                if ($filter('filter')(res, {slug: $state.params.market}).length > 0) {
                    $scope.trending = $filter('filter')(res, {slug: $state.params.market})[0];
                } else {
                    $scope.trending = $filter('filter')(res, {slug: 'generic'})[0];
                }
            } else {
                $scope.trending = $filter('filter')(res, {slug: 'generic'})[0];
            }
        });

        $scope.firePricingAtlasEvent = function(url) {
            if (url.indexOf('pricing/') !== -1) {
                if(typeof atlasEvent !== 'undefined') {
                    atlasEvent('click', 'pricing footer nav');
                }
            }
        };
    }]);