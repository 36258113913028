angular.module('picmonic-common')
    .factory('FacebookService', ["$q", function($q) {
        return {
            login: function() {
                var deferred = $q.defer();
                FB.login(function(response) {
                    if (response.status === 'connected') {
                        deferred.resolve(response);
                        // Logged into your app and Facebook.
                    } else if (response.status === 'not_authorized') {
                        // The person is logged into Facebook, but not your app.
                        deferred.reject();
                    } else {
                        // The person is not logged into Facebook, so we're not sure if
                        // they are logged into this app or not.
                        deferred.reject();
                    }

                }, {scope: 'public_profile,email'});

                return deferred.promise;
            }
        }
    }]);