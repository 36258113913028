angular.module('picmonicpublic')
    .controller("PasswordResetStep2Controller", ['$scope', '$location', '$stateParams', 'User', function($scope, $location, $stateParams, User){
        $scope.user = {
            token: $stateParams.token
        };
        $scope.$emit('endInteraction');

        $scope.submit = function(){
            if(!$scope.loading) {
                $scope.loading = true;
                $scope.error = null;
                $scope.user.resource = 'resetpassword';

                User.update($scope.user, function (data) {
                    $scope.loading = false;
                    $scope.message = data.message+'. You will be redirected in 3 seconds.';

                    setTimeout(function(){
                        top.location = $location.protocol()+'://'+$location.host()+'/home';
                    }, 3000);
                }, function (error) {
                    $scope.loading = false;
                    $scope.error = error.data.message;
                });
            }
        };
    }]);
