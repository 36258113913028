angular.module('picmonic-common')
    .controller('QuestionOfTheDayController', ['$scope', '$filter', '$location', '$stateParams', 'QuestionOfTheDayResource', 'UserService', '$modal', 'MetaService', '$cookies', '$timeout', '$rootScope', 'MarketOptionsConfig', 'DateService', 'IntercomService', function($scope, $filter, $location, $stateParams, QuestionOfTheDayResource, UserService, $modal, MetaService, $cookies, $timeout, $rootScope, MarketOptionsConfig, DateService, IntercomService){
        window.dataLayer = window.dataLayer || [];
        $scope.user = UserService.user;

        $scope.selectedAnswers = [];
        $scope.form = {submitted: false};
        $scope.qotd = {};
        $scope.primaryCard = {};
        $scope.allQuestionDates = {};
        $scope.day = $stateParams.day;
        $scope.today = $filter('date')(new Date(), 'yyyy-MM-dd');
        $scope.loading = true;
        $scope.UserService = UserService;
        $scope.noQuestionForDateProvided = false;
        $scope.actionSetId = 1947;
        $scope.formData = {};
        $scope.playerPlaying = false;
        $scope.hideLearnButton = false;
        $scope.highlightLearnButton = false;
        $scope.DateService = DateService;

        $scope.testimonials = [
            {
                type: 'written',
                url: null,
                videoId: null,
                text: "I honestly believe I may not have passed Pharmacology without it.",
                author: "Josephine T., College of the Mainland, class of 2015"
            },
            {
                type: 'video',
                videoId: 'hdV_gKlBua4',
                url: 'https://www.youtube.com/watch?v=hdV_gKlBua4/hdV_gKlBua4',
                text: "",
                author: ""
            },
            {
                type: 'written',
                url: null,
                videoId: null,
                text: "Bravo Team Picmonic on creating a truly revolutionary approach to learning, and for helping me to pass the NCLEX®. Couldn't have done it without you!",
                author: "Steph B., University of Detroit Mercy, class of 2015"
            }
        ];

        $scope.$on('youtube.player.playing', function() {
            if(typeof atlasEvent !== 'undefined') {
                atlasEvent('click', 'Play Video');
            }
        });

        $scope.tileClick = function(tile) {
            if(tile.event_type && typeof atlasEvent !== 'undefined') {
                atlasEvent('click', 'qotd tile - '+tile.event_type);
            }
        };

        $scope.showRegisterModal = function(card) {
            $modal.open({
                templateUrl: '/templates/modals/register-modal.html',
                windowClass: 'learn-master-complete registration-cta-modal full-screen-modal',
                backdropClass: 'below-nav',
                size: 'sm',
                scope: $scope,
                backdrop: 'static',
                controller: 'RegisterModalController',
                resolve: {
                    card: function () {
                        if (card) {
                            return card;
                        }
                    },
                    market_id: function() {
                        return $cookies.get('pls_user_lead');
                    },
                    copy: function() {
                        return 'Sign up for free and get access to Picmonics and an email with an NCLEX® question every day! No strings attached!';
                    },
                    redirect: function() {
                        IntercomService.trackEvent('qotd-signup');
                        var answers = '';
                        angular.forEach($scope.qotd.question_of_the_day_answers, function(answer) {
                            if (answer.selected) {
                                answers += answer.answer_id+'|';
                            }
                        });

                        return '/nursing-nclex-question-of-the-day/'+$scope.day+'?answers='+answers;
                    },
                    registration_source_page: function() {
                        return 'nursing-nclex-question-of-the-day';
                    }
                }
            });
        };

        $scope.showRegisterModalToCard = function(card, location) {
            if (UserService.user.user_id) {
                if (location == 'quiz') {
                    window.open('/quiz/picmonic/'+card.slug);
                } else {
                    window.open('/learn/'+card.slug);
                }
            } else {
                $modal.open({
                    templateUrl: '/templates/modals/register-modal.html',
                    windowClass: 'learn-master-complete registration-cta-modal full-screen-modal',
                    backdropClass: 'below-nav',
                    size: 'sm',
                    scope: $scope,
                    backdrop: 'static',
                    controller: 'RegisterModalController',
                    resolve: {
                        card: function () {
                            return null;
                        },
                        market_id: function () {
                            return $cookies.get('pls_user_lead');
                        },
                        copy: function () {
                            return 'Sign up for free and get access to Picmonics and an email with an NCLEX® question every day! No strings attached!';
                        },
                        redirect: function () {
                            IntercomService.trackEvent('qotd-signup');

                            if (location == 'quiz') {
                                return '/quiz/picmonic/' + card.slug + '?newWindow';
                            } else {
                                return '/learn/' + card.slug + '?newWindow';
                            }
                        },
                        registration_source_page: function () {
                            return 'nursing-nclex-question-of-the-day';
                        }
                    }
                });
            }
        };

        $scope.getTokenCount = function() {
            if ($scope.user && $scope.user.user_id) {
                return false;
            } else {
                var qotd_token_count = $cookies.get('pls_user_qotd_tokens');
                if (typeof(qotd_token_count) == 'undefined') {
                    return 3;
                } else {
                    return qotd_token_count;
                }
            }
        };

        $scope.shouldShowNursingPricing = function() {
            if ($scope.user && $scope.user.user_id && !$scope.user.is_trial) {
                return false;
            } else {
                return true;
            }
        };

        $scope.getDetails = function() {
            // Fetch current question and all associated data
            QuestionOfTheDayResource.get({date: $scope.day ? $scope.day : $scope.today}, function (data) {
                if (!data.question_of_the_day_id) {
                    $scope.qotd = {};
                    $scope.noQuestionForDateProvided = true;
                }
                $scope.qotd = data;
                $scope.primaryCard = $filter('filter')($scope.qotd.picmonics, {is_primary: '1'});
                $scope.hasLearned = ($scope.primaryCard && $scope.primaryCard[0] && $scope.primaryCard[0].cards && $scope.primaryCard[0].cards.learned) ? true : false;

                if ($location.search().inf_custom_CustomData1) {
                    angular.forEach($scope.qotd.question_of_the_day_answers, function (answer, key) {
                        angular.forEach(angular.fromJson($location.search().inf_custom_CustomData1), function (value, key2) {
                            if (answer.answer_id == value) {
                                $scope.selectAnswer(answer);
                            }
                        });
                    });
                }
                $scope.loading = false;
                $scope.$emit('endInteraction');
                if ($location.search().answers) {
                    $scope.showResults();
                }

                // Compare dates to determine whether webinar redirect link is pointed to recorded youtube video or GotoMeeting
                var navigatedDate = $filter('date')($scope.day, 'yyyy-MM-dd');
                $scope.webinarRedirect = (moment($scope.today) > moment($filter('date')($scope.qotd.question.webinar_date, 'yyyy-MM-dd'))) ? 'youtube' : 'gotomeeting';
            });

            // Fetch a list of all questions that have been published
            QuestionOfTheDayResource.query({fields: 'publish_on'}, function (results) {
                $scope.allQuestionDates = results;
                var currentQuestionDateIndex = $filter('arrayPos')($scope.allQuestionDates, $scope.day, 'publish_on');

                // Check to see if their is a previous question, and if so set $scope.previous
                if (typeof $scope.allQuestionDates[currentQuestionDateIndex - 1] !== 'undefined') {
                    $scope.previous = $scope.allQuestionDates[currentQuestionDateIndex - 1].publish_on;
                }
                // Check to see if their is a next question, and if so set $scope.next
                if (typeof $scope.allQuestionDates[currentQuestionDateIndex + 1] !== 'undefined' && ( new Date($scope.allQuestionDates[currentQuestionDateIndex + 1].publish_on) <= new Date($scope.today) )) {
                    $scope.next = $scope.allQuestionDates[currentQuestionDateIndex + 1].publish_on;
                }
            });
            if((!$scope.user || !$scope.user.user_id) && !$scope.tokens) {
                $scope.showRegisterModal();
            } else {
                $scope.hideLearnButton = false;
                $scope.highlightLearnButton = true;
            }
        };

        $scope.tokens = $scope.getTokenCount();
        $scope.showNursingPricing = $scope.shouldShowNursingPricing();
        if($scope.showNursingPricing) {
            $scope.faqs = [
                {
                    heading: 'Is Picmonic only for the NCLEX®?',
                    text: 'No way! Picmonic for Nursing RN covers information that is relevant to your entire nursing education! Whether you’re studying for your classes, getting ready to pass the NCLEX®, or just brushing up on some fundamentals, we’re here to help! <a href="https://www.picmonic.com/cardlist/nursing/courses/standard" target="_blank">You can view our topic list here to see what we cover!</a>'
                },
                {
                    heading: 'How many Picmonics are included when I buy?',
                    text: 'You get immediate access to all of Picmonic for Nursing RN! That’s over 800 Picmonics to help you pass your classes and the NCLEX®. And, even better, we’re always making more! <a href="https://www.picmonic.com/cardlist/nursing/courses/standard" target="_blank">You can view our topic list here!</a>'
                },
                {
                    heading: 'Does Picmonic guarantee I’ll pass the NCLEX®?',
                    text: 'I’m glad you asked because we do! We’re so confident that Picmonic will help you pass, we’re willing to give you your money back if you don’t. <a href="https://www.picmonic.com/pages/pass-the-nclex-guarantee/" target="_blank">Click here to learn more.</a>'
                },
                {
                    heading: 'Is Picmonic Free really free? What’s the catch?!',
                    text: 'Yes, it is 100% free. No credit card required, no shenanigans, no payment necessary – ever. Really! Picmonic Free includes 1 Picmonic Video play, 20 Multiple Choice Quiz questions, and 1 round of our Daily Quiz with Spaced Repetition every 24 hours.Just head <a href="/register" target="_blank">here</a> to create your account.'
                },
                {
                    heading: 'What is the difference between a monthly subscription and a non-monthly subscription (one time payment)?',
                    text: 'A monthly recurring subscription will be charged EVERY month until you choose to cancel it, whereas a fixed length, non-recurring subscription will only be charged one time (the entire total up front) and no future charges will occur. The monthly recurring subscription is the $11.99/month option. All other purchasing options are considered fixed length.'
                }
            ];
        }
        $scope.getDetails();

        var lead = 101;

        if ((UserService.user.user_id && UserService.user.lead == 202) || (!UserService.user.user_id && $cookies.get('pls_user_lead') == 202)) {
            lead = 202;
        }

        if (!$scope.day) {
            $scope.day = $scope.today;
        }

        $scope.learn_button_color = $cookies.get('pls_user_learn_button_color');
        if (!$scope.learn_button_color) {
            var rand_num = Math.floor((Math.random() * 2) + 1);
            if (rand_num == 1) {
                $scope.learn_button_color = 'blue';
            } else if (rand_num == 2) {
                $scope.learn_button_color = 'pink';
            }
            $cookies.put('pls_user_learn_button_color', $scope.learn_button_color);
        }

        UserService.setCookieIfNotLoggedIn('lead', 101);
        UserService.setCookieIfNotLoggedIn('role', 'Student');

        $scope.activeTile = $cookies.get('pls_user_active_tile');
        if (!$scope.activeTile) {$scope.activeTile = 1;}
        else if ($scope.activeTile == 9) {$scope.activeTile = 1;}
        else {$scope.activeTile++;}

        $cookies.put('pls_user_active_tile', $scope.activeTile);

        var date = new Date();

        $scope.consumeToken = function() {
            if($scope.tokens > 0) {
                $scope.tokens--;
                $cookies.put('pls_user_qotd_tokens', $scope.tokens);
                return true;
            } else if($scope.user && $scope.user.user_id) {
                return true;
            }

            return false;
        };

        $scope.selectAnswer = function(answer) {
            if($filter('filter')($scope.qotd.question_of_the_day_answers, {is_correct:'1'}).length == 1){
                var selected = $filter('filter')($scope.qotd.question_of_the_day_answers, {selected: 'true'})[0];
                if(selected){
                    selected.selected = false;
                }
                $scope.selectedAnswer = answer.answer_id;
                answer.selected = true;
            } else {
                answer.selected = answer.selected ? false : true;
            }
            send_ga('event','QOTD','Answer Selected');
        };

        $scope.share = function(method) {

            send_ga('event', 'QOTD', 'Shared on: '+method);

            if ((typeof atlasEvent !== 'undefined') && (method != 'facebook')) {
                atlasEvent('share', 'qotd - '+method);
            }

            switch(method) {
                case "twitter":
                    var left = ((window.outerWidth/2) - 300) + ((window.screenLeft != undefined) ? window.screenLeft : screen.left);
                    var top = ((window.outerHeight/2) - 200) + ((window.screenTop != undefined) ? window.screenTop : screen.top);
                    var url = encodeURIComponent(window.location.href + '?utm_source=twitter&utm_medium=social&utm_term=1461765820&utm_content=image_link&utm_campaign=qotd_share_tw&ref='+UserService.getReferralCodeAndSuffix());

                    var text;
                    if ($scope.form.is_correct) {
                        text = $scope.$meta.completed_og.title;
                    } else {
                        text = $scope.$meta.og.title;
                    }

                    window.open('https://twitter.com/share?url=' + url + '&text=' + text, "twitter",
                        "height=400,width=600,left=" + left + ",top=" + top + ",location=no,resizable=no,scrollbars=no,status=no,toolbar=no,titlebar=no");
                    break;
                case "facebook":
                    var fb_opts = {
                        method: 'share',
                        href: window.location.href + '?utm_source=facebook&utm_medium=social&utm_term=1461765452&utm_content=image_link&utm_campaign=qotd_share_fb&ref='+UserService.getReferralCodeAndSuffix()
                    };

                    if($scope.form.is_correct) {
                        fb_opts.title = $scope.$meta.completed_og.title;
                        fb_opts.picture = $scope.$meta.completed_og.image;
                        fb_opts.description = $scope.$meta.completed_og.description;
                    } else {
                        fb_opts.title = $scope.$meta.og.title;
                        fb_opts.picture = $scope.$meta.og.image;
                        fb_opts.description = $scope.$meta.og.description;
                    }

                    FB.ui(fb_opts, function (response) {
                        if (response && !response.error_message) {
                            if (typeof atlasEvent !== 'undefined') {
                                atlasEvent('share', 'qotd - facebook');
                            }
                        }
                    });
                    break;
            }
        };

        $scope.submitAnswer = function() {
            var self = this;

            if (!$scope.user.user_id) {
                if ($scope.consumeToken()) {
                    $scope.view_count = $cookies.get('pls_user_qotd_count');
                    if (!$scope.view_count) {
                        $scope.view_count = 0;
                    }
                    $scope.view_count++;
                    $cookies.put('pls_user_qotd_count', $scope.view_count);

                    if ($scope.tokens > 0) {
                        $scope.showResults();
                    } else {
                        $scope.learnPicmonic();
                    }
                } else {
                    $scope.showRegisterModal();
                }
            } else {
                $scope.showResults();
            }

            send_ga('event', 'QOTD', 'Answer Submitted');
        };

        $scope.$on('miniPlayerPlaying', function(event, isPlaying) {
            $scope.playerPlaying = isPlaying;
            $scope.highlightLearnButton = false;
        });

        $scope.learnPicmonic = function(picmonic) {
            $scope.highlightLearnButton = false;

            if (!UserService.user.user_id && !$cookies.get('pls_modal_nursing_program')  && !$scope.tokens) {
                $modal.open({
                    templateUrl: '/templates/modals/nursing-program.html',
                    windowClass: 'nursing-program-modal',
                    scope: $scope,
                    controller: function ($scope, $modalInstance, MarketOptionsConfig) {
                        $scope.header_copy = 'Choose your nursing program';

                        var expireDate = new Date();
                        expireDate.setDate(expireDate.getDate() + 1);

                        $cookies.put('pls_modal_nursing_program', 'true', {'expires': expireDate});

                        $scope.setProgram = function (slug) {
                            MarketOptionsConfig.setProgram(slug, false, false);
                            $scope.close();
                        };

                        $scope.close = function () {
                            $modalInstance.close();
                            $scope.showRegisterModal(picmonic);
                        };
                    }
                });
            } else if (!UserService.user.user_id && !$scope.tokens) {
                $scope.showRegisterModal(picmonic);
            } else {
                $rootScope.$broadcast('miniPlayerToggle');
                $timeout(function() { $scope.hasLearned = true; }, 2000);
            }
        };


        $scope.showResults = function () {
            var correct = true;
            // Display results
            angular.forEach($scope.qotd.question_of_the_day_answers, function(answer){
                if (($location.search().answers) && ($location.search().answers.indexOf(answer.answer_id+'|') > -1)) {
                    answer.selected = true;
                }

                if(answer.is_correct == 1 && !answer.selected || !answer.is_correct && answer.selected){
                    correct = false;
                }
            });
            $scope.form.submitted = true;
            $scope.form.is_correct = correct;
            if(correct) {
                $scope.hasLearned = true;
                $scope.highlightLearnButton = false;
            } else {
                $scope.highlightLearnButton = true;
            }
        };

        $scope.tiles = [
            {
                header: 'Struggling with pharm?',
                copy: 'Never struggle with Pharmacology again thanks to Picmonic!',
                cta: 'Click here to get started!',
                url: '/pathways/nursing/courses/standard/pharmacological-nursing-324',
                img: '/assets/img/questionoftheday/tiles/pharmacology.jpg'
            },
            {
                header: 'Hypo vs. Hyperthyroidism',
                copy: 'Do you know all the differences between hypothyroidism and hyperthyroidism?<br><br>Learn them in under 5 minutes with this fun video!',
                cta: 'Watch it now!',
                url: 'https://www.picmonic.com/vlog/hypothyroidism-vs-hyperthyroidism/',
                img: '/assets/img/questionoftheday/tiles/hypo-hyper.png'
            },
            {
                header: 'Be our friend!',
                copy: 'Picmonic is a community of students, scholars, academics, and friends! Join us and make learning easier and way more fun!',
                cta: 'Join now!',
                url: 'https://www.facebook.com/picmonicfornursing/?fref=ts',
                img: '/assets/img/questionoftheday/tiles/friend.png',
                event_type: 'facebook'
            },
            {
                header: 'Don’t miss a question!',
                copy: 'Our master nursing scholar Marlee Liberman discusses how answering our daily questions and then reviewing the content with Picmonic will improve your exam scores.',
                cta: 'Watch it now!',
                url: 'https://www.picmonic.com/vlog/picmonic-nclex-rn-question-of-the-day/',
                img: '/assets/img/questionoftheday/tiles/quiz.png'
            },
            {
                header: 'Feeling imbalanced?',
                copy: 'Learn everything you need to know about Acid Base Imbalances in under 5 minutes. This video uses Picmonic’s unforgettable images to help you learn!',
                cta: 'Watch it now!',
                url: 'https://www.picmonic.com/vlog/acid-base-imbalances-made-easy/',
                img: '/assets/img/questionoftheday/tiles/acid-base.png'
            },
            {
                header: 'Stumped by nursing questions?',
                copy: 'Check out this free blog where we discuss the three most important areas you need to focus on to pass nursing exams and the NCLEX.',
                cta: 'Yes, Show me how.',
                url: 'https://www.picmonic.com/pages/how-to-pass-nursing-exams-and-the-nclex/',
                img: '/assets/img/questionoftheday/tiles/nursing.jpg'
            },
            {
                header: 'Nurses Love Picmonic',
                copy: 'Hear from other students who’ve used Picmonic to save time and have fun while they learn!',
                cta: 'See our testimonials',
                url: 'https://www.youtube.com/watch?v=hdV_gKlBua4',
                img: '/assets/img/questionoftheday/tiles/testimonial.png',
                event_type: 'youtube'
            },
            {
                header: 'Nurses Love Picmonic',
                copy: 'Hear from other students who’ve used Picmonic to save time and have fun while they learn!',
                cta: 'See our testimonials',
                url: 'https://www.youtube.com/watch?v=hdV_gKlBua4',
                img: '/assets/img/questionoftheday/tiles/testimonial.png',
                event_type: 'youtube'
            },
            {
                header: '#stressed to #blessed',
                copy: 'Want to ditch the test-taking anxiety monster in nursing school? Watch Nurse Marlee’s video or read the blog with her tips!',
                cta: 'Take me there!',
                url: 'https://www.picmonic.com/vlog/nursing-test-taking-anxiety-with-nurse-marlee/',
                img: '/assets/img/questionoftheday/tiles/stressed.png'
            }
        ];

    }]);
