angular.module('picmonicpublic')
    .controller("PasswordResetController", ['$scope', 'User', function($scope, User){
        $scope.user = {};
        $scope.$emit('endInteraction');

        $scope.submit = function(){
            if(!$scope.loading) {
                $scope.loading = true;
                $scope.error = null;
                $scope.user.resource = 'resetpassword';

                User.save($scope.user, function (data) {
                    $scope.loading = false;
                    $scope.message = data.message;
                }, function (error) {
                    $scope.loading = false;
                    $scope.error = error.data.message;
                });
            }
        };
    }]);
