angular.module('picmonic-common')
    .factory("MetaService", ['$timeout', '$rootScope', '$state', '$location', function($timeout, $rootScope, $state, $location) {
        var defaults = {
            replacements: {},
            config: {},
            overrides: {}
        };

        var MetaService = {
            setConfig: function(config) {
                this.config = config;
            },
            replace: function(tag, value, doNotRun) {
                this.replacements[tag] = value;
                if(!doNotRun) {
                    MetaService.run();
                }
            },
            getRoute: function(name) {
                return this.config.hasOwnProperty(name) ? angular.copy(this.config[name]) : {};
            },
            getReplacements: function() {
                return this.replacements;
            },
            run: function() {
                var url = $location.url();

                // Get default meta
                var meta = MetaService.getRoute('home');

                // Get route meta
                for (var route_name in this.config) {
                    if(this.config.hasOwnProperty(route_name) && route_name.length) {
                        var route = this.config[route_name];
                        var meta_regex = new RegExp(route.match.replace(/\//g, '\\/'), 'i');
                        if (url.match(meta_regex)) {
                            // Assign route meta
                            meta.meta.description = false;
                            meta = angular.merge(meta, route);
                        }
                    }
                }

                // Combine with state meta
                var state_meta = $state.$current.resolve ? MetaService.prepMetaVariable($state.$current.resolve.$meta) : {};
                var meta_replacements = MetaService.getReplacements();
                meta = angular.merge(meta, state_meta);
                meta = angular.merge(meta, this.overrides);

                // Replace the MetaService replacements
                if(meta_replacements) {
                    meta = MetaService.parseMetaVar(meta, meta_replacements);
                }

                // If the state meta provided a list of replacements, replace them
                if(meta.replacements) {
                    meta = MetaService.parseMetaVar(meta, meta.replacements);
                }

                meta = MetaService.removeUnresolvedReplacements(meta);

                $timeout(function() {
                    $rootScope.$meta = meta;
                });
            },
            parseMetaVar: function(variable, replacements) {
                var cloned = angular.copy(variable);
                if(typeof(cloned) == 'object') {
                    for(var x in cloned) {
                        if(x != 'replacements' && x != 'run' && cloned.hasOwnProperty(x)) {
                            cloned[x] = MetaService.parseMetaVar(cloned[x], replacements);
                        }
                    }
                } else if(typeof(cloned) == 'string') {
                    for(var y in replacements) {
                        if(replacements.hasOwnProperty(y)) {
                            cloned = cloned.replace(new RegExp(y, 'ig'), replacements[y]);
                        }
                    }
                }
                return cloned;
            },
            removeUnresolvedReplacements: function(meta) {
                var cloned = angular.copy(meta);
                if(typeof(cloned) == 'object') {
                    for(var x in cloned) {
                        if(x != 'replacements' && cloned.hasOwnProperty(x)) {
                            cloned[x] = MetaService.removeUnresolvedReplacements(cloned[x]);
                        }
                    }
                } else if(typeof(cloned) == 'string') {
                    cloned = cloned.replace(new RegExp('%([^%]+)%','g'), '...');
                }
                return cloned;
            },
            prepMetaVariable: function(meta) {
                return angular.isFunction(meta) ? meta() : meta;
            },
            reset: function() {
                angular.merge(this, defaults);
                this.replacements = {};
                this.overrides = {};
            },
            override: function(param, value) {
                if(typeof(this.overrides[param]) == 'object' && typeof(value) == 'object') {
                    Object.assign(this.overrides[param], value);
                } else {
                    this.overrides[param] = value;
                }
            }
        };

        MetaService.reset();
        return MetaService;
    }]);
