/**
 * send to ga
 *
 * this function will send a notification to google analytics if google analytics is defined
 * else it will do nothing
 *
 * @author damir djikic
 * @param action
 * @param label
 * @param value
 * @param implicitount
 * @param event
 */
function send_ga(action, label, value, implicitount, event) {

    if (typeof ga !== 'undefined') {

        event = typeof event !== 'undefined' ? event : 'send';
        implicitount = typeof implicitount !== 'undefined' ? implicitount : value;

        ga(event, action, label, value, implicitount);

        console.log('GA '+event+':'+action+':'+ label+':'+value+':'+implicitount);
        return true;
    }
    return false;
}

function checkRightClick(event) {
    if (!(event.target && event.target.classList && typeof event.target.classList === "object" && event.target.classList.contains("right-clickable"))) {
        event.preventDefault();
        return false;
    }
}