angular.module('picmonic-common')
    .factory('GroupBuyService', ['$interval', '$cookies', 'MarketOptionsConfig', 'UserService', 'User', 'GroupBuyResource', 'PackageOptionsResource', '$rootScope', '$filter', 'SplitTestService', function($interval, $cookies, MarketOptionsConfig, UserService, User, GroupBuyResource, PackageOptionsResource, $rootScope, $filter, SplitTestService){
        return {
            referral_url: '',
            group_buy: {},
            init: function () {
                var self = this;
                self.group_buy = User.getv3({resource:'group_buy'}, function (data) {
                    var pathArray = location.href.split( '/' );
                    var protocol = pathArray[0];
                    var host = pathArray[2];
                    var url = protocol + '//' + host;

                    self.referral_url = url + '/gb/' + data.slug;
                    $interval.cancel(self.interval);
                    self.interval = $interval(function(){
                        self.group_buy.time_left--;
                    }, 1000, self.group_buy.time_left);
                });
            },
            getBySlug: function (slug) {
                var self = this;
                self.group_buy = GroupBuyResource.get({slug:slug}, function(){
                    $interval.cancel(self.interval);
                    self.interval = $interval(function(){
                        self.group_buy.time_left--;
                    }, 1000, self.group_buy.time_left);
                });
                return self.group_buy.$promise;
            },
            launch: function(slug, keepCookie) {
                var self = this;

                self.plans = PackageOptionsResource.query({market_id: MarketOptionsConfig.getMarketBySlug(slug).id, group_id: 1, is_group_buy: 1}, function(data){
                    var selectedPlan = data[0];

                    angular.forEach(data, function(value) {
                        if (value.price < selectedPlan.price) {
                            selectedPlan = value;
                        }
                    });

                    if (!keepCookie) {
                        $cookies.remove('pls_user_group_buy');
                    }

                    window.location = '/store/'+selectedPlan.slug;
                });
            },
            shareOnFacebook: function() {
                var self = this,
                    url = self.referral_url,
                    splittest;

                if (self.group_buy.goal > self.group_buy.users.length) {
                    splittest = SplitTestService.split('group_buy_pre_fb', ['a', 'b']);
                    url += splittest === 'a' ? '?src=facebook-share-pre-a-v4' : '?src=facebook-share-pre-b-v4';
                } else {
                    splittest = SplitTestService.split('group_buy_post_fb', ['a', 'b']);
                    url += splittest === 'a' ? '?src=facebook-share-post-a-v4' : '?src=facebook-share-post-b-v4';
                }

                FB.ui({
                    method: 'feed',
                    link: url
                }, function(response){
                    if (response && !response.error_message) {
                        if (typeof atlasEvent !== 'undefined') {
                            atlasEvent('share', 'Group Buy - facebook');
                        }
                    }
                });
            }
        };
    }]);
