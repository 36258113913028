angular.module('picmonicpublic')
    .controller("RegisterStep3Controller", ['$scope', '$state', '$timeout', '$location', '$filter', 'UserService', 'Schools', 'UserAgentMatch', '$cookies', 'SplitTestService', 'OnboardingService', function($scope, $state, $timeout, $location, $filter, UserService, Schools, UserAgentMatch, $cookies, SplitTestService, OnboardingService){
        var urlparams = $location.search();
        $scope.mobileDeviceDetected = UserAgentMatch.onMobileDevice();
        OnboardingService.check();
        $scope.OnboardingService = OnboardingService;
        OnboardingService.progress = 67;

        window.dataLayer = window.dataLayer || [];
        $scope.$emit('endInteraction');

        //Set the mode (register or login) so interface knows how to display copy
        $scope.mode = $location.path().indexOf('register')>-1?'register':'login';
        $scope.user = UserService.user;


        if($cookies.get('pls_user_registrationUserJobTitle')) {
            $scope.user.title = $cookies.get('pls_user_registrationUserJobTitle');
            $cookies.remove('pls_user_registrationUserJobTitle');
        }

        //Create list of the next ten years for select field
        $scope.years = [];
        var start_year = new Date().getFullYear();
        for(var a = 0; a <10; a++){
            $scope.years.push(start_year+a);
        }

        $scope.months = {
            1: 'January',
            2: 'February',
            3: 'March',
            4: 'April',
            5: 'May',
            6: 'June',
            7: 'July',
            8: 'August',
            9: 'September',
            10: 'October',
            11: 'November',
            12: 'December'
        };

        //Submit user object to user endpoint (update user)
        $scope.submit = function(){
            if($scope.step3.$invalid){
                $scope.error = 'Please complete all required fields';
                return;
            }else{
                $scope.error = '';
            }

            if(!$scope.loading) {
                $scope.loading = true;
                $scope.error = null;

                if(urlparams.track_id) {
                    $scope.user.current_track_id = urlparams.track_id;
                    $scope.user.playlist_mode = 'track';
                }

                $scope.user.$update(function (data) {
                    if(typeof(atlasEvent) !== 'undefined') {
                        atlasEvent('click', 'finish step 3');
                        atlasEvent('click', 'register finished step 3', 'new_public_onboarding');
                    }
 
                    if(urlparams.redirect){
                        if (urlparams.redirect.indexOf('/') === 0) {
                            urlparams.redirect = urlparams.redirect.substring(1);
                        }
                        if (urlparams.redirect.indexOf('%2F') === 0) {
                            urlparams.redirect = urlparams.redirect.substring(3);
                        }
                        top.location = '/'+urlparams.redirect;
                    } else{
                        if($scope.mode == 'register'){
                            //sends register submit event to datalayer
                            if ($scope.user.school) {
                                dataLayer.push({
                                    'event': 'user_register',
                                    'eventCategory' : 'signupSchoolDetail',
                                    'eventLabel' : $scope.user.school.name + '_' + $scope.user.graduation_year
                                });
                            }

                            if (OnboardingService.onboarding) {
                                top.location = '/onboarding/step4';
                                return;
                            }

                            // If user just finished registering on mobile device redirect them to mobile intercept page
                            var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                            var isMobileAndroidApp = ((userAgent.indexOf('Crosswalk') > -1) || (userAgent.indexOf('CriOS') > -1));

                            if(!isMobileAndroidApp && UserAgentMatch.onMobileDevice()) {
                                top.location = '/login/mobile-app';
                                return;
                            }

                            // If newly registered user is a Med or Nursing student redirect to pathways
                            if ((data.role == 'Professor') || (data.role == 'Administrator')) {
                                top.location = '/educator';
                            } else if ($scope.user.market.register_redirect) {
                               top.location = $scope.user.market.register_redirect;
                            } else {
                                top.location = '/find-picmonic';
                            }
                        } else {
                            top.location = '/find-picmonic';
                        }
                    }
                }, function (error) {
                    $scope.error = error.data.message;
                    $scope.loading = false;
                });
            }
        };
    }]);
