angular.module('picmonic-common', [
    'ngResource',
    'ngSanitize',
    'ngCookies',
    'ngAria',
    'ui.router',
    'cfp.hotkeys',
    'ui.bootstrap',
    'youtube-embed',
    'ng.deviceDetector',
])
    .run(['$rootScope', '$timeout', 'UserService', 'MetaService', 'IntercomService', '$http', '$state', '$transitions', function($rootScope, $timeout, UserService, MetaService, IntercomService, $http, $state, $transitions){
        // Register ucwords
        String.prototype.ucwords = function() {
            var str = this.toLowerCase();
            return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
                function ($1){
                    return $1.toUpperCase();
                }
            );
        };

        // Add PLS settings to root scope
        for(var x in window.pls_config) {
            if(window.pls_config.hasOwnProperty(x)) {
                $rootScope[x] = window.pls_config[x];
            }
        }

        // Prepare an HTML class array
        $rootScope.htmlClasses = [];

        // Register meta config
        MetaService.setConfig(window.pls_config.meta);
        IntercomService.setUserInfo(UserService.user);

        $rootScope.interacting = true;
        $transitions.onStart({}, function(transition){
            console.info('Beginning interaction');
            $rootScope.interacting = true;

            transition.promise.finally(function(){
                MetaService.run();

                document.body.scrollTop = document.documentElement.scrollTop = 0;


                if (window.pls_analytics_hard_load) {
                    window.pls_analytics_hard_load = false;
                    window.previous_url = document.location.href;
                } else {
                    window.current_url = window.location.protocol+'//'+window.location.host+$state.href(transition.to());
                    $http({
                        method: 'POST',
                        url: '/apiv2/analytics',
                        data: 'action=pageview&source=angular&url=' + window.current_url + '&referrer=' + window.previous_url,
                        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                    });
                    console.info('Action: pageview | URL: ' + window.current_url);
                    window.previous_url = window.current_url;
                }

                IntercomService.trackPageView();
                send_ga('pageview', $state.href(transition.to()));
            });
            
            
        });

        // By default, stop interactions 10 seconds after they begin (to prevent FullStory issues)
        var interactionTimeout = false;
        $rootScope.$on('$viewContentLoaded', function() {
            interactionTimeout = window.setTimeout(function() {
                if($rootScope.interacting) {
                    console.info('Ending interaction via timeout');
                    $rootScope.interacting = false;
                    if(typeof(newrelic) !== 'undefined') {
                        newrelic.interaction().end();
                    }
                }
            }, 10000);
        });

        // Content ready -- tell NewRelic to stahp
        $rootScope.$on('endInteraction', function() {
            if($rootScope.interacting) {
                console.info('Ending interaction');
                $rootScope.interacting = false;
                if(typeof(newrelic) !== 'undefined') {
                    newrelic.interaction().end();
                }
            }
        });
    }])
    .config(['$urlRouterProvider', '$locationProvider', function($urlRouterProvider, $locationProvider){
        $urlRouterProvider.rule(function($injector, $location) {
            var path = $location.path();
            var urlparams = $location.search();
            var hasTrailingSlash = path[path.length-1] === '/' && path !== '/';

            if(hasTrailingSlash) {
                var addparams = '';
                if (Object.keys(urlparams).length) {
                    addparams = '?' + $.param(urlparams);
                }

                //if last charcter is a slash, return the same url without the slash
                return path.substr(0, path.length - 1) + addparams;
            }
        });

        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
    }])
    .config(['$httpProvider', function ($httpProvider) {
        // Initialize get if not there
        if (!$httpProvider.defaults.headers.get) {
            $httpProvider.defaults.headers.get = {};
        }

        // Enables Request.IsAjaxRequest() in ASP.NET MVC
        $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';

        // Disable IE ajax request caching
        $httpProvider.defaults.headers.get['If-Modified-Since'] = '0';

        $httpProvider.interceptors.push(function($q, $rootScope) {
            return {
                'request': function(config){
                    if(config.url.match(/^\/templates/)){
                        config.url += (config.url.indexOf('?') > -1 ? '&':'?') + 'asset_versions=' + window.pls_config.asset_versions;
                    }
                    return config;
                },
                'response': function(response) {
                    var assetVersionsHeader = response.headers('Asset-Versions');

                    if (assetVersionsHeader && window.pls_config.asset_versions && window.pls_config.asset_versions !== assetVersionsHeader) {
                        $rootScope.refresh = true;
                    }

                    return response;
                },

                'responseError': function(response) {
                    var status = response.status;
                    if (status == 401) {
                        var redirect = location.pathname;
                        window.onbeforeunload = null;
                        window.location = "/register?status=401&redirect=" + encodeURIComponent(redirect);
                        return $q.reject(response);
                    }
                    // otherwise
                    return $q.reject(response);
                }
            };
        });
    }]);
