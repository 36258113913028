angular.module('picmonicpublic')
    .directive("pricingBanner", [function(){
        return {
            restrict: 'E',
            templateUrl: '/templates/public/directives/pricing-banner.html',
            replace: true,
            scope: {
                slug: '=',
                fixed: '='
            },
            controller: ['$scope', '$interval', 'MarketOptionsConfig', 'ReferralDataService', "DateService", function($scope, $interval, MarketOptionsConfig, ReferralDataService, DateService){
                $scope.market = MarketOptionsConfig.getMarketBySlug($scope.slug);
                $scope.days = "00";
                $scope.hours = "00";
                $scope.minutes = "00";
                $scope.ReferralDataService = ReferralDataService;

                $scope.calcTimeRemaining = function(){
                    $scope.expiration = DateService.dateTimeStringToDate("2017-02-01 07:59:59");
                    $scope.now = new Date();

                    if ($scope.expiration.getTime() > $scope.now.getTime()) {
                        var diff = Math.round(($scope.expiration.getTime() - $scope.now.getTime()) / 1000);

                        $scope.days = Math.round(diff / (60 * 60 * 24));
                        if ($scope.days < 10) {
                            $scope.days = '0' + $scope.days;
                        }

                        $scope.hours = Math.round(diff / (60 * 60)) % 24;
                        if ($scope.hours < 10) {
                            $scope.hours = '0' + $scope.hours;
                        }

                        $scope.minutes = Math.round(diff / (60)) % 60;
                        if ($scope.minutes < 10) {
                            $scope.minutes = '0' + $scope.minutes;
                        }
                    }
                };

                $scope.calcTimeRemaining();

                var interval = $interval($scope.calcTimeRemaining, 1000 * 60);
                $scope.$on("$destroy", function() {
                   $interval.cancel(interval);
                });
            }]
        };
    }]);