angular.module('picmonicpublic')
    .controller('HomePageController', ['$rootScope', '$scope', '$compile', '$state', '$stateParams', '$modal', '$anchorScroll', 'MarketOptionsConfig', 'UserService', 'MarketSelectorModalService', 'SplitTestService', 'splitTest', '$location', 'groupBuy', 'bundleBuy', 'GroupBuyService', 'BundleBuyService', function ($rootScope, $scope, $compile, $state, $stateParams, $modal, $anchorScroll, MarketOptionsConfig, UserService, MarketSelectorModalService, SplitTestService, splitTest, $location, groupBuy, bundleBuy, GroupBuyService, BundleBuyService) {
        $location.search('noredirect', null);
        $scope.base_asset_url = $rootScope.base_asset_url;
        $anchorScroll.yOffset = 130;
        $scope.$anchorScroll = $anchorScroll;
        $scope.loading = true;
        $scope.error = false;
        var market_data = MarketOptionsConfig.getMarketBySlug($stateParams.market);
        $scope.market_data = market_data;
        $scope.market = market_data.slug;
        $scope.$stateParams = $stateParams;
        $scope.UserService = UserService;
        $scope.splitTest = splitTest;
        $scope.fuel_env = window.pls_config.fuel_env;
        $scope.user_count = window.pls_config.user_count;


        if (groupBuy) {
            $scope.market = $scope.slug = groupBuy;

            if (GroupBuyService.group_buy.time_left <= 0) {
                $stateParams.is_group_buy = 0;
            }
        }

        if (bundleBuy) {
            $scope.market = $scope.slug = bundleBuy;

            if (BundleBuyService.bundle_buy.time_left <= 0) {
                $stateParams.is_bundle_buy = 0;
            }
        }

        if (typeof atlasEvent !== 'undefined') {
            atlasEvent('click', 'Home Page', 'new_user', 'start', 60 * 60 * 2, true);
            atlasEvent('click', 'Home Page', 'user_flow_7_2017', 'start', 60 * 60 * 2, true);
            //atlasEvent('click', 'Home Page', 'new_public_onboarding', 'start', 60 * 60 * 2, true);
        }

        $scope.showMarketSelectorModal = function(market) {
            if (UserService.user.user_id) {
                window.location = '/home?browseOpen';
                return;
            }
            var splittest = SplitTestService.split('early_registration', ['market_selector', 'registration']);
            if (splittest == 'registration') {
                $scope.showRegisterModal();
            } else {
                currentState = $state.current.name;
                if (currentState == 'public.osmosis' || currentState == 'public.osmosis.card' || currentState == 'public.memorang' || currentState == 'public.memorang.card') {
                    $scope.showRegisterModal();
                } else {
                    MarketSelectorModalService.showMarketSelectorModal(market);
                }
            }
        };

        $scope.scrollToFeature = function(num) {
            angular.element('#feature-tile-wrapper').animate({
                scrollLeft: angular.element('#feature-tile-wrapper .home-learn-tile')[num].offsetLeft - 15
            }, 500);
        };

        $scope.scrollToTestimonial = function(num) {
            angular.element('#testimonial-tile-wrapper').animate({
                scrollLeft: angular.element('#testimonial-tile-wrapper .tweet-box')[num].offsetLeft - 15
            }, 500);
        };

        $scope.goToURL = function(url) {
            if (typeof atlasEvent !== 'undefined') {
                atlasEvent('click', 'learn_more');
            }

            document.location.href = url;
        };

        $scope.showRegisterModal = function(login) {
            $modal.open({
                templateUrl: '/templates/modals/register-modal.html',
                windowClass: 'registration-cta-modal full-screen-modal',
                controller: 'RegisterModalController',
                resolve: {
                    card: function () {
                        return $scope.card ? $scope.card : undefined;
                    },
                    tab: function() {
                        return login ? 'login' : 'register';
                    },
                    redirect: function() {
                        return $scope.card ? '/learn/' + $scope.card.slug : '/find-picmonic' + ($scope.market_data?'?market='+$scope.market_data.slug:'');
                    }
                }
            });
        };
    }]);
