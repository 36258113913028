angular.module('picmonic-common')
    .factory('OnboardingService', ['$state', '$cookies', 'UserService', function($state, $cookies, UserService) {
        return {
            onboarding: false,
            show_both: false,
            progress: 0,
            num_attempts: 0,
            num_correct: 0,
            check: function() {
                if (!UserService.user.user_id && $cookies.get('pls_user_split_onboarding') == 'onboarding') {
                    var self = this;

                    var onboardingRoutes = [
                        'app.onboarding',
                        'public.auth.register',
                        'public.auth.register.step2',
                        'public.auth.register.step3',
                        'app.quiz',
                        'app.learn',
                    ];

                    if (onboardingRoutes.indexOf($state.current.name) !== -1) {
                        self.onboarding = true;
                    }
                }
            }
        }
    }]);