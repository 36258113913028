angular.module('picmonicpublic')
    .controller('WebinarsController', ['$scope', '$controller', '$filter', 'UserService', 'WebinarResource', '$compile', '$modal', '$state', '$location', 'IntercomService', '$stateParams', 'MarketOptionsConfig', function ($scope, $controller, $filter, UserService, WebinarResource, $compile, $modal, $state, $location, IntercomService, $stateParams, MarketOptionsConfig) {
        $controller('LoginController', {$scope: $scope});
        $controller('RegisterStep1Controller', {$scope: $scope});
        $scope.webinar_id = $stateParams.webinar_id;
        $scope.market = MarketOptionsConfig.getMarketBySlug($stateParams.market);
        $scope.current_url = location.pathname;

        moment.tz.add('America/Los_Angeles|PST PDT|80 70|01010101010101010101010|1BQW0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|15e6');

        $scope.UserService = UserService;
        if (UserService.user.user_id) {
            $scope.tab = 'loggedin';
        } else {
            $scope.tab = 'register';
        }

        $scope.webinar = WebinarResource.get({webinar_id: $scope.webinar_id}, function(data) {
            var start = moment(data.times[0].startTime).tz('America/Los_Angeles');
            var end = moment(data.times[0].endTime).tz('America/Los_Angeles');
            $scope.start = start.format('llll');
            $scope.end = moment(data.times[0].endTime).tz('America/Los_Angeles').format('LT z');

            $scope.isPast = false;
            if (moment().tz('America/Los_Angeles').diff(end) > 0) {
                $scope.isPast = true;
            }
        });

        $scope.registerForWebinar = function() {
            $scope.loading = true;

            if ($scope.isPast) {
                $scope.loading = false;
                $scope.tab = 'success-registeronly';
            } else {
                WebinarResource.save({webinar_id: $scope.webinar_id, resource_type: 'register'}, function () {
                    $scope.loading = false;
                    $scope.tab = 'success';
                }, function (error) {
                    $scope.loading = false;
                    $scope.error = error.data.message.replace('/login', '/login?redirect=' + $scope.current_url);
                });
            }
        };

        $scope.submitModalRegistration = function(){
            $scope.user.lead = $scope.market.id;
            $scope.user.accessToken = null;
            $scope.loading = true;
            $scope.error = '';

            // Call RegisterStep1Controller.register
            $scope.register(false).then(function(data){
                $scope.registerForWebinar();
            }, function(error){
                $scope.error = error.data.message.replace('/login', '/login?redirect='+$scope.current_url);
                $scope.loading = false;
            });
        };

        $scope.submitModalSocialRegistration = function() {
            $scope.loading = true;
            $scope.loadingMode = 'registration-social';
            $scope.error = '';

            UserService.user.lead = $scope.market.id;
            UserService.user.role = 'Student';
            UserService.user.registration_source_page = 'webinars';

            // Call RegisterStep1Controller.register
            $scope.socialRegister(false).then(function(data){
                $scope.registerForWebinar();
            }, function(error){
                $scope.error = error.data.message.replace('/login', '/login?redirect='+$scope.current_url);
                $scope.loading = false;
            });
        };

        $scope.submitModalLogin = function(){
            $scope.loading = true;
            $scope.loadingMode = 'login-picmonic';
            $scope.error = '';

            $scope.appLogin(false).then(function(){
                $scope.registerForWebinar();
            }, function(error){
                $scope.error = error.data.message.replace('/login', '/login?redirect='+$scope.current_url);
                $scope.loading = false;
            });
        };

        $scope.submitModalSocialLogin = function() {
            $scope.loading = true;
            $scope.loadingMode = 'login-social';
            $scope.error = '';

            $scope.socialLogin(false).then(function(){
                $scope.registerForWebinar();
            }, function(error){
                $scope.error = error.data.message.replace('/login', '/login?redirect='+$scope.current_url);
                $scope.loading = false;
            });
        };

    }]);