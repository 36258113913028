angular.module('picmonic-common')
    .directive("clipboard", [function(){
        return {
            restrict: 'A',
            scope: {
                onCopied: '&clipboardCopied',
                onError: '&?clipboardOnError',
                client: '=?clipboard',
                value: '=clipboardModel',
                text: '@clipboardText'
            },
            link: function(scope, element, attrs){
                new Clipboard(element[0]);

                var btn = element[0];
                var _completeHnd, client;

                if (angular.isFunction(Clipboard)) {
                    client = new Clipboard(btn);
                }

                scope.$watch('value', function(v) {
                    if (v === undefined) {
                        return;
                    }
                    element.attr('data-clipboard-text', v);
                });

                scope.$watch('text', function(v) {
                    element.attr('data-clipboard-text', v);
                });

                client.on('success', _completeHnd = function(e) {
                    scope.$apply(function() {
                      scope.onCopied({$event: e});
                    });
                });

                client.on('error', function(e) {
                    if (scope.onError) {
                        scope.$apply(function() {
                            scope.onError({$event: e});
                        });
                    }
                });
            }
        };
    }]);