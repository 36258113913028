angular.module('picmonic-common')
    .factory('RaygunService', ['$cookies', function($cookies){
        return {
            trackPageView: function(path) {
                if (typeof rg4js !== 'undefined' && typeof Raygun !== 'undefined') {
                    Raygun.trackEvent('pageView', {
                        path: path
                    });
                }
            },
            sendException: function(exception) {
                if (typeof rg4js !== 'undefined' && typeof Raygun !== 'undefined') {
                    // Send the exception
                    if (__rg4jsEnableCrashReporting) {
                        Raygun.send(exception);
                    }
                }
            },
            setRayGunUserInfo: function(user) {
                if (typeof rg4js !== 'undefined') {
                    // Use existing cookies
                    if ($cookies.get('rguserid')) {
                        rg4js('setUser', {
                            identifier: decodeURIComponent($cookies.get('rguserid')),
                            isAnonymous: false,
                            email: decodeURIComponent($cookies.get('rgemail')),
                            firstName: decodeURIComponent($cookies.get('rgfirstname')),
                            fullName: decodeURIComponent($cookies.get('rgfullname')).replace('+', ' ')
                        });
                    } else {
                        // Set cookies using logged in users details
                        if (user.user_id) {
                            var expiration = new Date();
                            expiration.setDate(expiration.getDate() + 30);
                            var options = {
                                'path': '/',
                                'expires': expiration
                            };
                            $cookies.put('rguserid', user.user_id, options);
                            $cookies.put('rgfirstname', user.first_name, options);
                            $cookies.put('rgfullname', user.first_name + ' ' + user.last_name, options);
                            $cookies.put('rgemail', user.email, options);
                            $cookies.put('rgisanonymous', 'false', options);
                            $cookies.remove('rguuidvalue');
                            $cookies.put('rguuid', 'false', options);

                            rg4js('setUser', {
                                identifier: user.user_id,
                                isAnonymous: false,
                                email: user.email,
                                firstName: user.first_name,
                                fullName: user.first_name + ' ' + user.last_name
                            });
                        } else {
                        // Set user as logged out
                            rg4js('setUser', { identifier: '', isAnonymous: true, email: '', firstName: '', fullName: ''});
                        }
                    }
                }
            }
        }
    }]);