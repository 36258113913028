angular.module('picmonicpublic')
    .controller('NavigationController', ['$scope', 'MenuService', '$location', 'MarketSelectorModalService', 'SplitTestService', '$modal', 'UserService', '$cookies', '$state', 'OnboardingService', 'MarketOptionsConfig', function($scope, MenuService, $location, MarketSelectorModalService, SplitTestService, $modal, UserService, $cookies, $state, OnboardingService, MarketOptionsConfig) {
        $scope.navigation = MenuService.navigation;
        $scope.$location = $location;
        $scope.MarketSelectorModalService = MarketSelectorModalService;
        $scope.UserService = UserService;
        $scope.OnboardingService = OnboardingService;
        $scope.baseURL = 'root';
        $scope.baseURLbutton = 'TRY FREE';
        $scope.splittest = SplitTestService.split('new_home_page', ['old', 'new']);
        if ($scope.splittest === 'new') {
            $scope.baseURLbutton = 'TRY IT FREE';
        }

        $scope.firePricingAtlasEvent = function(url) {
            if (url.indexOf('pricing/') !== -1) {
                if(typeof atlasEvent !== 'undefined') {
                    atlasEvent('click', 'pricing top bar nav');
                }
            }
        };

        $scope.showMarketSelectorModal = function(market) {
            $modal.open({
                templateUrl: '/templates/modals/register-modal.html',
                windowClass: 'registration-cta-modal full-screen-modal',
                controller: 'RegisterModalController',
                resolve: {
                    card: function() {
                        return null;
                    },
                    market_id: function() {
                        return null;
                    },
                    copy: function() {
                        return null;
                    },
                    redirect: function() {
                        return null;
                    },
                    tab: function() {
                        return 'register';
                    }
                }
            });
        };

        $scope.howPicmonicWorks = function() {
            $location.path("/page/how-picmonic-works");
        };

        // Temporarily hijack login link
        angular.element(document).on('click', 'a.nav-menu-item[href="/login"]', function(e) {
            e.preventDefault();
            e.stopPropagation();

            var lead = $cookies.get('pls_user_lead');
            $modal.open({
                templateUrl: '/templates/modals/register-modal.html',
                windowClass: 'registration-cta-modal full-screen-modal',
                controller: 'RegisterModalController',
                resolve: {
                    card: function() {
                        return null;
                    },
                    market_id: function() {
                        return lead;
                    },
                    copy: function() {
                        return null;
                    },
                    redirect: function() {
                        return null;
                    },
                    tab: function() {
                        return 'login';
                    }
                }
            });
        });

        $scope.goToURL = function(url) {
            if (UserService.user.user_id) {
                document.location.href = url;
                return;
            }

            var queryString = url.substring(url.indexOf('?') + 1);
            var slug = 'medicine';
            if (queryString.indexOf('&') > -1) {
                slug = queryString.substring(queryString.indexOf('market=') + 7, queryString.indexOf('&'));
            } else {
                slug = queryString.substring(queryString.indexOf('market=') + 7);
            }

            var lead,
                market = MarketOptionsConfig.getMarketBySlug(slug);

            if (market.id) {
                UserService.setMarketCookies(market.id);
                if (market.is_pseudo) {
                    lead = market.id;
                }
            }

            if (url === '/pathways/medical-terminology/courses/standard/medical-terminology-2411?market=medical-terminology') {
                url = '/find-picmonic?market=medical-terminology&pathway=medical-terminology-2411';
            }

            $modal.open({
                templateUrl: '/templates/modals/register-modal.html',
                windowClass: 'registration-cta-modal full-screen-modal',
                controller: 'RegisterModalController',
                resolve: {
                    card: function() {
                        return null;
                    },
                    market_id: function() {
                        return lead;
                    },
                    copy: function() {
                        return null;
                    },
                    redirect: function() {
                        return url;
                    },
                    tab: function() {
                        return 'register';
                    }
                }
            });
        };

    }]);