angular.module('picmonicpublic')
    .controller("RegisterStep2Controller", ['$scope', '$location', 'UserService', 'UserAgentMatch', '$filter', '$cookies', 'MarketOptionsConfig', 'SplitTestService', 'OnboardingService', '$state', function($scope, $location, UserService, UserAgentMatch, $filter, $cookies, MarketOptionsConfig, SplitTestService, OnboardingService, $state){
        MarketOptionsConfig.getMarketById(101).name = 'Nursing';

        OnboardingService.check();
        $scope.OnboardingService = OnboardingService;
        OnboardingService.progress = 67;

        $scope.MarketOptionsConfig = MarketOptionsConfig;
        var urlparams = $location.search();
        $scope.mobileDeviceDetected = UserAgentMatch.onMobileDevice();

        window.dataLayer = window.dataLayer || [];
        $scope.$emit('endInteraction');

        //Set the mode (register or login) so interface knows how to display copy
        $scope.mode = $location.path().indexOf('register')>-1?'register':'login';

        $scope.user = UserService.user;

        // LEAD COOKIE
        var lead = $cookies.get('pls_user_lead');
        if (lead && !$scope.user.lead) {
            $scope.user.lead = parseInt(lead);
        }

        if ($scope.user.lead) {
            $scope.user.selected_lead = MarketOptionsConfig.getMarketById($scope.user.lead).selected_lead;
        } else {
            $scope.user.selected_lead = "";
        }

        // ROLE COOKIE
        var role = $cookies.get('pls_user_role');
        if (role) {
            $scope.user.role = (role == "Educator" || role == 'Professor') ? "Professor" : "Student";
        }

        if ($scope.user.role == '' || !$scope.user.role) {
            $scope.user.role = "Student";
        }

        //Submit user object to user endpoint (update user)
        $scope.submit = function(){
            if($scope.step2.$invalid || !$scope.user.selected_lead){
                $scope.error = 'Please complete all required fields';
                return;
            }else{
                $scope.error = '';
            }

            if(!$scope.loading) {
                $scope.loading = true;
                $scope.error = null;

                if ($scope.user.program && MarketOptionsConfig.getMarketById($scope.user.selected_lead).use_program_market_id) {
                    $scope.user.lead = $filter('filter')(MarketOptionsConfig.programs, {value: $scope.user.program}, true)[0].market_id;
                } else {
                    $scope.user.lead = $scope.user.selected_lead;
                }

                $scope.user.$update(function (data) {
                    dataLayer.push({
                        'event': 'user_register',
                        'eventCategory' : 'signupStudenttype',
                        'eventLabel' : $scope.user.role + '_' + $scope.user.lead
                    });

                    if (typeof atlasEvent !== 'undefined') {
                        atlasEvent('click', 'register finished step 2', 'new_public_onboarding');
                    }

                    if ((urlparams.skip_school) && (urlparams.redirect)) {
                        if (urlparams.redirect.indexOf('/') === 0) {
                            urlparams.redirect = urlparams.redirect.substring(1);
                        }
                        if (urlparams.redirect.indexOf('%2F') === 0) {
                            urlparams.redirect = urlparams.redirect.substring(3);
                        }
                        top.location = $location.protocol() + '://' + $location.host() + '/' + urlparams.redirect;
                    } else {
                        if(MarketOptionsConfig.getMarketById($scope.user.lead).show_school_modal ) {
                            $location.url('/register/step3?' + $.param(urlparams));
                        } else {
                            if (typeof atlasEvent !== 'undefined') {
                                atlasEvent('click', 'register step 3 skipped', 'new_public_onboarding');
                            }

                            if (OnboardingService.onboarding) {
                                top.location = '/onboarding/step4';
                                return;
                            } else if(urlparams.redirect){
                                if (urlparams.redirect.indexOf('/') === 0) {
                                    urlparams.redirect = urlparams.redirect.substring(1);
                                }
                                if (urlparams.redirect.indexOf('%2F') === 0) {
                                    urlparams.redirect = urlparams.redirect.substring(3);
                                }
                                top.location = '/'+urlparams.redirect;
                            } else{
                                top.location = '/find-picmonic';
                            }
                        }
                    }
                }, function (error) {
                    $scope.error = error.data.message;
                    $scope.loading = false;
                });
            }
        };
    }]);
