angular.module('picmonic-common')
    .factory("User", ['$resource', function($resource){
        return $resource("/apiv2/user/:resource/:resource_id/:resource2", {resource:'@resource', resource_id:'@resource_id', resource2:'@resource2'}, {
            'getv3': { method: 'GET', url: '/api/v3/user/:resource' },
            'update': { method:'PUT', isArray:false },
            'post': { method: 'POST', isArray:false },
            'recommended': { method: 'GET', url: '/api/v3/user/recommended' },
            'last_order': {method: 'GET', url: '/api/v3/user/last-order'},
            'order_history': {method: 'GET', url: '/api/v3/user/order-history', isArray: true},
            'payment_info': {method: 'GET', url: '/api/v3/user/payment-info', isArray:false},
            'update_pathway': {method: 'PUT', url: '/api/v3/user/pathways/:pathway_id', isArray:false},
            'update_payment_info': {method: 'PUT', url: '/api/v3/user/payment-info', isArray:false},
            'billing_history': {method: 'GET', url: '/api/v3/user/billing-history', isArray: true},
            'cancel_entitlement': {method: 'POST', url: '/api/v3/user/entitlements/:users_entitlements_id/cancel'},
            'pause_entitlement': {method: 'POST', url: '/api/v3/user/entitlements/:users_entitlements_id/pause'},
            'unpause_entitlement': {method: 'POST', url: '/api/v3/user/entitlements/:users_entitlements_id/unpause'},
            'weakest': { method: 'GET', url: '/api/v3/user/weakest' },
            'managePackages': {method: 'GET', url: '/api/v3/user/manage-packages'},
            'savePackage': {method: 'POST', url: '/api/v3/user/packages'},
            'removePackage': {method: 'DELETE', url: '/api/v3/user/packages/:id', params:{id:'@id', package_id:'@package_id'}}
        });
    }]);
