angular.module('picmonic-common')
    .directive('plsProgressBar', ['$timeout', function($timeout){
        return {
            restrict: 'E',
            replace: true,
            scope: {
                percent: '@',
                animatePercent: '@',
                color: '@',
                percent2: '@',
                color2: '@',
                pathway: '=',
                track: '=',
                picmonic: '=',
                showProgress: '=',
            },
            template: (
                '<div class="pls-progress-bar ng-hide" ng-hide="!percent && !showProgress">' +
                    '<div ng-attr-title="{{percent | number:2}}% Learned" ng-class="[color ? \'pls-progress-bar--\' + color : \'pls-progress-bar--default\', percent == 0 && !showProgress ? \'invisible\' : \'\']" >' +
                        '<div class="progress" role="progressbar" aria-valuenow="{{(percent>0?(percent<100?percent:100):0)}}" aria-valuemin="0" aria-valuemax="100" ng-style="{width: (percent>0?(percent<100?percent:100):0) + \'%\'}" ng-class="{\'no-border-radius-right\': percent2,completed:(percent >= 100)}"></div>' +
                        '<div ng-show="percent2" class="progress" role="progressbar" aria-valuenow="{{(percent2>0?(percent2<100?percent2:100):0)}}" aria-valuemin="0" aria-valuemax="100" ng-style="{width: (percent2>0?(percent2<100?percent2:100):0) + \'%\'}" ng-class="[percent ? \'no-border-radius-left\':\'\', color2 ? \'bg-pls-\'+color2:\'\']"></div>' +
                    '</div>' +
                '</div>'
            ),
            controller: ['$scope', '$timeout', function($scope, $timeout) {
                if ($scope.animatePercent) {
                    $timeout(function(){
                        $scope.percent = $scope.animatePercent;

                        $scope.$watch('animatePercent', function(newValue) {
                            $scope.percent = newValue;
                        });
                    }, 500);
                }

                $scope.titleText = '';

                $scope.$watch('pathway', function(){
                    updateStats();
                });
                $scope.$watch('track', function(){
                    updateStats();
                });
                $scope.$watch('picmonic', function(){
                    updateStats();
                });

                function updateStats(){
                    if($scope.pathway) {
                        $scope.percent = ($scope.pathway.total_mastered / ($scope.pathway.total_picmonics ? $scope.pathway.total_picmonics : $scope.pathway.total )) * 100;
                    } else if($scope.track) {
                        $scope.percent = ($scope.track.total_mastered / $scope.track.total) * 100;
                    } else if($scope.picmonic) {
                        $scope.percent = ($scope.picmonic.num_mastered_facts / $scope.picmonic.num_facts) * 100;
                    }

                    if(!$scope.percent) {
                        $scope.percent = 0;
                    }

                    // If we're creating a progress bar for a pathway, track, or picmonic
                    // and it has 100% completion, display the progress bar in meekus (green)
                    if(($scope.pathway || $scope.track || $scope.picmonic) && $scope.percent == 100) {
                        $scope.color = 'meekus';
                    }
                }
            }]
        };
    }]);
