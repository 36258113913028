angular.module('picmonic-common')
    .directive("plsLoadingIcon", [function(){
        return {
            restrict: 'E',
            replace: true,
            scope: {
                size: "@"
            },
            template: '<div class="pls-loading-icon"><img ng-src="{{base_asset_url}}/assets/img/loading.svg"></div>',
            controller: ['$rootScope', '$scope', function($rootScope, $scope){
                $scope.base_asset_url = $rootScope.base_asset_url;
            }],
            link: function(scope, element, attrs) {
                if (scope.size) {
                    angular.element(element).addClass("pls-loading-icon--" + scope.size);
                }
            }
        };
    }]);