angular.module('picmonicpublic')
    .directive("osmosisHeader", [function(){
        return {
            restrict: 'E',
            templateUrl: '/templates/public/directives/osmosis-header.html',
            scope: {
                cardId: '='
            },
            controller: ['$rootScope', '$scope', '$modal', 'UserService', 'Picmonics', 'MarketSelectorModalService', function($rootScope, $scope, $modal, UserService, Picmonics, MarketSelectorModalService){
                $scope.base_asset_url = $rootScope.base_asset_url;
                $scope.card = Picmonics.get({card_id: $scope.cardId}, function(card){
                    if (UserService.user.user_id) {
                        window.location = '/learn/'+card.slug;
                    }

                    $parent.card = card;

                    $scope.cardVisible = true;
                }, function(){
                    window.location = '/osmosis/2594';
                    $scope.cardVisible = true;
                });

                $scope.showMarketSelectorModal = function(market) {
                    MarketSelectorModalService.showMarketSelectorModal(market);
                };

                $scope.showRegisterModal = function(login) {
                    $modal.open({
                        templateUrl: '/templates/modals/register-modal.html',
                        windowClass: 'learn-master-complete registration-cta-modal full-screen-modal',
                        backdropClass: 'below-nav',
                        size: 'sm',
                        scope: $scope,
                        backdrop: 'static',
                        controller: 'RegisterModalController',
                        resolve: {
                            card: function () {
                                return $scope.card ? $scope.card : undefined;
                            },
                            tab: function() {
                                return login ? 'login' : 'register';
                            },
                            redirect: function() {
                                return $scope.card ? '/learn/' + $scope.card.slug : undefined;
                            }
                        }
                    });
                };
            }]
        };
    }]);