angular.module('picmonic-common')
    .directive('splitTest', ['SplitTestService', function(SplitTestService) {
        return {
            restrict: 'A',
            link: function(scope,element,attrs){
                var split = SplitTestService.split(attrs.splitTest, 2);
                if (attrs.splitTestVersion != split) {
                    element.hide();
                }
            }
        }
    }]);