angular.module('picmonic-common')
    .directive("factCharacterImage", ['$q', function($q){
        return {
            restrict: "E",
            replace: true,
            template: '<canvas class="character-image"></canvas>',
            scope:{
                character: '=',
                size: '@'
            },
            link: function(scope, element, attrs){
                var canvas = element[0];
                var ctx = canvas.getContext('2d');
                var sizeRatio = 1;
                var sizeSuffix = '';

                scope.images = [];

                switch(scope.size){
                    case 'md':
                        sizeRatio = 0.75;
                        sizeSuffix = '_threequarter';
                    break;
                    case 'sm':
                        sizeRatio = 0.5;
                        sizeSuffix = '_half';
                    break;
                    case 'xs':
                        sizeRatio = 0.25;
                        sizeSuffix = '_quarter';
                        break;
                    default:
                        sizeRatio = 1;
                    break;
                }

                for(var x in scope.character.assets){
                    scope.images.push(
                        $q(function(resolve, reject){
                            var asset = scope.character.assets[x];
                            var img = new Image();

                            img.addEventListener('load', function(){
                                resolve({asset: asset, image: img});
                            });

                            img.addEventListener('error', function(){
                                reject({asset: asset, image: img});
                            });

                            img.src =  asset.image_url.replace('picmonic_assets','picmonic_assets' + sizeSuffix);
                        })
                    );
                }

                canvas.width = scope.character.original_width * sizeRatio;
                canvas.height = scope.character.original_height * sizeRatio;

                $q.all(scope.images).then(function(images) {
                    images.sort(function(a, b){
                        return a.asset.z < b.asset.z ? -1 : (a.asset.z > b.asset.z ? 1 : 0);
                    });

                    for(var a in images){
                        ctx.drawImage(images[a].image, parseInt(images[a].asset.x | 0) * sizeRatio, parseInt(images[a].asset.y | 0) * sizeRatio, parseInt(images[a].asset.width) * sizeRatio, parseInt(images[a].asset.height) * sizeRatio);
                    }
                });
            }
        }
    }]);