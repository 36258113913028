angular.module('picmonic-common')
    .directive("factCharacterGroup", ['deviceDetector', function(deviceDetector) {
        return {
            restrict: 'E',
            template:
                '<div class="fact-character-group" ng-class="{\'editable\': (fact.is_primary && playerService.hasAccess(\'workflow\', \'topic_character\', fact)) || (!fact.is_primary && playerService.hasAccess(\'workflow\', \'fact_character\', fact)),\'selected\':fact.selected, \'correct\':fact.is_answer, \'characters-selected\':playerService.currentFact.fact_id}">' +
                    '<fact-character z-index="zIndex" does-blink="doesBlink" start-blink="startBlink" does-fade="doesFade" prevent-zoom="fact.prevent_zoom" no-blur="noBlur" ng-repeat="character in fact.characters | filter:{deleted: \'!true\'}" size="size" zoom="zoom" fact="fact" character="character" player-service="playerService" ng-show="(playerService.mode == \'learn\' && (fact.selected || fact.learned_flag || fact.is_primary || (UserService.user.show_all_characters && !playerService.insideQuiz) || showCharacterGroup)) || (playerService.mode == \'review\' && fact.selected) || playerService.mode == \'editor\' || playerService.mode == \'mini\'"></fact-character>'+
                '</div>',
            scope: {
                fact: '=',
                playerService: '=',
                size: '=',
                zoom: '=',
                noBlur: '=',
                doesBlink: '=',
                startBlink: '=',
                doesFade: '=',
                zIndex: '=',
                showCharacterGroup: '='
            },
            controller: ['$scope', 'UserService', function($scope, UserService){
                $scope.UserService = UserService;
                if(typeof $scope.playerService.hasFullPermission == 'undefined'){
                    $scope.playerService.hasFullPermission = function(){return false;}
                }
            }]
        }
    }]);
