angular.module('picmonic-common')
    .directive("groupBuyBlock", [function(){
        return {
            restrict: 'E',
            replace: true,
            scope: {},
            templateUrl: '/templates/common/directives/group-buy-block.html',
            controller: ['$rootScope', '$scope', '$state', 'MarketOptionsConfig', 'GroupBuyService', function ($rootScope, $scope, $state, MarketOptionsConfig, GroupBuyService) {
                $scope.base_asset_url = $rootScope.base_asset_url;
                $scope.GroupBuyService = GroupBuyService;
                $scope.MarketOptionsConfig = MarketOptionsConfig;
                $scope.is_group_buy = $state.current.name == 'public.gb';
            }]
        };
    }]);