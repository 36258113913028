angular.module('picmonic-common')
    .directive("pricingTable", [function(){
        return {
            restrict: 'E',
            replace: true,
            scope: {
                slug: '@',
                isGroupBuy: '@'
            },
            controller: ['$scope', 'UserService', 'DateService', 'MarketOptionsConfig', '$filter', '$stateParams', 'PackageOptionsResource', 'GroupBuyService', 'SplitTestService', '$modal', function($scope, UserService, DateService, MarketOptionsConfig, $filter, $stateParams, PackageOptionsResource, GroupBuyService, SplitTestService, $modal){
                $scope.MarketOptionsConfig = MarketOptionsConfig;
                $scope.UserService = UserService;
                $scope.DateService = DateService;
                $scope.market = MarketOptionsConfig.getMarketBySlug($scope.slug);
                $scope.osmosis = $stateParams.osmosis;
                $scope.GroupBuyService = GroupBuyService;
                $scope.is_group_buy = $stateParams.is_group_buy;
                $scope.navigateToStore = function(slug) {
                    location.assign('/store/'+slug);
                };
                $scope.navigateToURL = function(url) {
                    location.assign('/'+url);
                };

                $scope.showRegisterModal = function() {
                    $modal.open({
                        templateUrl: '/templates/modals/register-modal.html',
                        windowClass: 'registration-cta-modal full-screen-modal',
                        controller: 'RegisterModalController',
                        resolve: {
                            card: function () {
                                return null;
                            },
                            tab: function() {
                                return 'register';
                            },
                            redirect: function() {
                                return '/find-picmonic?market='+$scope.slug;
                            }
                        }
                    });
                };

                $scope.marketPlans = PackageOptionsResource.query({market_id: MarketOptionsConfig.getMarketBySlug($scope.slug).id, group_id: 1, is_group_buy: ($scope.isGroupBuy || $stateParams.is_group_buy) ? 1 : 0});

                $scope.hasStrikePrice = false;

                $scope.marketPlans.$promise.then(function(){
                    angular.forEach($scope.marketPlans, function(plan) {
                        if (plan.strike_price) {
                            $scope.hasStrikePrice = true;
                        }

                        if ($scope.osmosis == '1') {
                            plan.slug_params = '?osmosis=1';
                        }
                    });
                });

            }],
            template: '<div class="m-t-8 m-b-4 clearfix flex-container text-center">\
    <div class="flex-grow" ng-show="!marketPlans.$resolved">\
        <pls-loading-icon size="x-small"></pls-loading-icon>\
    </div>\
    <!-- COLUMN - FREE -->\
    <div class="flex-item m-b-2 pricing-pad-lg clearfix" ng-show="marketPlans.$resolved && !is_group_buy && !UserService.user.user_id">\
        <div class="col-xs-12 pricing-box-wrapper pricing-box-wrapper--free" ng-click="UserService.user.user_id ? \'\' : showRegisterModal()">\
            <h3 class="add-top pls-black pricing-block-header pls-font-18 pls-font-cabin pls-font-weight-800 p-t-1 m-b-0">Picmonic Free</h3>\
            <div class="pls-font-14 pls-font-cabin pls-font-weight-800">&nbsp;</div>\
            <div ng-if="hasStrikePrice">\
                <h4 class="pls-font-40 pricing-plan-price m-t-0 m-b-0 hidden-xs">&nbsp;</h4>\
            </div>\
            <div>\
                <h4 class="pls-font-40 pricing-plan-price m-t-0 m-b-0">$0</h4>\
            </div>\
            <div><p class="m-t-0 m-b-1 pricing-block-expiration-date pricing-block-expiration-date--transparent hidden-xs"><em>Expires Dec 31, 2020 @ 11:59 Pacific</em></p></div>\
            <div class="p-t-0 pricing-block-sub-header hidden-xs">&nbsp;<br></div>\
            <div class="text-center">\
                <div class="add-top-less row">\
                    <div class="col-xs-12">\
                        <a ng-class="{\'pricing-block-hidden-cta hidden-xs\': UserService.user.user_id}">\
                            <button class="pls-btn pls-btn--lighten-blue pls-black pricing-plan-cta col-xs-12"><strong>Try&nbsp;it&nbsp;Free</strong></button>\
                        </a>\
                    </div>\
                </div>\
            </div>\
        </div>\
    </div>\
    <!-- END COLUMN - FREE -->\
    <!-- COLUMN - GROUP BUY -->\
    <div class="flex-item m-b-2 pricing-pad-lg clearfix" ng-show="marketPlans.$resolved && is_group_buy">\
        <div class="col-xs-12 pricing-box-wrapper" style="opacity: .2; cursor: not-allowed;">\
            <h3 class="add-top pls-black pricing-block-header pls-font-18 pls-font-cabin pls-font-weight-800 p-t-1 m-b-0">Monthly</h3>\
            <div class="pls-blue pls-font-14 pls-font-cabin pls-font-weight-800" ng-class="{\'pls-orange\':plan.best_option, \'hidden-xs\': !plan.price_description}">{{ plan.price_description ? plan.price_description : \'&nbsp;\' }}</div>\
            <div ng-if="hasStrikePrice">\
                <h4 class="pls-font-40 pricing-plan-price m-t-0 m-b-0 hidden-xs">&nbsp;</h4>\
            </div>\
            <div>\
                <h4 class="pls-font-40 m-t-0 m-b-0 pricing-plan-price">$<span>{{ marketPlans[0].unit_base_price | integer }}<sup class="cents">99</sup><sub class="unit">/mo</sub></span></h4>\
            </div>\
            <div><p class="m-t-0 m-b-1 pricing-block-expiration-date pricing-block-expiration-date--transparent hidden-xs"><em>Expires Dec 31, 2020 @ 11:59 Pacific</em></p></div>\
            <div class="p-t-0 pricing-block-sub-header hidden-xs">Not Available In a Group Buy<br></div>\
            <div class="text-center">\
                <div class="add-top-less row">\
                    <div class="col-xs-12">\
                        <a target="_self">\
                            <button class="pls-btn pls-btn--blue pls-black pricing-plan-cta col-xs-12" style="cursor: not-allowed;"><strong>BUY NOW</strong></button>\
                        </a>\
                    </div>\
                </div>\
            </div>\
        </div>\
        <div class="text-center pls-font-12 ng-hide" ng-show="is_group_buy"><a class="pls-jullians-eyes" href="/pricing" target="_self">See normal pricing page.</a></div>\
    </div>\
    <!-- END COLUMN - GROUP BUY -->\
    <!-- COLUMN - PLANS -->\
    <div ng-repeat="plan in marketPlans" class="flex-item m-b-2 pricing-pad-lg clearfix" ng-class="{\'pricing-premium-col\': plan.best_option}"> \
        <div class="m-l-1 m-r-1" ng-if="plan.best_option" ng-click="navigateToStore(plan.slug+(plan.slug_params ? plan.slug_params : \'\'))">\
            <div class="bg-pls-orange pricing-premium-plan-header">\
                <span class="pls-white pls-font-cabin pls-font-12 pls-font-weight-400">MOST POPULAR</span>\
            </div>\
        </div>\
        <div class="col-xs-12 pricing-box-wrapper" ng-class="{\'pricing-box-wrapper--best\': plan.best_option}" ng-click="navigateToStore(plan.slug+(plan.slug_params ? plan.slug_params : \'\'))">\
            <div class="pricing-badge" ng-if="!plan.recurring && !is_group_buy">${{ ((plan.units * plan.unit_base_price) - (plan.price)) | integer }} off</div>\
            <div class="pricing-badge" ng-if="is_group_buy && GroupBuyService.group_buy.goal - 1 - GroupBuyService.group_buy.users.length >= 1">{{ GroupBuyService.group_buy.discount_before_goal }}% off</div>\
            <div class="pricing-badge" ng-if="is_group_buy && GroupBuyService.group_buy.goal - 1 - GroupBuyService.group_buy.users.length < 1">{{ GroupBuyService.group_buy.discount_after_goal }}% off</div>\
            <h3 class="add-top pls-black pricing-block-header pls-font-18 pls-font-cabin pls-font-weight-800 p-t-1 m-b-0">{{plan.header}}</h3>\
            <div class="pls-blue pls-font-14 pls-font-cabin pls-font-weight-800" ng-class="{\'pls-orange\':plan.best_option, \'hidden-xs\': !plan.price_description}">{{ plan.price_description ? plan.price_description : \'&nbsp;\' }}</div>\
            <div ng-class="{\'pls-orange\': plan.best_option}">\
                <div ng-if="plan.strike_price && hasStrikePrice">\
                    <div class="pricing-line-through"></div>\
                    <h4 class="pls-font-40 pricing-plan-price m-t-0 m-b-0">\
                        <span><span class="pls-wet-cement">${{(plan.strike_price / plan.units) | integer}}</span><sup class="cents pls-wet-cement">{{ ((plan.strike_price / plan.units)  % 1 * 100) < 10 ? \'0\' : \'\' }}{{ ((plan.strike_price / plan.units) % 1 * 100) | number:0 }}</sup><sub class="unit" ng-style="{\'color\': (!plan.best_option) ? \'#aaacb3\' : \'#616b80\' }">/mo</sub></span>\
                    </h4>\
                </div>\
                <div ng-if="!plan.strike_price && hasStrikePrice" class="hidden-xs">\
                    <h4 class="pls-font-40 pricing-plan-price m-t-0 m-b-0">&nbsp;</h4>\
                </div>\
                <h4 class="pls-font-40 m-t-0 m-b-0 pricing-plan-price" ng-if="!plan.is_group_buy">$<span ng-if="plan.recurring">{{plan.price | integer}}<sup class="cents">99</sup><sub class="unit">/mo</sub></span><span ng-if="!plan.recurring">{{(plan.price / plan.units) | integer}}<sup class="cents">{{ ((plan.price / plan.units)  % 1 * 100) < 10 ? \'0\' : \'\' }}{{ ((plan.price / plan.units)  % 1 * 100) | number:0 }}</sup><sub class="unit">/mo</sub></span></h4>\
                 <div ng-if="plan.is_group_buy">\
                    <div class="pricing-line-through"></div>\
                    <h4 class="pls-font-40 pricing-plan-price m-t-0 m-b-0">\
                        <span><span class="pls-wet-cement">${{(plan.price / plan.units) | integer}}</span><sup class="cents pls-wet-cement">{{ ((plan.price / plan.units)  % 1 * 100) < 10 ? \'0\' : \'\' }}{{ ((plan.price / plan.units) % 1 * 100) | number:0 }}</sup><sub class="unit" ng-style="{\'color\': (!plan.best_option) ? \'#aaacb3\' : \'#616b80\' }">/mo</sub></span>\
                    </h4>\
                </div>\
                <div ng-if="plan.is_group_buy" class="pls-blue pls-font-14 pls-font-cabin pls-font-weight-800 m-t-1 m-b-1" ng-class="{\'pls-orange\':plan.best_option}">Group Buy Discount</div>\
                <h4 class="pls-font-40 m-t-0 m-b-0 pricing-plan-price" ng-if="plan.is_group_buy && GroupBuyService.group_buy.goal - 1 - GroupBuyService.group_buy.users.length >= 1">$<span ng-if="!plan.recurring">{{((plan.price - ( plan.price * GroupBuyService.group_buy.discount_before_goal/100)) / plan.units) | integer}}<sup class="cents">{{ (((plan.price - ( plan.price * GroupBuyService.group_buy.discount_before_goal/100)) / plan.units)  % 1 * 100) < 10 ? \'0\' : \'\' }}{{ (((plan.price - ( plan.price * GroupBuyService.group_buy.discount_before_goal/100)) / plan.units)  % 1 * 100) | number:0 }}</sup><sub class="unit">/mo</sub></span></h4>\
                <h4 class="pls-font-40 m-t-0 m-b-0 pricing-plan-price" ng-if="plan.is_group_buy && GroupBuyService.group_buy.goal - 1 - GroupBuyService.group_buy.users.length < 1">$<span ng-if="!plan.recurring">{{((plan.price - ( plan.price * GroupBuyService.group_buy.discount_after_goal/100)) / plan.units) | integer}}<sup class="cents">{{ (((plan.price - ( plan.price * GroupBuyService.group_buy.discount_after_goal/100)) / plan.units)  % 1 * 100) < 10 ? \'0\' : \'\' }}{{ (((plan.price - ( plan.price * GroupBuyService.group_buy.discount_after_goal/100)) / plan.units)  % 1 * 100) | number:0 }}</sup><sub class="unit">/mo</sub></span></h4>\
                <div ng-if="plan.expiration && DateService.showExpirationDate(plan.expiration)"><p class="m-t-0 m-b-1 pricing-block-expiration-date"><em>Expires {{ DateService.dateTimeStringToDate(plan.expiration) | date:\'MMM dd, yyyy @ h:mm\':\'-07:00\'}} Pacific</em></p></div>\
                <div ng-if="!(plan.expiration && DateService.showExpirationDate(plan.expiration))"class="hidden-xs"><p class="m-t-0 m-b-1 pricing-block-expiration-date pricing-block-expiration-date--transparent"><em>Expires Dec 31, 2020 @ 11:59 Pacific</em></p></div>\
            </div>\
            <div class="text-center">\
                <div class="p-t-0 pricing-block-sub-header">\
                    <span ng-if="!plan.is_group_buy">${{ plan.price | number:2 }}</span>\
                    <span ng-if="plan.is_group_buy && GroupBuyService.group_buy.goal - 1 - GroupBuyService.group_buy.users.length >= 1"><span>${{ (plan.price - ( plan.price * GroupBuyService.group_buy.discount_before_goal/100)) | number:2 }}</span></span>\
                    <span ng-if="plan.is_group_buy && GroupBuyService.group_buy.goal - 1 - GroupBuyService.group_buy.users.length < 1"><span>${{ (plan.price - ( plan.price * GroupBuyService.group_buy.discount_after_goal/100)) | number:2 }}</span></span>\
                    {{plan.sub_header}}<br>\
                </div>\
                <div class="p-t-1 p-b-1 pls-font-10" ng-class="{\'pls-blue\': !plan.best_option, \'pls-orange\': plan.best_option}" ng-if="plan.is_group_buy && GroupBuyService.group_buy.goal - 1 - GroupBuyService.group_buy.users.length >= 1">BONUS {{ GroupBuyService.group_buy.discount_before_goal }}% refund if group buy goal is hit</div>\
                <div class="add-top-less row">\
                    <div class="col-xs-12">\
                        <a ng-href="/store/{{plan.slug}}{{plan.slug_params}}" target="_self">\
                            <button class="pls-btn pls-black pricing-plan-cta col-xs-12" ng-class="{\'pls-btn--lighten-orange\': plan.best_option, \'pls-magnum\': plan.best_option, \'pls-btn--lighten-blue\': !plan.best_option}"><strong>BUY NOW</strong></button>\
                        </a>\
                    </div>\
                </div>\
            </div>\
        </div>\
    </div>\
    <!-- END COLUMN - PLANS -->\
</div>'
        };
    }]);