angular.module('picmonic-common')
    .factory("SplitTestService", ['SplitTest', 'UserService', '$cookies', '$filter', function(SplitTest, UserService, $cookies, $filter) {
        return {
            splittests: window.pls_config.splittests,
            split: function(event_label, potential_outcomes, expiration_offset, only_new_visitors, guest) {
                if ($filter('filter')(this.splittests, {name: event_label}, true).length) {
                    return $filter('filter')(this.splittests, {name: event_label}, true)[0].result;
                }

                var key = 'pls_user_split_'+event_label;
                var value = $cookies.get(key);
                if(value) {
                    return value;
                }

                if (only_new_visitors){
                    var cutoff =  Math.floor(Date.now()/1000) - 86400;
                    var initial_time = this.getCookie('pls_user_initial_timestamp', Math.floor(Date.now()/1000));
                    if (cutoff > initial_time) {
                        return;
                    }
                }

                if (guest && UserService.user.user_id) {
                    return;
                }

                if(potential_outcomes.constructor === Array) {
                    var num = Math.floor(Math.random() * potential_outcomes.length);
                    value = potential_outcomes[num];
                } else {
                    value = Math.floor((Math.random() * potential_outcomes) + 1);
                }

                if(!expiration_offset) {
                    expiration_offset = 10 * 365 * 24 * 60 * 60 * 1000;
                }
                var expiration_date = new Date();
                expiration_date.setTime(expiration_date.valueOf() + expiration_offset);

                if (event_label && typeof atlasEvent !== 'undefined') {
                    atlasEvent('split', event_label + ' ' + value);
                }

                $cookies.put(key, value, { expires: expiration_date });
                return value;
            },
            getCookie: function(key, fallback) {
                var cv = $cookies.get(key);
                return cv ? cv : fallback;
            }
        };
    }]);
