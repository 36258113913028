angular.module('picmonic-common')
  .directive('keyboardModality', ['$rootScope', '$timeout', function($rootScope, $timeout) {
    return {
      restrict: 'A',
      link: function($scope, element, attrs) {
        /* https://github.com/alice/modality */
        var hadKeyboardEvent = false,
            keyboardModalityWhitelist = [
              "input:not([type])",
              "input[type=text]",
              "input[type=number]",
              "input[type=date]",
              "input[type=time]",
              "input[type=datetime]",
              "textarea",
              "[role=textbox]",
              "[supports-modality=keyboard]"
            ].join(","),
            isHandlingKeyboardThrottle,
            matcher = (function () {
        			var el = document.body;
        			if (el.matchesSelector)
        				return el.matchesSelector;
        			if (el.webkitMatchesSelector)
        				return el.webkitMatchesSelector;
        			if (el.mozMatchesSelector)
        				return el.mozMatchesSelector;
        			if (el.msMatchesSelector)
        				return el.msMatchesSelector;
        			console.error("Couldn't find any matchesSelector method on document.body.");
        		}()),
        		focusTriggersKeyboardModality = function (el) {
        			var triggers = false;
        			if (matcher) {
        				triggers = matcher.call(el, keyboardModalityWhitelist) && matcher.call(el, ":not([readonly]");
        			}
    	        return triggers;
        		};

        element.keydown(function(e) {
          hadKeyboardEvent = true;
          if(e.keyCode == 9) {
            // Tab
            element.attr('modality', 'keyboard');
            $timeout(function(){
              $rootScope.hasKeyboardModality = true;
              $rootScope.canCopy = false;
            }, 0);
          }

          if (isHandlingKeyboardThrottle) {
              clearTimeout(isHandlingKeyboardThrottle);
          }
          isHandlingKeyboardThrottle = setTimeout(function() {
              hadKeyboardEvent = false;
          }, 100);
        });

        element.on('focusin', function(e) {
          if (hadKeyboardEvent && focusTriggersKeyboardModality(e.target)) {
            element.attr('modality', 'keyboard');
            $timeout(function(){
              $rootScope.canCopy = false;
              $rootScope.hasKeyboardModality = true;
            }, 0);
          }
        });

        element.on('blurin', function() {
          element.removeAttr("modality");
            $timeout(function(){
            $rootScope.hasKeyboardModality = false;
            if(!$rootScope.zcError) {
                $rootScope.canCopy = true;
            }
            }, 0);
        });

        element.on('mousemove', function() {
          if($rootScope.hasKeyboardModality) {
            element.removeAttr("modality");
            $timeout(function(){
              $rootScope.hasKeyboardModality = false;
              if(!$rootScope.zcError) {
                  $rootScope.canCopy = true;
              }
            }, 0);
          }
        });
      }
    }
  }]);
