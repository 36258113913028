angular.module('picmonicpublic')
    .controller('EducatorsController', ['$scope', 'IntercomService', 'User', '$cookies', 'UserService', function ($scope, IntercomService, User, $cookies, UserService) {
        $scope.formData = {};
        $scope.formVisible = false;
        $scope.email_regex = /^[a-z0-9._+]+@[a-z-_0-9.]+\.edu$/;
        $scope.formStatus = null;
        $scope.$emit('endInteraction');
        $scope.logged_in = UserService.user.user_id ? true : false;

        $scope.formData.market_id = $cookies.get('pls_user_lead', 100);

        IntercomService.trackEvent('educator-page-viewed');

        $scope.toggleForm = function () {
            $scope.formVisible = !$scope.formVisible;
            angular.element('body').toggleClass('scroll-lock');
        };

        $scope.submitForm = function() {
            if($scope.eduForm.$valid) {
              User.post({ resource: 'educator' }, $scope.formData, function(res) {
                  if(res.error) {
                      $scope.formStatus = false;
                  } else {
                      $scope.formStatus = true;
                  }
              });
            }
        };
    }]);