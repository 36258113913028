angular.module('picmonic-common')
    .directive("cardList", [function(){
        return {
            restrict: 'E',
            templateUrl: '/templates/public/directives/card-list.html',
            replace: true,
            scope: {
                slug: '@',
            },
            controller: ['$scope', 'MarketOptionsConfig', '$http', function($scope, MarketOptionsConfig, $http){
                $scope.loading = true;
                $http.get('/api/v3/cardlist?market_id='+ MarketOptionsConfig.getMarketBySlug($scope.slug).id).then(function(response){
                    $scope.pathways = response.data;
                }).finally(function(){
                    $scope.loading = false;
                });
            }]
        };
    }]);