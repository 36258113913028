angular.module('picmonicpublic')
    .config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

        $urlRouterProvider

            .when('/pricing/physicianassistant', '/pricing/physician-assistant');

        $stateProvider
            .state('public', {
                abstract: true,
                views: {
                    'nav' : {
                        templateUrl: '/templates/partials/nav-public.html',
                        controller: 'NavigationController'
                    },
                    'footer': {
                        templateUrl: '/templates/partials/footer.html',
                        controller: 'FooterController'
                    }
                }
            })
            .state('public.404', {
                url: '/public/404',
                views: {
                    'template@': {
                        templateUrl: '/templates/public/404.html',
                        controller: '404Controller'
                    }
                }
            })
            .state('public.home', {
                url: '/?status',
                params: {
                    slug: 'home',
                    status: null
                },
                views: {
                    'template@': {
                        templateProvider: ['$templateFactory', 'SplitTestService', function($templateFactory, SplitTestService) {
                            var split = SplitTestService.split('new_home_page', ['old', 'new']);
                            var page = (split === 'new')?'home-b.html':'home-a.html';

                            return $templateFactory.fromUrl('/templates/public/'+page);
                        }],
                        controller: 'HomePageController'
                    }
                },
                resolve: {
                    splitTest: function(){ return '';},
                    bundleBuy: function(){ return '';},
                    groupBuy: function(){ return '';}
                }
            })
            .state('public.presentations', {
                url: '/presentations?id',
                views: {
                    'template@': {
                        component:'presentationsComponent'
                    }
                },
            })
            .state('public.bb', {
                url: '/bb/:bundle_buy_slug',
                params: {
                    is_bundle_buy: 1
                },
                views: {
                    'template@': {
                        templateUrl: '/templates/public/bb.html',
                        controller: 'BbController'
                    },
                    'footer@': {
                        templateUrl: null
                    }
                },
                resolve: {
                    bundleBuy: ['$stateParams', '$cookies', 'MarketOptionsConfig', 'BundleBuyService', function($stateParams, $cookies, MarketOptionsConfig, BundleBuyService) {
                        BundleBuyService.getBySlug($stateParams.bundle_buy_slug).then(function(){

                            // If Group Buy doesn't exist redirect to /pricing page
                            if (!BundleBuyService.bundle_buy.slug) {
                                return window.location = '/pricing';
                            }

                            // Set Group Buy Cookie
                            $cookies.put('pls_user_bundle_buy', BundleBuyService.bundle_buy.slug);

                            return MarketOptionsConfig.getMarketById(BundleBuyService.bundle_buy.market_id).slug;
                        });

                        BundleBuyService.bundle_buy.$promise.then(function(){}, function(){
                            window.location = '/pricing/medicine';
                        });

                        return BundleBuyService.bundle_buy.$promise;
                    }],
                    splitTest: function(){ return '';},
                    groupBuy: function(){ return '';}
                }
            })
            .state('public.gb', {
                url: '/gb/:group_buy_slug',
                params: {
                    is_group_buy: 1
                },
                views: {
                    'template@': {
                        templateUrl: '/templates/pages/wordpress/page.html',
                        controller: 'PageController'
                    }
                },
                resolve: {
                    groupBuy: ['$stateParams', '$cookies', 'MarketOptionsConfig', 'GroupBuyService', '$location', 'SplitTestService', function($stateParams, $cookies, MarketOptionsConfig, GroupBuyService, $location, SplitTestService) {
                        return GroupBuyService.getBySlug($stateParams.group_buy_slug).then(function(){

                            // If Group Buy doesn't exist redirect to /pricing page
                            if (!GroupBuyService.group_buy.slug) {
                                return window.location = '/pricing';
                            }

                            if ($location.search().src) {
                                atlasEvent('click', $location.search().src);
                            }

                            // Set Group Buy Cookie
                            $cookies.put('pls_user_group_buy', GroupBuyService.group_buy.slug);

                            // Revert to standard group buy page if it's expired
                            if (GroupBuyService.group_buy.time_left > 0) {
                                var splitTest = SplitTestService.split('group_buy_pricing', ['pricing_page', 'store']);

                                if (splitTest === 'store') {
                                    GroupBuyService.launch(MarketOptionsConfig.getMarketById(GroupBuyService.group_buy.owner.lead).slug, true);
                                }
                            }

                            return MarketOptionsConfig.getMarketById(GroupBuyService.group_buy.owner.lead).slug;
                        });
                    }],
                    splitTest: function(){ return '';},
                    bundleBuy: function(){ return '';},
                }
            })
            .state('public.pricing', {
                abstract: true,
                url: '/pricing',
                params: {
                    is_group_buy: 0
                },
                resolve: {
                    splitTest: function() {
                        return '';
                    }
                }
            })
            .state('public.pricing.nursing', {
                url: '/nursing',
                params: {
                    slug: 'nursing',
                    market: 'nursing'
                },
                views: {
                    'template@': {
                        templateUrl: '/templates/pages/wordpress/page.html',
                        controller: 'PageController'
                    }
                },
                resolve: {
                    $meta: function() {return {replacements:{'%MARKET%': 'Nursing'}};},
                    bundleBuy: function(){ return '';},
                    groupBuy: function(){ return '';}
                }
            })
            .state('public.pricing.nursing-lpn', {
                url: '/nursing-lpn',
                params: {
                    slug: 'nursing-lpn',
                    market: 'nursing-lpn'
                },
                views: {
                    'template@': {
                        templateUrl: '/templates/pages/wordpress/page.html',
                        controller: 'PageController'
                    }
                },
                resolve: {
                    $meta: function() {return {replacements:{'%MARKET%': 'Nursing LPN'}};},
                    bundleBuy: function(){ return '';},
                    groupBuy: function(){ return '';}
                }
            })
            .state('public.pricing.nursing-np', {
                url: '/nursing-np',
                params: {
                    slug: 'nursing-np',
                    market: 'nursing-np'
                },
                views: {
                    'template@': {
                        templateUrl: '/templates/pages/wordpress/page.html',
                        controller: 'PageController'
                    }
                },
                resolve: {
                    $meta: function() {return {replacements:{'%MARKET%': 'Nurse Practitioner'}};},
                    bundleBuy: function(){ return '';},
                    groupBuy: function(){ return '';}
                }
            })
            .state('public.pricing.medical-terminology', {
                url: '/medical-terminology',
                params: {
                    slug: 'medical-terminology',
                    market: 'medical-terminology'
                },
                views: {
                    'template@': {
                        templateUrl: '/templates/pages/wordpress/page.html',
                        controller: 'PageController'
                    }
                },
                resolve: {
                    $meta: function() {return {replacements:{'%MARKET%': 'Medical Terminology'}};},
                    bundleBuy: function(){ return '';},
                    groupBuy: function(){ return '';}
                }
            })
            .state('public.pricing.medicine', {
                url: '/medicine?osmosis',
                params: {
                    slug: 'medicine',
                    market: 'medicine',
                    no_market: false,
                    osmosis: null
                },
                views: {
                    'template@': {
                        templateUrl: '/templates/pages/wordpress/page.html',
                        controller: 'PageController'
                    }
                },
                resolve: {
                    $meta: function() {return {replacements:{'%MARKET%': 'Medicine'}};},
                    bundleBuy: function(){ return '';},
                    groupBuy: function(){ return '';}
                }
            })
            .state('public.pricing.prehealth', {
                url: '/prehealth',
                params: {
                    slug: 'prehealth',
                    market: 'prehealth'
                },
                views: {
                    'template@': {
                        templateUrl: '/templates/pages/wordpress/page.html',
                        controller: 'PageController'
                    }
                },
                resolve: {
                    $meta: function() {return {replacements:{'%MARKET%': 'Pre-Health'}};},
                    bundleBuy: function(){ return '';},
                    groupBuy: function(){ return '';}
                }
            })
            .state('public.pricing.pharmacy', {
                url: '/pharmacy',
                params: {
                    slug: 'pharmacy',
                    market: 'pharmacy'
                },
                views: {
                    'template@': {
                        templateUrl: '/templates/pages/wordpress/page.html',
                        controller: 'PageController'
                    }
                },
                resolve: {
                    $meta: function() {return {replacements:{'%MARKET%': 'Pharmacy'}};},
                    bundleBuy: function(){ return '';},
                    groupBuy: function(){ return '';}
                }
            })
            .state('public.pricing.beta', {
                url: '/beta?market&pathway&path&pathway_type&curriculum',
                params: {
                    slug: 'beta'
                },
                views: {
                    'template@': {
                        templateUrl: '/templates/pages/wordpress/page.html',
                        controller: 'PageController'
                    }
                },
                resolve: {
                    $meta: ['$stateParams', 'MarketOptionsConfig', function($stateParams, MarketOptionsConfig) {
                        var market_data = MarketOptionsConfig.getMarketBySlug($stateParams.market);
                        return {replacements:{'%MARKET%': market_data.name}};
                    }],
                    bundleBuy: function(){ return '';},
                    groupBuy: function(){ return '';}
                }
            })
            .state('public.pricing.physician-assistant', {
                url: '/physician-assistant',
                params: {
                    slug: 'physician-assistant',
                    market: 'physician-assistant'
                },
                views: {
                    'template@': {
                        templateUrl: '/templates/pages/wordpress/page.html',
                        controller: 'PageController'
                    }
                },
                resolve: {
                    $meta: function() {return {replacements:{'%MARKET%': 'Physician Assistant'}};},
                    bundleBuy: function(){ return '';},
                    groupBuy: function(){ return '';}
                }
            })
            .state('public.question-of-the-day', {
                url: '/nursing-nclex-question-of-the-day',
                views: {
                    'template@': {
                        templateUrl: '/templates/pages/question-of-the-day/index.html',
                        controller: 'QuestionOfTheDayController'
                    }
                }
            })
            .state('public.question-of-the-day.day', {
                url: '/:day',
                views: {
                    'template@': {
                        templateUrl: '/templates/pages/question-of-the-day/index.html',
                        controller: 'QuestionOfTheDayController'
                    }
                }
            })
            .state('public.upcomingwebinars', {
                url: '/webinars',
                views: {
                    'template@': {
                        templateUrl: '/templates/public/upcomingwebinars.html',
                        controller: 'UpcomingWebinarsController'
                    }
                }
            })
            .state('public.webinar', {
                url: '/webinars/:market/:webinar_id',
                views: {
                    'template@': {
                        templateUrl: '/templates/public/webinars.html',
                        controller: 'WebinarsController'
                    }
                }
            })
            .state('public.educators', {
                url: '/educators',
                views: {
                    'template@': {
                        templateUrl: '/templates/pages/educators/index.html',
                        controller: 'EducatorsController'
                    }
                }
            })
            .state('public.career', {
                url: '/page/careers/:slug?status',
                params: {
                    status: null,
                    resource: 'careers'
                },
                views: {
                    'template@': {
                        templateUrl: '/templates/pages/wordpress/page.html',
                        controller: 'PageController'
                    }
                },
                resolve: {
                    $meta: ['$stateParams', function($stateParams) {
                      var position = $stateParams.slug.replace('-', ' ')
                        .replace(/^([a-z\u00E0-\u00FC])|\s+([a-z\u00E0-\u00FC])/g, function ($1) {
                          return $1.toUpperCase();
                        });
                      return {
                        replacements: {'%POSITION%': position}
                      };
                    }],
                    splitTest: function(){ return '';},
                    bundleBuy: function(){ return '';},
                    groupBuy: function(){ return '';}
                }
            })
            .state('public.page', {
              url: '/page/:slug?status',
              params: {
                  status: null
              },
              views: {
                'template@': {
                  templateUrl: '/templates/pages/wordpress/page.html',
                  controller: 'PageController'
                }
              },
              resolve: {
                  splitTest: function(){ return '';},
                  bundleBuy: function(){ return '';},
                  groupBuy: function(){ return '';}
              }
            })
            .state('public.auth', {
                abstract: true,
                views: {
                  'template@': {
                    templateUrl: '/templates/pages/auth/template.html',
                    controller: ['$scope', '$state', 'OnboardingService', function($scope, $state, OnboardingService) {
                        $scope.$state = $state;
                        $scope.OnboardingService = OnboardingService;
                    }]
                  }
                }
            })
            .state('public.auth.login', {
                url: '/login',
                views: {
                    'content': {
                        templateUrl: '/templates/pages/auth/login.html',
                        controller: 'LoginController'
                    }
                }
            })
            .state('public.auth.login.step2', {
                url: '/step2',
                views: {
                    'content@public.auth':{
                        templateUrl: '/templates/pages/auth/register-2.html',
                        controller: 'RegisterStep2Controller'
                    }
                }
            })
            .state('public.auth.login.step3', {
                url: '/step3',
                views: {
                    'content@public.auth':{
                        templateUrl: '/templates/pages/auth/register-3.html',
                        controller: 'RegisterStep3Controller'
                    }
                }
            })
            .state('public.auth.register', {
                url: '/register',
                views: {
                    'content@public.auth': {
                        templateUrl: '/templates/pages/auth/register-1.html',
                        controller: 'RegisterStep1Controller'
                    }
                }
            })
            .state('public.auth.register.step2', {
                url: '/step2',
                views: {
                    'content@public.auth':{
                        templateUrl: '/templates/pages/auth/register-2.html',
                        controller: 'RegisterStep2Controller'
                    },
                    'footer@':{
                        templateUrl: null
                    }
                }
            })
            .state('public.auth.register.step3', {
                url: '/step3',
                views: {
                    'content@public.auth':{
                        templateUrl: '/templates/pages/auth/register-3.html',
                        controller: 'RegisterStep3Controller'
                    },
                    'footer@':{
                        templateUrl: null,
                    }
                }
            })
            .state('public.auth.login.reset-password', {
                url: '/reset-password',
                views: {
                    'content@public.auth':{
                        templateUrl: '/templates/pages/auth/reset-password.html',
                        controller: 'PasswordResetController'
                    }
                }
            })
            .state('public.auth.login.reset-password.step2', {
                url: '/:token',
                views: {
                    'content@public.auth':{
                        templateUrl: '/templates/pages/auth/reset-password-2.html',
                        controller: 'PasswordResetStep2Controller'
                    }
                }
            })
            .state('public.auth.login.mobile-app', {
                url: '/mobile-app?card_id',
                params: {
                    card_id: null
                },
                views: {
                    'content@public.auth':{
                        templateUrl: '/templates/pages/auth/mobile-app.html',
                        controller: 'MobileAppController'
                    }
                }
            })
            .state('public.auth.login.update-browser', {
                url: '/update-browser',
                views: {
                    'content@public.auth':{
                        templateUrl: '/templates/pages/auth/update-browser.html'
                    }
                }
            });

        $urlRouterProvider
            .when('/pricing', ['UserService', '$state', 'MarketOptionsConfig', '$location', '$cookies', function (UserService, $state, MarketOptionsConfig, $location, $cookies) {
                $location.replace();
                if (UserService.user.user_id) {
                    $state.transitionTo('public.pricing.' + MarketOptionsConfig.getMarketById(UserService.user.lead).slug, {market: MarketOptionsConfig.getMarketById(UserService.user.lead).slug, slug: MarketOptionsConfig.getMarketById(UserService.user.lead).slug});
                    return;
                } else {
                    var lead = $cookies.get('pls_user_lead');
                    if (lead) {
                        $state.transitionTo('public.pricing.' + MarketOptionsConfig.getMarketById(lead).slug, {market: MarketOptionsConfig.getMarketById(lead).slug, slug: MarketOptionsConfig.getMarketById(lead).slug});
                        return;
                    }
                }
        
                $state.transitionTo('public.pricing.medicine', {market: 'medicine', no_market:true});
                return;
            }])
            .otherwise(function($injector, $location) {
                if(typeof(atlasEvent) !== 'undefined') {
                    atlasEvent('404', document.location);
                }
                return '/public/404';
            });
    }]);
