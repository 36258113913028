angular.module('picmonic-common')
    .factory("Picmonics", ['$resource', function($resource){
        return $resource("/apiv2/picmonics/:card_id/:resource/:resource_id", {card_id:'@card_id', resource:'@resource', resource_id: '@resource_id'}, {
            'query':{method:'GET', isArray:true},
            'get':{method:'GET', isArray:false},
            'update':{method:'PUT', isArray:false},
            'save':   {method:'POST', isArray:false},
            'search': {method:'GET', isArray: false, url: '/api/v3/picmonics'},
            'saveEditorSession': {method:'POST', isArray: false, url: '/api/v3/picmonics/:card_id/editor_sessions'}
        });
    }]);
