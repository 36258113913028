angular.module('picmonic-common')
    .directive('picmonicThumbnail', ['$timeout', '$state', '$location', 'UserService', '$rootScope', function($timeout, $state, $location, UserService, $rootScope){
        return {
            restrict: 'E',
            scope: {
                picmonic: '=',
                goto: '@',
                size: '@',
                hidePlay: '='
            },
            template:
            '<div class="picmonic-thumbnail">' +
                '<img lazy-load="{{cdn_url}}/{{directory}}/{{picmonic.background?picmonic.background:picmonic.main_image}}" class="img-responsive" ng-if="!picmonic.is_mature || !UserService.user.safety_mode"/>' +
                '<img lazy-load="{{base_asset_url}}/assets/img/mature-content-small.png" class="img-responsive" ng-if="picmonic.is_mature && UserService.user.safety_mode"/>' +
                '<div class="card-icon" ng-if="hidePlay != true && (goto == \'none\' || goto == \'app.learn\' || $state.current.name==\'app.learn\')">' +
                    '<i class="icon-play"></i>' +
                '</div>' +
            '</div>',
            controller: ['$scope', 'UserService', function($scope, UserService){
                $scope.UserService = UserService;
            }],
            link: function(scope, element){
                scope.$state = $state;

                scope.cdn_url = $rootScope.cdn_url;

                if (scope.goto != 'none') {
                    scope.goto = 'app.learn';
                }

                function setDimensions(){
                    //determine the font-size and line-height of the card icon
                    var font_size = (element.find('.card-icon').width() / 1.8);
                    element.find('.card-icon').css({'font-size':font_size+'px', 'line-height': element.find('.card-icon').height()+'px'});
                }

                switch(scope.size) {
                    case "full":
                        scope.directory = 'images_panel';
                        break;
                    case "medium":
                        scope.directory = 'images_panel_tablet';
                        break;
                    case "small":
                        scope.directory = 'images_panel_mobile';
                        break;
                    default:
                        scope.directory = 'images_panel_thumb';
                        break;
                }

                $timeout(setDimensions, 100);
                angular.element(window).resize(setDimensions);

                element.bind('click', function(event){
                    send_ga('event', 'Picmonic Thumbnail', 'Clicked');

                    if (scope.goto != 'none') {
                        scope.$apply(function(){
                            $state.go(scope.goto, {slug: scope.picmonic.slug});
                        });
                    }
                });
            }
        };
    }]);