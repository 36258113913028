angular.module('picmonic-common')
    .filter('findInArray', function() {
        return function(input, val, property) {
            var i=0, len=input.length;
            for (; i<len; i++) {
                if (input[i][property] == val) {
                    return input[i];
                }
            }
            return false;
        }
    });