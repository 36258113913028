angular.module('picmonic-common')
    .directive('lazyLoad', function(){
        return {
            restrict: 'A',
            link: function(scope, element, attr) {
                attr.$observe('lazyLoad', function(value) {
                    if (attr.class != 'background') {
                        element[0].src = '/assets/img/placeholder-card-thumb_v2.jpg';
                    }
                    var img = angular.element('<img />');
                    img.attr('src', value);

                    img.on('load', function () {
                        element[0].src = img.attr('src');
                    });
                });
            }
        };
    });