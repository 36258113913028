angular.module('picmonic-common')
    .directive("referralBlock", [function(){
        return {
            restrict: 'E',
            scope: {
                marketSlug: '='
            },
            templateUrl: '/templates/common/directives/referral-block.html',
            controller: ['$rootScope', '$scope', '$cookies', '$state', '$stateParams', 'UserService', 'ReferralResource', 'ReferralDataService', function($rootScope, $scope, $cookies, $state, $stateParams, UserService, ReferralResource, ReferralDataService){
                $scope.base_asset_url = $rootScope.base_asset_url;
                $scope.ReferralDataService = ReferralDataService;
                $scope.osmosis = $stateParams.osmosis;
                $scope.is_group_buy = $stateParams.is_group_buy;
                $scope.UserService = UserService;
                $scope.$state = $state;

                var referrerLinkClicked = $cookies.get('pls_user_referrer_link_clicked');
                if (referrerLinkClicked) {
                    $cookies.remove('pls_user_referrer_link_clicked');
                    if(typeof atlasEvent !== 'undefined') {
                        atlasEvent('referral', referrerLinkClicked);
                    }
                }

                var referrerCode = $cookies.get('referrer_code');
                if (referrerCode) {
                    ReferralResource.get({referral_code: referrerCode}, function(data){
                        ReferralDataService.referral = data;
                    });
                }

                if (UserService.user.user_id && UserService.user.is_referred) {
                    ReferralResource.get({}, function(data){
                        ReferralDataService.referral = data;
                    });
                }
            }]
        };
    }]);