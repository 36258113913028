angular.module('picmonic-common')
    .filter('expiresIn', function(){
        return function(seconds){
            if (seconds < 1) {
                return 'Expired';
            }
            var value = '';

            var days = Math.floor(seconds/(60*60*24));
            seconds -= days*60*60*24;
            var hours = Math.floor(seconds/(60*60));
            seconds -= hours*60*60;
            var minutes = Math.floor(seconds/(60));
            seconds -= minutes*60;

            if (days > 0) {
                value = days+'d '+hours+'h '+minutes+'m '+seconds+'s';
            } else if(hours > 0) {
                value = hours+'h '+minutes+'m '+seconds+'s';
            } else if(minutes > 0) {
                value = minutes+'m '+seconds+'s';
            } else {
                value = seconds+'s';
            }
            
            return value;
        };
    });
