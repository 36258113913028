angular.module('picmonic-common')
    .directive('onboardingProgress', function(){
        return {
            restrict: 'E',
            templateUrl: '/templates/common/directives/onboarding-progress.html',
            scope: {},
            controller: ['$rootScope', '$scope', '$state', 'OnboardingService', function($rootScope, $scope, $state, OnboardingService) {
                $scope.base_asset_url = $rootScope.base_asset_url;
                $scope.OnboardingService = OnboardingService;
                $scope.$state = $state;
            }]
        };
    });