angular.module('picmonic-common')
    .directive('plsTypeahead', ['$timeout', function ($timeout) {
      return {
        restrict: 'E',
        replace: true,
        require: 'ngModel',
        transclude: true,
        template: '<div class="pls-typeahead" ng-class="{\'pls-typeahead--focused\': focused, \'pls-typeahead--dark\': dark}"><input type="text" class="{{styles}}" ng-model="searchTerm" ng-focus="focus()" placeholder="{{placeholder}}" ng-required="required" autocomplete="off"/><i class="icon-spin icon-circle-o-notch" ng-if="loading"></i><div class="pls-typeahead__results" ng-if="options && !loading"><ul><li ng-repeat="option in options" ng-class="{highlighted:($index==activeIndex)}" ng-click="select(option)" ng-transclude></li><li ng-if="!options.length" class="text-center">No Search Results</li></ul></div></div>',
        scope: {
            label: '@',         // The field of the model you want to be displayed as the input box label
            onSelect: '&',      // Callback for when a user selects an option from the search results (injects $item)
            onSearch: '&',      // Callback for when a user types a key and search begins (injects $searchTerm)
            placeholder: '@',   // Input field placeholder
            ngModel: '=',
            styles: '@',        // Input field classes
            required: '=',      // Input field required
            dark: '='           // Whether or not to apply dark styles
        },
        link: function (scope, elem, attrs, ngModel) {
            scope.activeIndex = 0;
            scope.focused = false;
            scope.searchTerm = ngModel ? ngModel[scope.label] : '';

            ngModel.$render = function () {
                var newValue = ngModel.$viewValue;
                scope.searchTerm = newValue ? newValue[scope.label] : '';
            };

            scope.focus = function () {
                scope.focused = true;
                scope.activeIndex = 0;
                scope.addClickToClose();
            };

            scope.blur = function () {
                scope.removeClickToClose();
                scope.focused = false;
            };

            scope.select = function (option) {
                scope.ngModel = angular.copy(option);
                scope.searchTerm = scope.ngModel[scope.label];
                scope.activeIndex = false;
                scope.blur();

                if (typeof scope.onSelect == 'function') {
                  var selected = angular.copy(option);
                  scope.onSelect({$item: selected});
                }

                delete scope.options;
            };

            elem.on('keydown', function (e) {
                scope.$apply(function() {
                    if (e.keyCode == 13 || e.keyCode == 9) {
                        if(!scope.focused) {
                            return;
                        }
                        if(scope.activeIndex !== false && scope.options[scope.activeIndex]) {
                            scope.select(scope.options[scope.activeIndex]);
                            scope.options = false;
                        
                            e.preventDefault();
                            e.stopPropagation();
                        } else {
                          scope.blur();
                        }
                        
                        return;
                    } else if(e.keyCode == 40) { // Down arrow
                        e.preventDefault();
                        
                          scope.activeIndex++;
                        
                        e.preventDefault();
                        e.stopPropagation();
                        return;
                    } else if(e.keyCode == 38) { // Up arrow
                        e.preventDefault();
                        
                          scope.activeIndex--;
                        
                        e.preventDefault();
                        e.stopPropagation();
                        return;
                    } else if(e.keyCode == 37 || e.keyCode == 39) { // Ignore other arrow keys
                      return;
                    }

                    scope.focus();

                    scope.loading = true;
                    scope.activeIndex = 0;

                    if (scope.timeout) {
                        $timeout.cancel(scope.timeout);
                    }

                    scope.timeout = $timeout(function () {
                        if (typeof scope.onSearch == 'function') {
                            scope.onSearch({$searchTerm: scope.searchTerm}).then(function (results) {
                                scope.options = results;
                            }).finally(function () {
                                scope.loading = false;
                            });
                        }
                    }, 250);
                });
            });

            function clickHandler(e) {
                if (scope.focused === true && elem[0] != e.target && !angular.element(elem).has(angular.element(e.target)).length) {
                    scope.$apply(function() {
                        scope.blur();
                    });
                }
            }

            scope.addClickToClose = function () {
                document.body.addEventListener("click", clickHandler, true);
            };

            scope.removeClickToClose = function () {
                document.body.removeEventListener("click", clickHandler, true);
            };
        }
      };
    }]);