angular.module('picmonic-common')
    .factory('IntercomService', ['$filter', function($filter){
        return {
            trackEvent: function(event, metadata) {
                metadata = metadata ? metadata : {};

                if (window.Intercom) {
                    window.Intercom('trackEvent', event, metadata);
                }
            },
            trackPageView: function() {
                if (window.Intercom) {
                    window.Intercom('update');
                }
            },
            setUserInfo: function(user, update) {
                if (window.Intercom) {
                    // When updating see: projects/app-v2/fuel/app/classes/service/intercom.php createUser
                    // When updating see: projects/app-v2/fuel/app/classes/service/emma.php getUserDetails

                    var showUserVoice = function() {
                        var frame = $(".intercom-launcher-frame");
                        var container = $("#intercom-container");

                        if (frame.css('visibility') == 'hidden') {
                            frame[0].style.setProperty( 'visibility', 'visible', 'important' );
                            container[0].style.setProperty( 'display', 'block', 'important' );
                        } else {
                            frame[0].style.setProperty( 'visibility', '' );
                            container[0].style.setProperty( 'display', '' );
                        }
                    };

                    if (user.user_id) {
                        window.Intercom(update ? "update" : "boot", {
                            app_id: window.pls_config.intercom_app_id,
                            user_id: user.user_id,
                            user_hash: user.intercom_user_hash,
                            email: user.email,
                            name: user.first_name + ' ' + user.last_name,
                            created_at: user.created_at,
                            "beta": user.beta_user_flag ? 'yes' : 'no',
                            "vertical": user.lead,
                            "subscription_type": user.subscription_type,
                            "subscription_exp": user.expiration,
                            "school_name": user.school ? user.school.name : null,
                            "cards_mastered": user.cards_mastered,
                            "advocate": (user.is_advocate?'yes':'no'),
                            "advocate_application_status": user.advocate_application_status,
                            "advocate_application_status_updated_at": user.advocate_application_status_updated_at,
                            "grad_year": (user.graduation_year ? user.graduation_year : ''),
                            "role": user.role,
                            "educator": (user.role == 'Administrator' || user.role == 'Professor' ? 'yes': 'no'),
                            "last_login": user.last_login,
                            "ugc_creator": (user.ugc_license ? 'yes' : 'no'),
                            "mobile_app_downloaded": (user.mobile_app_downloaded ? 'yes' : 'no'),
                            "spaced_rep_interaction": (user.spaced_rep_date ? 'yes' : 'no'),
                            "cards_viewed": (user.cards_viewed ? user.cards_viewed : 0),
                            "playlist_created": (user.playlist_created ? 'yes' : 'no'),
                            "register_source": (user.register_source ? user.register_source : ''),
                            "last_viewed_card_slug": (user.last_viewed_card_slug ? user.last_viewed_card_slug : ''),
                            "last_viewed_card_name": (user.last_viewed_card_name ? user.last_viewed_card_name : ''),
                            "referral_purchases": user.referral_purchases,
                            "referral_signups": user.referral_signups,
                            "market_name": ((user.market && user.market.name) ? user.market.name : ''),
                            "quiz_interaction": (user.quiz_agreed ? 'yes' : 'no'),
                            "referral_code": user.referrer_code,
                            widget: {
                                activator: '#uservoice-feedback, .uservoice-feedback'
                            }
                        });
                        $("body").on('click', '.uservoice-feedback, #uservoice-feedback', showUserVoice);

                    } else {
                        window.Intercom("boot", {
                            app_id: window.pls_config.intercom_app_id
                        });

                        var body = $('body');

                        body.on('click', '.uservoice-feedback', function(e){
                            e.preventDefault();
                            window.Intercom("show");

                            showUserVoice() ;
                        });

                        body.on('click', '#uservoice-feedback', function(e){
                            e.preventDefault();
                            window.Intercom("show");

                            showUserVoice();
                        });
                    }
                }
            }
        };
    }]);