angular.module('picmonic-common')
    .filter('arrayPos', function(){
        return function(haystack, needle, field){
            for(a in haystack){
                if(haystack[a][field] == needle){
                    return parseInt(a);
                }
            }
            return null;
        };
    });
