angular.module('picmonic-common')
    .controller("MobileAppController", ['$scope', '$location', '$stateParams', 'UserService', function($scope, $location, $stateParams, UserService){
        $scope.$emit('endInteraction');

        $scope.submit = function(){
            if ($location.search().redirect) {
                top.location = $location.protocol() + '://' + $location.host() + ($location.search().redirect.indexOf('/') === 0 ? $location.search().redirect : '/' +  $location.search().redirect);
            } else if ($scope.mode == 'login') {
                top.location = $location.protocol() + '://' + $location.host() + '/home';
            } else if (UserService.user.market && UserService.user.market.register_redirect) {
                top.location = $location.protocol() + '://' + $location.host() + UserService.user.market.register_redirect;
            } else {
                top.location = $location.protocol() + '://' + $location.host() + '/find-picmonic';
            }
        };
    }]);