angular.module('picmonic-common')
    .directive('uiToggle', [function(){
        return {
            restrict: 'E',
            template: '<div class="toggle-wrapper" ng-class="{active: ngModel == onValue}"><div class="toggle-handle"></div></div>',
            require: 'ngModel',
            scope: {
                'onValue': '=',
                'offValue': '=',
                'ngModel': '=',
                'onChange': '&'
            },
            link: function(scope, element, attrs, ngModel){
                element.bind('click', function(e){
                    e.stopPropagation();

                    if(scope.ngModel == scope.onValue){
                        ngModel.$setViewValue(scope.offValue);
                    }else{
                        ngModel.$setViewValue(scope.onValue);
                    }

                    if(typeof scope.onChange == 'function'){
                        scope.onChange();
                    }
                });
            }
        };
    }]);