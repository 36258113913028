angular.module('picmonic-common').directive("plsSrc", [function(){
    return {
        priority: 99,
        link: function(scope, element, attr) {
            attr.$observe('plsSrc', function(value) {
                if (!value) {
                    return;
                }

                attr.$set('src', window.pls_config.base_asset_url+value);

                //IE 9-11 only
                if (window.document.documentMode) element.prop('src', attr['src']);
            });
        }
    };
}]);