angular.module('picmonic-common')
    .factory('BundleBuyService', ['$interval', 'BundleBuysResource', function($interval, BundleBuysResource){
        return {
            bundle_buy: {},
            getBySlug: function (slug) {
                var self = this;
                self.bundle_buy = BundleBuysResource.get({slug:slug}, function(){
                    $interval.cancel(self.interval);
                    self.interval = $interval(function(){
                        self.bundle_buy.time_left--;
                    }, 1000, self.bundle_buy.time_left);
                });
                return self.bundle_buy.$promise;
            }
        };
    }]);
