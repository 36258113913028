angular.module('picmonic-common')
    .directive("groupBuyJoinBlock", [function(){
        return {
            restrict: 'E',
            templateUrl: '/templates/common/directives/group-buy-join-block.html',
            replace: true,
            scope: {
                slug: '@',
            },
            controller: ['$rootScope', '$scope', '$stateParams', 'GroupBuyService', 'SplitTestService', function($rootScope, $scope, $stateParams, GroupBuyService, SplitTestService){
                $scope.base_asset_url = $rootScope.base_asset_url;
                $scope.is_group_buy = $stateParams.is_group_buy;
                $scope.GroupBuyService = GroupBuyService;
                $scope.$stateParams = $stateParams;
            }]
        };
    }]);