angular.module('picmonic-common')
    .controller("LoginController", ["$scope", "$sce", "$q", "UserService", '$location', function($scope, $sce, $q, UserService, $location){
        //initialize datalayer for gtm
        window.dataLayer = window.dataLayer || [];
        $scope.credentials = UserService.user;
        $scope.$sce = $sce;

        //Set redirect variable to be displayed in <a href>
        $scope.redirect = $location.search().redirect ? encodeURIComponent($location.search().redirect) : '';

        $scope.$emit('endInteraction');

        // Picmonic Login
        $scope.appLogin = function(redirect, redirectUrl) {
            $scope.credentials.accessToken = null;

            return UserService.login(redirect, redirectUrl).then(null, function(error){
                $scope.error = error.data.message;
                $scope.loading = false;
                return $q.reject(error);
            });
        };

        // Authenticate with Facebook
        $scope.socialLogin = function(redirect, redirectUrl){
            $scope.loading = true;
            $scope.loadingMode = 'login-social';
            $scope.error = '';
            return UserService.socialLogin(redirect, false, redirectUrl).then(null, function(error){
                $scope.error = ((error && error.data.message) ? error.data.message : 'Not Authorized. You must click "Okay" when the Login with Facebook pop up opens.');
                $scope.loading = false;
                return $q.reject(error);
            });
        };

        // Submit Picmonic Login Form
        $scope.submitLogin = function($event, redirect, redirectUrl, success){
            succcess = success || function(){};

            if ($scope.login.$valid) {
                dataLayer.push({'event': 'user_login'});

                if($event) {
                    $event.preventDefault();
                }

                if(!$scope.loading){
                    $scope.loading = true;
                    $scope.loadingMode = 'login-picmonic';
                    $scope.error = '';
                    $scope.appLogin(redirect, redirectUrl).then(success);
                }
            } else {
                $scope.login.$setDirty();
                angular.forEach($scope.login.$error, function (field) {
                    angular.forEach(field, function(errorField){
                        errorField.$setTouched();
                    });
                });
            }
        };
    }]);