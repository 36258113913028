angular.module('picmonic-common')
    .controller("RegisterStep1Controller", ['$rootScope', '$scope', '$location', '$q', '$filter', 'UserService', 'MarketOptionsConfig', 'OnboardingService', 'SplitTestService', function($rootScope, $scope, $location, $q, $filter, UserService, MarketOptionsConfig, OnboardingService, SplitTestService){
        //initialize datalayer for gtm
        window.dataLayer = window.dataLayer || [];

        $scope.user = UserService.user;

        OnboardingService.check();
        $scope.OnboardingService = OnboardingService;
        OnboardingService.progress = 67;

        //Set redirect variable to be displayed in <a href>
        $scope.redirect = $location.search().redirect ? encodeURIComponent($location.search().redirect) : '';
        $scope.$emit('endInteraction');

        // Set user variables from URL parameters
        var urlparams = $location.search();

        if (urlparams.first_name) {
            $scope.user.first_name = decodeURIComponent(urlparams.first_name);
        }
        if (urlparams.last_name) {
            $scope.user.last_name = decodeURIComponent(urlparams.last_name);
        }

        if(urlparams.email) {
            $scope.user.email = decodeURIComponent(urlparams.email);
        }

        if(urlparams.job_title) {
            $scope.user.title = urlparams.job_title;
            UserService.setCookieIfNotLoggedIn('registrationUserJobTitle', decodeURIComponent(urlparams.job_title) );
        }

        if (urlparams.lead) {
            UserService.setCookieIfNotLoggedIn('lead', parseInt(urlparams.lead, 10));
        }

        if (urlparams.role) {
            $scope.user.role =  decodeURIComponent(urlparams.role);
            UserService.setCookieIfNotLoggedIn('role', ((urlparams.role == 'Educator' || urlparams.role == 'Professor') ? urlparams.role : 'Student'));
        }

        // Picmonic Registration
        $scope.register = function(redirect, redirectUrl) {
            // Parse full name
            if ($scope.user.full_name) {
                if ($scope.user.full_name.indexOf(" ") == -1) {
                    $scope.user.first_name = $scope.user.full_name;
                    $scope.user.last_name = '';
                } else {
                    first_name = $scope.user.full_name.substring(0, $scope.user.full_name.indexOf(" "));
                    last_name = $scope.user.full_name.substring($scope.user.full_name.indexOf(" ") + 1);

                    $scope.user.first_name = first_name.charAt(0).toUpperCase() + first_name.slice(1);
                    $scope.user.last_name = last_name.charAt(0).toUpperCase() + last_name.slice(1);
                }
            }

            // Registration Source Page
            if ($scope.registration_source_page) {
                $scope.user.registration_source_page = $scope.registration_source_page;
            }

            // Market and Program
            if ($scope.user.selected_lead) {
                if ($scope.user.program && MarketOptionsConfig.getMarketById($scope.user.selected_lead).use_program_market_id) {
                    $scope.user.lead = $filter('filter')(MarketOptionsConfig.programs, {value: $scope.user.program}, true)[0].market_id;
                } else {
                    $scope.user.lead = $scope.user.selected_lead;
                }
            }
            var result = UserService.register(redirect, redirectUrl);
            result.then(function () {}, function(error){
                $scope.error = error.data.message_html;
                $scope.loading = false;
                return $q.reject(error);
            });
            return result;
        };

        //Facebook Registration
        $scope.socialRegister = function(redirect, redirectUrl){
            $scope.loading = true;
            $scope.loadingMode = 'registration-social';
            $scope.error = '';
            return UserService.socialRegister(redirect, redirectUrl).then(null, function(error){
                $scope.error = ((error && error.data.message) ? error.data.message : 'Not Authorized. You must click "Okay" when the Login with Facebook pop up opens.');
                $scope.loading = false;
                return $q.reject(error);
            });
        };

        // Submit Picmonic Registraiton Form
        $scope.submitRegistration = function(redirect){
            if ($scope.registration.$valid) {
                dataLayer.push({
                    'event': 'user_register',
                    'eventCategory' : 'signupAccount'
                });

                if(!$scope.loading){
                    $scope.loading = true;
                    $scope.loadingMode = 'registration-picmonic';
                    $scope.error = '';
                    $scope.user.accessToken = null;
                    $scope.register(redirect);
                }
            } else {
                $scope.error = 'Please complete all required fields';
                $scope.registration.$setDirty();
                angular.forEach($scope.registration.$error, function (field) {
                    angular.forEach(field, function(errorField){
                        errorField.$setTouched();
                    });
                });
            }
        };
    }]);