angular.module('picmonic-common')
    .directive("groupBuyTestTool", [function(){
        return {
            restrict: "E",
            replace: true,
            scope:{},
            template:
                '<div class="bg-pls-abyss pls-white pls-font-12" style="top: 70px;position: fixed;opacity: .25;z-index: 9999999;padding: 0 8px 0 8px;border-radius: 4px;box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);left: 8px;" ng-hide="hide">' +
                    'Group Buy Test Tool &nbsp;' +
                    '<a class="pls-blue" ng-click="addUser()">Add User</a> | ' +
                    '<a class="pls-blue" ng-click="removeAllUsers()">Remove All Users</a> | ' +
                    '<a class="pls-blue" ng-click="activate()">Activate</a> | ' +
                    '<a class="pls-blue" ng-click="expire()">Expire</a> | ' +
                    '<a class="pls-blue pull-right" ng-click="hide = true">Hide</a> ' +
                '</div>',
            controller: ['$scope', 'GroupBuyService', function($scope, GroupBuyService) {
                $scope.addUser = function() {
                    GroupBuyService.group_buy.users.push({first_name: "Neal", id: 169, last_name: "Lambert",lead: 100, user_id: 169});
                };

                $scope.removeAllUsers = function() {
                    GroupBuyService.group_buy.users = [];
                };

                $scope.activate = function() {
                    GroupBuyService.group_buy.time_left = 373429;
                    GroupBuyService.group_buy.end_date = '2016-06-30';
                };

                $scope.expire = function() {
                    GroupBuyService.group_buy.time_left = 0;
                    GroupBuyService.group_buy.end_date = '2016-06-20';
                };
            }]
        }
    }]);
