angular.module('picmonic-common')
    .directive("testimonialVideoLink", function(){
        return {
            restrict: "C",
            link: function(scope, element, attrs){
                $(element).magnificPopup({
                    type: 'iframe',
                    iframe: {}
                });
            }
        }
    });