angular.module('picmonicpublic')
    .controller('404Controller', ['$rootScope', '$scope', '$window', 'IntercomService', function($rootScope, $scope, $window, IntercomService) {
        $scope.base_asset_url = $rootScope.base_asset_url;
        $scope.spinning = false;
        IntercomService.trackEvent('404-page-viewed');
        $scope.$emit('endInteraction');

        $scope.spin = function(event) {
            if (!$scope.spinning) {
                $scope.spinning = true;

                angular.element(event.target).toggleClass('fa-spin');

                setTimeout(function(){
                    angular.element(event.target).toggleClass('fa-spin');
                    $scope.spinning = false;
                }, 2000);
            }
        };

        $scope.showIntercom = function() {
            $window.Intercom("show");
        }
    }]);