angular.module('picmonicpublic')
    .controller('PageController', ['$rootScope', '$scope', '$compile', '$state', '$stateParams', '$modal', '$anchorScroll', 'WordPressResource', 'MarketOptionsConfig', 'UserService', 'MarketSelectorModalService', 'SplitTestService', 'splitTest', '$location', 'groupBuy', 'bundleBuy', 'GroupBuyService', 'BundleBuyService', function ($rootScope, $scope, $compile, $state, $stateParams, $modal, $anchorScroll, WordPressResource, MarketOptionsConfig, UserService, MarketSelectorModalService, SplitTestService, splitTest, $location, groupBuy, bundleBuy, GroupBuyService, BundleBuyService) {
        $location.search('noredirect', null);
        $scope.base_asset_url = $rootScope.base_asset_url;
        $anchorScroll.yOffset = 130;
        $scope.$anchorScroll = $anchorScroll;
        $scope.loading = true;
        $scope.error = false;
        $scope.resource = $stateParams.resource ? $stateParams.resource : 'pages';
        $scope.slug = $stateParams.slug;
        var market_data = MarketOptionsConfig.getMarketBySlug($stateParams.market);
        $scope.market_data = market_data;
        $scope.market = market_data.slug;
        $scope.$stateParams = $stateParams;
        $scope.UserService = UserService;
        $scope.splitTest = splitTest;
        $scope.fuel_env = window.pls_config.fuel_env;

        if (groupBuy) {
            $scope.market = $scope.slug = groupBuy;

            if (GroupBuyService.group_buy.time_left <= 0) {
                $stateParams.is_group_buy = 0;
            }
        }

        if (bundleBuy) {
            $scope.market = $scope.slug = bundleBuy;

            if (BundleBuyService.bundle_buy.time_left <= 0) {
                $stateParams.is_bundle_buy = 0;
            }
        }

        if ($stateParams.no_market) {
            $modal.open({
                        templateUrl: '/templates/public/modals/pricing-selector.html',
                        windowClass: 'market-selector',
                        controller: ['$scope', '$filter', '$modalInstance', 'MenuService', function ($scope, $filter, $modalInstance, MenuService) {
                            $scope.loading = true;
                            MenuService.navigation.$promise.then(function(){
                                $scope.loading = false;
                                $scope.markets = $filter('filter')(MenuService.navigation.items, {title: 'Pricing'}, true)[0];
                            });
                        }]
                    });
        }
        
        if ($scope.market) {
            userMarket = MarketOptionsConfig.getMarketBySlug($scope.market);
            if (userMarket.id) {
                UserService.setMarketCookies(userMarket.id);
            }
        }

        var params = {
            resource: $scope.resource,
            slug: $scope.slug
        };

        // Valid Values: publish, future, draft, pending, private (development only)
        if ($stateParams.status) {
            params.status = $stateParams.status;
        }

        if (($scope.slug == 'home') && (typeof atlasEvent !== 'undefined')) {
            atlasEvent('click', 'Home Page', 'new_user', 'start', 60 * 60 * 2, true);
            atlasEvent('click', 'Home Page', 'user_flow_7_2017', 'start', 60 * 60 * 2, true);
            //atlasEvent('click', 'Home Page', 'new_public_onboarding', 'start', 60 * 60 * 2, true);
        }

        if (($scope.slug == 'how-picmonic-works') && (typeof atlasEvent !== 'undefined')) {
            atlasEvent('click', 'How Picmonic Works', 'new_user', 'start', 60 * 60 * 2, true);
            atlasEvent('click', 'How Picmonic Works', 'user_flow_7_2017', 'start', 60 * 60 * 2, true);
            //atlasEvent('click', 'How Picmonic Works', 'new_public_onboarding', 'start', 60 * 60 * 2, true);
        }

        // Get the page
        WordPressResource.get(params, function(res) {
            if(res.length) {
               $scope.page = res[0];
               angular.element('#compiledPageContent').html($compile($scope.page.content.rendered)($scope));
            } else {
               $state.transitionTo('public.404');
            }
        }, function(){
            $state.transitionTo('public.404');
        }).$promise.finally(function(){
            $scope.loading = false;
            $scope.$emit('endInteraction');
        });

        // Get careers
        if ($scope.slug == 'careers') {
            WordPressResource.get({resource: 'careers', '_embed': 1}, function(res) {
                if(res.length) {
                    $scope.careers = res;
                } else {
                    $scope.error = true;
                }
            }, function(){
                $scope.error = true;
            }).$promise.finally(function(){
                $scope.loading = false;
            });
        }

        $scope.showMarketSelectorModal = function(market) {
            if (UserService.user.user_id) {
                window.location = '/home?browseOpen';
                return;
            }

            $scope.showRegisterModal();
        };

        $scope.goToURL = function(url) {
            if (typeof atlasEvent !== 'undefined') {
                atlasEvent('click', 'learn_more');
            }

            document.location.href = url;
        };

        $scope.showRegisterModal = function(login) {
            $modal.open({
                templateUrl: '/templates/modals/register-modal.html',
                windowClass: 'registration-cta-modal full-screen-modal',
                controller: 'RegisterModalController',
                resolve: {
                    card: function () {
                        return $scope.card ? $scope.card : undefined;
                    },
                    tab: function() {
                        return login ? 'login' : 'register';
                    },
                    redirect: function() {
                        return $scope.card ? '/learn/' + $scope.card.slug : '/find-picmonic' + ($scope.market_data?'?market='+$scope.market_data.slug:'');
                    }
                }
            });
        };
    }]);
