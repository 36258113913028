angular.module('picmonic-common')
    .directive('uiTooltip', ['$compile', function($compile){
        return {
            restrict: 'A',
            replace: false,
            scope: {
                uiTooltip: '@',
                uiTooltipPosition: '@',
                uiTooltipTrigger: '@'
            },
            link: function(scope, elem, params) {
                var template = angular.element('<div class="ui-tooltip" ng-class="uiTooltipPosition?\'ui-tooltip--\'+uiTooltipPosition:\'\'" ng-bind-html="uiTooltip | safeHTML"></div>');
                elem.after(template);
                $compile(template)(scope);

                // Default value is click
                if (!scope.uiTooltipTrigger) {
                    scope.uiTooltipTrigger = 'click';
                }

                var triggers = scope.uiTooltipTrigger.split(" ");

                for (var i in triggers) {
                    switch (triggers[i]){
                        case 'hover':
                            elem.bind('mouseover', function(){
                                template.addClass('ui-tooltip--visible');
                            }).bind('mouseleave', function(){
                                template.removeClass('ui-tooltip--visible');
                            });
                            break;
                        case 'click':
                            elem.bind('click', function(e){
                                e.stopPropagation();
                                template.toggleClass('ui-tooltip--visible');
                            });

                            angular.element('body').bind('click', function(e){
                                if(e.target != template[0] && template.hasClass('ui-tooltip--visible')){
                                    template.removeClass('ui-tooltip--visible');
                                }
                            });
                            break;
                    }
                }
            }
        }
    }]);