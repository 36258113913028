angular.module('picmonicpublic').component('presentationsComponent', {
    templateUrl: '/templates/public/pages/presentations/presentations.html',
    bindings: {
        $transition$: '<'
    },
    controller: ['PresentationsResource', 'UserService', 'PermissionsService', 'MarketOptionsConfig', '$modal', function(PresentationsResource, UserService, PermissionsService, MarketOptionsConfig, $modal){
        var ctrl = this;
        var stateParams = ctrl.$transition$.params('to');
        ctrl.loading = true;
        PresentationsResource.query(function(response){
            ctrl.past_presentations = [];
            ctrl.future_presentations = [];
            var time = Math.floor(Date.now()/1000) - 7200;
            angular.forEach(response, function(pres){
                pres.market = MarketOptionsConfig.getMarketById(pres.market_id).name;
                if (pres.presenter_id == UserService.user.user_id) {
                    pres.is_presenter = 1;
                }
                if (pres.when > time) {
                    ctrl.future_presentations.push(pres);
                } else {
                    ctrl.past_presentations.push(pres);
                }
                if (stateParams.id && stateParams.id == pres.presentation_id) {
                    ctrl.q = pres.school.name;
                }
                ctrl.loading = false;
            });
        });
            
        PermissionsService.permissions.$promise.then(function(){
            ctrl.is_admin = PermissionsService.hasAccess('admin','presentations').result;
        });
        
        ctrl.rsvp = function(pres) {
            $modal.open({
                templateUrl:'/templates/public/modals/presentation-rsvp.html',
                controller:['$scope', function($scope){
                    $scope.MarketOptionsConfig = MarketOptionsConfig;
                    $scope.rsvp = function(email) {
                        PresentationsResource.save({presentation_id:pres.presentation_id, resource:'rsvps'},{email:email});
                        $scope.showThankYou = true;
                    };
                    $scope.pres = pres;
                    if (UserService.user.email) {
                        $scope.rsvp(UserService.user.email);
                    }
                }]
            });
        };

        ctrl.rsvp_list = function(pres) {
           $modal.open({
                templateUrl:'/templates/public/modals/presentation-rsvp-list.html',
                controller:['$scope', function($scope){
                    $scope.presentation = pres;
                    $scope.rsvps = PresentationsResource.query({presentation_id:pres.presentation_id, resource:'rsvps'});
                }]
            });
        };
    }]
});