angular.module('picmonic-common')
    .factory("WordPressResource", ['$resource', function($resource){
        return $resource("https://www.picmonic.com/pages/wp-json/wp/v2/:resource/:resource_id/?p=icmonic", {resource:'@resource', resource_id:'@resource_id', slug:'@slug'}, {
            'get': {
                method: 'GET',
                isArray: true,
                headers: {
                    'X-Requested-With': undefined,
                    'If-Modified-Since': undefined,
                    'Authorization': window.pls_config.wp_authorization_header ? 'Basic ' + window.pls_config.wp_authorization_header : undefined
                }
            }
        });
    }])
    .factory("WordPressMenuResource", ['$resource', function($resource){
        return $resource("https://www.picmonic.com/pages/wp-json/wp-api-menus/v2/:resource/:resource_id", {resource:'@resource', resource_id:'@resource_id'}, {
            'get': {
                method: 'GET',
                headers: {
                    'X-Requested-With': undefined,
                    'If-Modified-Since': undefined,
                    'Authorization': window.pls_config.wp_authorization_header ? 'Basic ' + window.pls_config.wp_authorization_header : undefined
                }
            }
        });
    }]);
