angular.module('picmonic-common')
    .directive('miniPlayer', ['Picmonics', '$filter', '$interval', '$q', 'hotkeys', 'UserService', '$state', 'deviceDetector', '$rootScope', '$timeout', function(Picmonics, $filter, $interval, $q, hotkeys, UserService, $state, deviceDetector, $rootScope, $timeout) {
        return {
            restrict: 'E',
            templateUrl: '/templates/directives/mini-player.html',
            link: function(scope, element, attrs) {
                scope.UserService = UserService;
                scope.audioElement = angular.element(element.find('audio'));
                scope.playing = false;
                scope.deviceDetector = deviceDetector.raw;
                scope.localPlayerService = {
                    mode: 'mini',
                    assetStatus: [],
                    assetsLoaded: false
                };
                scope.startTime = 0;
                scope.qotd_question_id = 0;
                scope.quiz_round_id = 0;
                scope.zoom = true;
                scope.destroyed = false;
                if (attrs.hideText) {scope.hideText = true;}
                scope.currentFact = {};
                scope.currentCard = {};

                scope.saveCardAttempt = function() {
                    var nonHeaderFacts = $filter('filter')(scope.currentFacts, { header: '!1'});

                    var fact_stats = new Array();

                    angular.forEach(nonHeaderFacts, function(value,key) {
                        var stat = new Object();
                        if (!value.viewed) {
                            value.viewed = 0;
                        }
                        stat = {fact_id: value.fact_id, card_id: value.card_id, viewed: value.viewed};
                        fact_stats.push(stat);
                    });

                    if (attrs.resource == 'qotd') {
                        scope.qotd_question_id = attrs.resourceId;
                    } else if (attrs.resource = 'quiz') {
                        scope.quiz_round_id = attrs.resourceId;
                    }

                    if (scope.startTime > 0 && UserService.user.user_id) {
                        Picmonics.save({
                            card_id: scope.currentCard.card_id,
                            resource: 'attempts',
                            mobile_flag: 0,
                            source_device: (deviceDetector.device == 'unknown') ? 'desktop' : deviceDetector.device,
                            start_time: scope.startTime,
                            end_time: Math.round(new Date().getTime() / 1000),
                            card_reset_flag: 0,
                            facts_attempted: 0,
                            facts_correct: 0,
                            card_confidence: scope.currentCard.rating,
                            quiz_attempts: 0,
                            fact_count: nonHeaderFacts.length,
                            quiz_fact_stats: fact_stats,
                            learn_time_spent: Math.round(new Date().getTime() / 1000) - scope.startTime,
                            review_time_spent: 0,
                            quiz_time_spent: 0,
                            education_completed_flag: scope.done ? 1 : 0,
                            story_completed_flag: 0,
                            summary_viewed_flag: 0,
                            learn_complete: scope.done ? 1 : 0,
                            review_complete: 0,
                            master_complete: 0,
                            mini_player_flag: 1,
                            quiz_round_id: scope.quiz_round_id,
                            qotd_question_id: scope.qotd_question_id
                        }, function() {
                            // Notify interested components that the card data has changed
                            scope.$emit('cardDataChanged', {action:'learn_attempt',card_id:scope.currentCard.card_id});
                        });
                    }
                };

                scope.$on('$destroy', function () {
                   scope.destroyed = true;
                   scope.audioElement[0].pause();
                   scope.audioElement[0].remove();
                   $interval.cancel(scope.interval);
                   scope.saveCardAttempt();
                });

                window.onbeforeunload = function(){
                    window.onbeforeunload = null;
                    scope.saveCardAttempt();
                };

                scope.$watch(function(){
                    return attrs.slug;
                }, function(){
                    if (scope.currentCard.slug != attrs.slug) {
                        scope.getCard();
                    }
                });

                scope.$watch(function(){
                    return scope.localPlayerService.assetStatus.length;
                }, function(){
                    if(scope.localPlayerService.assetStatus.length){
                        $q.all(scope.localPlayerService.assetStatus).finally(function(){
                            scope.localPlayerService.assetStatus = [];
                            scope.localPlayerService.assetsLoaded = true;
                            if (attrs.autoplay && scope.audioElement[0].paused && !scope.destroyed) {
                                scope.playPauseAudio();
                            }
                        });
                    }
                });

                var miniPlayerToggle = function(event, val) {
                    if (scope.localPlayerService.assetsLoaded) {
                        scope.playPauseAudio();
                    } else {
                        attrs.autoplay = true;
                    }
                };

                scope.$on('miniPlayerToggle', miniPlayerToggle);

                var miniPlayerPause = function(event, val) {
                    $rootScope.$broadcast('miniPlayerPlaying', false);
                    scope.audioElement[0].pause();
                    scope.clearSelectedFact();
                };

                scope.$on('miniPlayerPause', miniPlayerPause);

                scope.playPauseAudio = function() {
                    if (scope.audioElement[0].paused) {
                        if (scope.localPlayerService.assetsLoaded) {
                            if (typeof atlasEvent !== 'undefined') {
                                atlasEvent('click', 'Play Mini Player');
                            }
                            $rootScope.$broadcast('miniPlayerPlaying', true);
                            scope.audioElement[0].play();

                            if (scope.startTime == 0) {
                                scope.startTime = Math.round(new Date().getTime() / 1000);
                            }
                        } else {
                            attrs.autoplay = true;
                        }
                    } else {
                        if(typeof atlasEvent !== 'undefined') {atlasEvent('click', 'Pause Mini Player');}
                        $rootScope.$broadcast('miniPlayerPlaying', false);
                        scope.audioElement[0].pause();
                        scope.clearSelectedFact();
                    }
                    $timeout(function() { angular.element('#mini-player-toggle').focus(); });
                };

                hotkeys.add({
                    combo: 'space',
                    callback: function(e) {
                        e.preventDefault();
                        if (!scope.destroyed) {
                            scope.playPauseAudio();
                        }
                    }
                });

                scope.audioElement.bind("play", function(){
                    scope.playing = true;
                    scope.interval = $interval(scope.determineFact, 50);
                });

                scope.seek = function($event) {
                    var offset = $event.currentTarget.getBoundingClientRect();
                    var time = (($event.pageX - offset.left) / offset.width) * scope.audioElement[0].duration;

                    scope.audioElement[0].currentTime = time;
                    scope.currentTime = time;
                };

                scope.setAudioSpeed = function(speed){
                    scope.audioElement[0].playbackRate = speed;
                    UserService.user.audio_speed = speed;
                    if (UserService.user.user_id) {
                        UserService.user.$update();
                    }
                    send_ga('event', 'Learn', 'Speed Changed');
                };

                scope.audioElement.bind("pause", function(){
                    scope.playing = false;
                    if(scope.interval) {
                        $interval.cancel(scope.interval);
                    }
                });

                scope.audioElement.bind("ended", function(){
                    if(typeof atlasEvent !== 'undefined') {atlasEvent('click', 'Mini Player Finished');}
                    scope.done = true;
                    if (scope.recommended) {
                        scope.close();
                    }
                    scope.playing = false;
                    if(scope.interval) {
                        $interval.cancel(scope.interval);
                    }
                });

                scope.determineFact = function(){
                    scope.currentTime = scope.audioElement[0].currentTime;
                    var currentFact;
                    var facts = $filter('filter')($filter('orderBy')(scope.currentFacts, 'order'), {header: '!1', entity_type_id: '!1'});

                    for (a in facts) {
                        var fact = facts[a];

                        if (scope.audioElement[0].currentTime >= fact.audio['educational'].start && scope.audioElement[0].currentTime <= (fact.audio['educational'].end + 0.5)) {
                            currentFact = fact;
                        }
                    }

                    if (currentFact) {
                        if ((scope.audioElement[0].currentTime - currentFact.audio['educational'].start) < 5) {
                            scope.zoom = true;
                        } else {
                            scope.zoom = false;
                        }
                        if(currentFact != scope.currentFact) {
                            scope.selectFact(currentFact);
                        }
                    }else{
                        scope.clearSelectedFact();
                    }
                };

                scope.selectFact = function(fact) {
                    var selected = scope.currentFacts[$filter('arrayPos')(scope.currentFacts, true, 'selected')];

                    if(selected){
                        selected.selected = false;
                    }

                    fact.selected = true;
                    fact.viewed = 1;
                    scope.currentFact = fact;
                };

                scope.clearSelectedFact = function(fact) {
                    scope.currentFact = {};
                    if (scope.currentFacts) {
                        var selected = scope.currentFacts[$filter('arrayPos')(scope.currentFacts, true, 'selected')];
                        if (selected) {
                            selected.selected = false;
                        }
                    }
                };

                scope.getCard = function() {
                    scope.currentCard = Picmonics.get({slug: attrs.slug});
                    scope.currentCard.$promise.then(function () {
                        if (scope.currentCard.audio && scope.currentCard.audio['educational']) {
                            scope.audioElement[0].src = '//d1abqebhfcygq8.cloudfront.net/cloud_data/audio_classic/' + scope.currentCard.audio['educational'];
                        }

                        scope.audioElement[0].oncanplay = function(){
                            scope.duration = scope.audioElement[0].duration;
                            if (UserService.user.user_id) {
                                scope.setAudioSpeed(UserService.user.audio_speed);
                            } else {
                                scope.setAudioSpeed(1);
                            }
                        };

                        Picmonics.query({card_id: scope.currentCard.card_id, resource: 'facts'}, function (facts) {
                            facts = $filter('orderBy')(facts, ['order', 'fact_id']);

                            var count = 0;

                            for (a in facts) {
                                if (!facts[a].header && facts[a].entity_type_id == 2) {
                                    facts[a].display_order = count;
                                    facts[a].shuffle_order = Math.random();
                                    facts[a].viewed = 1;
                                    count++;
                                }
                            }

                            scope.currentFacts = facts;

                        });

                    });
                };
            }
        };
    }]);
