angular.module('picmonic-common')
    .factory("DateService", [function() {
        return {
            dateTimeStringToDate:  function(dateTimeString) {
                var dSplit = dateTimeString.split(/[- :]/);
                return new Date(Date.UTC(dSplit[0], dSplit[1]-1, dSplit[2], dSplit[3], dSplit[4], dSplit[5]));
            },
            showExpirationDate: function(dateTimeString) {
                var self = this;
                
                var now = new Date();
                expirationDate = self.dateTimeStringToDate(dateTimeString);

                return (now.getTime() / 1000) >= ((expirationDate.getTime() / 1000) - (60 * 60 * 24 * 31));
            }
        }
    }]);