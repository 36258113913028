angular.module('picmonic-common')
  .directive('marketDropdown', [function () {
    return {
      restrict: 'E',
      replace: true,
      require: 'ngModel',
      scope: {
        prefix: '@',
        emptyText: '@',
        required: '='
      },
      template: '<select class="pls-input"><option value="">{{emptyText}}</option><option ng-repeat="market in markets | filter: {hidden:0}" value="{{market.id}}">{{prefix}} {{market.name}}</option></select>',
      controller: ['MarketOptionsConfig', '$scope', function(MarketOptionsConfig, $scope) {
        $scope.markets = MarketOptionsConfig.markets;
      }]
    };
  }]);