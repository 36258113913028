angular.module('picmonic-common')
    .directive("atlasSend", [function(){
        return {
            restrict: "A",
            link: function(scope, element, attrs){
                element.bind('click', function(event) {
                    if(typeof atlasEvent !== 'undefined') {
                        if (!attrs.atlasAction) {
                            attrs.atlasAction = 'click';
                        }

                        atlasEvent(attrs.atlasAction, attrs.atlasSend, attrs.atlasFlow, attrs.atlasStep, attrs.atlasTimeLimit, attrs.atlasOnlyNewVisitors);
                    }
                });
            }
        };
    }]);
