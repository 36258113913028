angular.module('picmonic-common')
    .directive("groupBuyBanner", ['$stateParams', 'MarketOptionsConfig', function($stateParams, MarketOptionsConfig){
        return {
            restrict: 'E',
            template: '<div ng-show="visible" class="group-buy-banner pls-gradient-sunrise pls-font-24 pls-font-raleway pls-font-weight-600 text-center p-t-1 p-b-1" style="height: 54px;" ng-click="goToGroupBuyCheckout()">\
                <em class="pls-black" style="position: relative;top: 2px;">Save up to 40%!</em><a class="pls-btn pls-btn--white pls-orange m-l-2">Learn More</a>\
                <i class="pls-white pull-right material-icons m-r-2 group-buy-banner-close" style="position: relative;top: 2px;" ng-click="hideBanner($event)">close</i>\
            </div>',
            replace: true,
            scope: {
                marketId: '<'
            },
            controller: ['$scope', '$cookies', function($scope, $cookies){
                $scope.visible = !parseInt($cookies.get('pls_user_group_buy_banner_visible'), 10);

                if ($stateParams.is_group_buy) {
                    $scope.visible = false;
                }

                $scope.hideBanner = function($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    $scope.visible = false;
                    var expires = new Date();
                    expires.setTime(expires.getTime() + (24*60*60*1000));
                    $cookies.put('pls_user_group_buy_banner_visible', 1, {expires:expires});
                };

                $scope.goToGroupBuyCheckout = function() {
                    if ($scope.marketId) {
                        window.location = '/store/'+MarketOptionsConfig.getMarketById($scope.marketId).slug+'-group-buy-12-months';
                    } else {
                        window.location = 'https://www.picmonic.com/pages/picmonic-group-buy/';
                    }
                };
            }]
        };
    }]);